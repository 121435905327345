<div *ngIf="saleCopy != null">
  <div class="title">Dossier client n°</div>
  <div class="sale-number">
    {{ saleCopy.numberWithVersion ? saleCopy.numberWithVersion : '(Sauvegardez pour obtenir un numéro de dossier)' }}
  </div>
  <div class="sale-history btn btn-default" *ngIf="saleCopy.number != null && olderVersions.length > 0">
    <i class="fas fa-history" (click)="showHistory = !showHistory"></i>
    <ul *ngIf="showHistory">
      <li *ngFor="let version of olderVersions" (click)="showVersion(version)">{{ version.numberWithVersion }}</li>
    </ul>
  </div>

  <div class="price-sale-view-wrapper">
    <div class="price-sale-view-column">
      <h3>Informations dossier</h3>
      <info-block
        [block]="selectBlock"
        #selectBlockComponent
        [data]="saleCopy"
        *ngIf="saleCopy.number == null"
      ></info-block>
      <info-block
        #shortageBlockComponent
        [block]="shortageBlock"
        [data]="saleCopy"
        [readonly]="!editMode && saleCopy.id"
      ></info-block>
      <info-block
        [block]="merchantBlock"
        #merchantBlockComponent
        [data]="saleCopy"
        [readonly]="saleCopy.number != null"
      ></info-block>
      <!-- <info-block [block]="suppliersBlock" [data]="saleCopy"></info-block> -->
      <info-block
        [block]="saleBlock"
        #saleBlockComponent
        [data]="saleCopy"
        [readonly]="!editMode && saleCopy.id"
      ></info-block>
      <info-block
        [block]="remarksBlock"
        #remarksBlockComponent
        [data]="saleCopy"
        [readonly]="!editMode && saleCopy.id"
      ></info-block>
      <info-block
        [block]="accountingBlock"
        #accountingBlockComponent
        [data]="saleCopy"
        [readonly]="!editMode && saleCopy.id"
      ></info-block>
      <!--
            <info-block #saleArticleBlockComponent [block]="saleArticleBlock" [data]="saleCopy.article" [readonly]="saleCopy.id && !editMode" *ngIf="saleCopy.article.type == 0"></info-block>
            <info-block #saleDeviceBlockComponent [block]="saleDeviceBlock" [data]="saleCopy.article" [readonly]="saleCopy.id && !editMode" *ngIf="saleCopy.article.type == 1"></info-block> -->

      <h3>Adresses</h3>
      <info-block
        [block]="ordinatorBlock"
        #ordinatorBlockComponent
        [data]="saleCopy"
        [readonly]="!editMode && saleCopy.id"
      ></info-block>
      <info-block
        [block]="invoicingBlock"
        #invoicingBlockComponent
        [data]="saleCopy"
        [readonly]="!editMode && saleCopy.id"
      ></info-block>
      <info-block
        [block]="deliveryBlock"
        #deliveryBlockComponent
        [data]="saleCopy"
        [readonly]="!editMode && saleCopy.id"
      ></info-block>
    </div>

    <div class="price-sale-view-column">
      <h3>Informations article</h3>
      <info-block
        #articleBlockComponent
        [block]="articleBlock"
        [data]="saleCopy.article"
        [readonly]="true"
        *ngIf="(saleCopy.article && objectIsArticle) || !saleCopy.article"
      ></info-block>
      <info-block
        #deviceBlockComponent
        [block]="deviceBlock"
        [data]="saleCopy.article"
        [readonly]="true"
        *ngIf="saleCopy.article && objectIsDevice"
      ></info-block>
      <info-block
        #serviceBlockComponent
        [block]="serviceBlock"
        [data]="saleCopy.article"
        [readonly]="true"
        *ngIf="saleCopy.article && objectIsService"
      ></info-block>

      <h3>Notes internes</h3>
      <info-block [block]="internalNotesBlock" [data]="saleCopy" [readonly]="!editMode && saleCopy.id"></info-block>

      <h3
        class="{{ articleHistoryVisible ? 'toggle-opened' : 'toggle-closed' }}"
        (click)="articleHistoryVisible = !articleHistoryVisible"
      >
        Historique article
      </h3>
      <data-grid
        *ngIf="articleHistoryVisible"
        id="sale-article-history"
        class="old-prices-grid"
        [columns]="oldPricesColumns"
        [items]="old_prices"
        [selectable]="false"
        [editable]="false"
        [resizable]="false"
      ></data-grid>

      <h3
        class="{{ customerTurnoversVisible ? 'toggle-opened' : 'toggle-closed' }}"
        (click)="customerTurnoversVisible = !customerTurnoversVisible"
      >
        Chiffres client
      </h3>
      <data-grid
        *ngIf="customerTurnoversVisible"
        id="sale-customer-turnover"
        class="turnovers-grid"
        [columns]="turnoverColumns"
        [items]="turnovers"
        [selectable]="false"
        [editable]="false"
        [resizable]="false"
      ></data-grid>

      <h3
        class="{{ incidentsVisible ? 'toggle-opened' : 'toggle-closed' }}"
        (click)="incidentsVisible = !incidentsVisible"
      >
        Incidents
      </h3>
      <data-grid
        *ngIf="incidentsVisible"
        id="sale-incidents"
        class="incidents-grid"
        [columns]="incidentsColumns"
        [items]="incidents"
        [selectable]="false"
        [editable]="false"
        [resizable]="false"
        [rowStyleFunction]="incidentRowStyle"
      ></data-grid>

      <!-- <h3 *ngIf="saleCopy.customer == null">Prospect</h3> -->
      <!-- <info-block *ngIf="saleCopy.customer == null" [block]="tempCustomerBlock" #tempCustomerBlockComponent [data]="saleCopy" [readonly]="!editMode && saleCopy.id"></info-block> -->

      <!-- <h3>Quantités et unités</h3> -->
      <!-- <data-grid #quantitiesGrid [columns]="quantitiesGridColumns" [items]="saleCopy.prices" [selectable]="true" [editable]="true" [resizable]="false"></data-grid> -->
    </div>

    <div class="price-sale-view-full-column">
      <h3>Quantités et prix</h3>
      <data-grid
        class="price-sale-prices-grid"
        id="sale-prices"
        [columns]="pricesGridColumns"
        [actions]="!saleCopy.id || editMode === true ? quantitiesGridActions : null"
        #quantitiesGrid
        [items]="saleCopy.prices"
        [selectable]="true"
        [editable]="!saleCopy.id || !saleCopy.number || editMode"
        [resizable]="true"
        (change)="onPriceChanged()"
        [fixedColumnsCount]="3"
      >
      </data-grid>
    </div>
  </div>
</div>
