import { ObjectDefinition } from '../../../classes/objects/ObjectDefinition.class';
import { ObjectModel2 } from '../../../classes/objects/ObjectModel2.class';
import { ObjectModel3 } from 'src/app/classes/objects/ObjectModel3.class';

export class Discount extends ObjectModel3 {
  public name: string = null;
  public amount: number = null;
  public percent: number = null;

  public constructor() {
    super(Discount);
  }

  /* ObjectModel FUNCTIONS */

  public static definition: ObjectDefinition = {
    database: {
      table: 'discounts',
      id: 'id',
    },
    values: {
      name: { type: 'string' },
      amount: { type: 'number' },
      percent: { type: 'number' },
    },
    children: {},
    links: {},
  };
}
