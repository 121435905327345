import { Component, ViewChild } from '@angular/core';

import { AccessViewComponent } from '../../../../components/views/access-view.component';
import { Accounting } from 'src/app/modules/other-data/classes/Accounting.class';
import { AccountingsService } from 'src/app/services/accountings/accountings.service';
import { Address } from 'src/app/modules/addresses/classes/Address.class';
import { ArrayUtil } from 'src/app/modules/utils/classes/ArrayUtil.class';
import { ArticleInfoBlocks } from 'src/app/modules/articles/classes/ArticleInfoBlocks.class';
import { ChangeOrderNumberComponent } from '../../change-order-number/change-order-number.component';
import { CredentialsService } from '../../../../services/credentials/credentials.service';
import { CurrenciesService } from 'src/app/services/currencies/currencies.service';
import { DateTimeUtil } from '../../../utils/classes/DateTimeUtil.class';
import { Deadline } from 'src/app/modules/other-data/classes/Deadline.class';
import { DeliveryNotePrintingModel } from '../../classes/DeliveryNotePrintingModel.class';
import { DeliveryTime } from 'src/app/modules/other-data/classes/DeliveryTime.class';
import { DialogButton } from 'src/app/components/dialogs/classes/DialogButton.class';
import { DialogsComponent } from 'src/app/components/dialogs/dialogs.component';
import { Discount } from '../../../other-data/classes/Discount.class';
import { ExternalService } from 'src/app/services/external/external.service';
import { FilesystemUtil } from 'src/app/modules/utils/classes/FilesystemUtil.class';
import { Form } from 'src/app/classes/forms/Form.class';
import { FormValidator } from 'src/app/classes/forms/FormValidator.class';
import { InfoBlock } from '../../../../components/info-block/classes/InfoBlock.class';
import { InfoBlockComponent } from '../../../../components/info-block/info-block.component';
import { InfoBlockField } from '../../../../components/info-block/classes/InfoBlockField.class';
import { LoadingPromise } from 'src/app/classes/objects/LoadingPromise.class';
import { NotificationsComponent } from 'src/app/components/notifications/notifications.component';
import { Order } from '../../classes/Order.class';
import { OrderAckPrintingModel } from '../../classes/OrderAckPrintingModel.class';
import { OrderPrintingModel } from '../../classes/OrderPrintingModel.class';
import { Price } from '../../../sales/classes/Price.class';
import { PrintPreviewViewComponent } from 'src/app/modules/printing/views/print-preview-view/print-preview-view.component';
import { PrintingModel } from 'src/app/modules/printing/classes/PrintingModel.class';
import { Sale } from 'src/app/modules/sales/classes/Sale.class';
import { ServerApi } from '../../../../classes/api/ServerApi.class';
import { SettingsComponent } from 'src/app/components/settings/settings.component';
import { StickersPrintingModel } from '../../classes/StickersPrintingModel.class';
import { Stock } from 'src/app/modules/stocks/classes/Stock.class';
import { StockAdjustment } from '../../../stocks/classes/StockAdjustment.class';
import { Supplier } from 'src/app/modules/suppliers/classes/Supplier.class';
import { Toolbar } from '../../../../components/toolbar/classes/Toolbar.class';
import { ViewsComponent } from 'src/app/components/views/views.component';
import { config } from '../../../../classes/config';

@Component({
  selector: 'app-create-order-view',
  templateUrl: './order-view.component.html',
  styleUrls: ['./order-view.component.css'],
})
export class OrderViewComponent extends AccessViewComponent {
  public permission: string = 'ecriture_commandes_fichecommande';

  public toolbar: Toolbar = {
    class: 'toolbar-big',
    viewTitle: 'Bon de commande',
    data: this,
    elements: [
      { type: 'separator-large' },
      // {
      //     type: 'button',
      //     name: 'saveButton',
      //     text: 'Enregistrer',
      //     icon: 'save',
      //     click: function(view: OrderViewComponent) {
      //         view.save().then((result) => {
      //             //view.updateView(view.demandCopy);
      //         });
      //     },
      //     options: { visible: true }
      // },
      {
        type: 'button',
        name: 'newOrderButton',
        text: 'Nouvelle<br/>commande',
        icon: ' far fa-file',
        click: function (view: OrderViewComponent) {
          DialogsComponent.display({
            title: 'Créer une nouvelle commande ?',
            message: 'Toute modification non enregistrée sera perdue !<br/>Êtes-vous sûr(e) de vouloir continuer ?',
            icon: 'question',
            buttons: DialogButton.yesNoButtons,
          }).then((result: any) => {
            if (result == DialogButton.RESULT_YES) view.initView(null);
          });
        },
        visible: true,
        access: this.writeAccess,
      },
      { type: 'separator' },
      {
        type: 'button',
        name: 'editNumberButton',
        text: 'Modifier<br/>le numéro',
        icon: 'edit',
        click: function (view: OrderViewComponent) {
          view.editNumber();
        },
        visible: true,
        access: this.writeAccess && CredentialsService.isUserAllowed('order_view_edit_number'),
      },
      { type: 'separator-large' },
      {
        type: 'button',
        text: 'Aperçu accusé<br/>de réception',
        icon: 'search',
        click: function (view: OrderViewComponent) {
          if (view.validateForm() == true) {
            const previewView = ViewsComponent.openView(
              PrintPreviewViewComponent,
              OrderAckPrintingModel,
              view.orderCopy
            ) as PrintPreviewViewComponent;
            previewView.onSendByMailClicked = () => view.sendToCustomer();
          }
        },
      },
      { type: 'separator' },
      {
        type: 'button',
        text: 'Aperçu bon<br/>de commande',
        icon: 'search',
        click: function (view: OrderViewComponent) {
          if (view.validateForm() == true) {
            ViewsComponent.openView(PrintPreviewViewComponent, OrderPrintingModel, view.orderCopy);
          }
        },
      },
      { type: 'separator' },
      {
        type: 'button',
        text: 'Aperçu bon<br/>de livraison',
        icon: 'search',
        click: function (view: OrderViewComponent) {
          if (view.validateForm() == true) {
            ViewsComponent.openView(PrintPreviewViewComponent, DeliveryNotePrintingModel, view.orderCopy);
          }
        },
      },
      { type: 'separator' },
      {
        type: 'button',
        text: 'Aperçu<br/>étiquettes',
        icon: 'search',
        click: function (view: OrderViewComponent) {
          if (view.validateForm() == true) {
            ViewsComponent.openView(PrintPreviewViewComponent, StickersPrintingModel, view.orderCopy);
          }
        },
      },

      { type: 'separator-large' },
      {
        type: 'button',
        text: 'Enregistrer<br/>(ERP + PDF)',
        icon: 'save',
        click: function (view: OrderViewComponent) {
          view.save().then((result) => {
            view.updateView(view.orderCopy);
            view.saveFiles();
          });
        },
        access: this.writeAccess,
      },
      { type: 'separator' },
      {
        type: 'button',
        text: 'Envoyer<br/>au client',
        icon: ' fas fa-at',
        click: function (view: OrderViewComponent) {
          view.sendToCustomer();
        },
      },
      { type: 'separator' },
      {
        type: 'button',
        text: 'Envoyer au<br/>fournisseur',
        icon: ' fas fa-at',
        click: function (view: OrderViewComponent) {
          view.sendToSupplier();
        },
      },
      { type: 'spacing' },
    ],
  };

  public order: Order = null;
  public orderCopy: Order = null;

  public selectedSale: Sale = null;

  public contactsData: any = { items: [] };
  public offersData: any = { items: [] };
  public pricesData: any = { items: [] };
  public discountsData: any = { items: [] };
  public deliveryTimeData: any = { items: [] };
  public deadlineData: any = { items: [] };
  public suppliersData: any = { items: [] };
  public accountingsData: any = { items: [] };
  public currenciesData: any = { items: [] };

  public allowed_stocks: any = { items: [] };

  public selectBlock: InfoBlock = {
    title: '',
    backColor: 'rgb(0,0,0)',
    textColor: 'white',
    fields: [
      {
        title: "Choix de l'offre",
        field: 'selectedSale',
        type: 'searchable-foreign-list',
        multiSelect: false,
        listItems: this.offersData,
        listField: 'numberAndName',
        nullText: '(Aucun)',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.updateView(this.orderCopy);
        },
      },
    ],
  };

  public shortageBlock: InfoBlock = {
    title: '',
    backColor: 'rgb(255,0,0)',
    textColor: 'white',
    fields: [{ title: 'Sujet aux pénuries', field: 'shortage_warning', type: 'checkbox' }],
  };

  public priceSelectionField: InfoBlockField = {
    title: 'Quantité et unité',
    field: 'price',
    type: 'foreign-list',
    readonly: false,
    visible: true,
    multiSelect: false,
    listItems: this.pricesData,
    listField: 'quantityAndUnit',
    nullText: '(Aucun)',
    change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
      this.selectedPriceChanged();
    },
  };
  public priceField: InfoBlockField = {
    title: 'Quantité et unité',
    field: 'quantityAndUnit',
    type: 'text',
    readonly: true,
    visible: false,
  };
  @ViewChild('orderBlockComponent') orderBlockComponent;
  public orderBlock: InfoBlock = {
    title: 'Commande',
    backColor: 'rgb(54,96,146)',
    textColor: 'white',
    fields: [
      { title: 'Date', type: 'date', field: 'date' },
      this.priceSelectionField,
      this.priceField,
      {
        title: 'Fournisseur',
        field: 'supplier',
        type: 'foreign-list',
        allowBlankValues: false,
        multiSelect: false,
        listItems: this.suppliersData,
        listField: 'name',
        nullText: '(Aucun)',
      },
      { title: 'N° offre fournisseur', field: 'supplier_reference' },
      {
        title: "Prix d'achat à l'unité",
        field: 'converted_buy_price',
        type: 'number',
        currencyField: 'buy_currency',
        decimalsCount: 5,
        textAlign: 'left',
        numberUnit: ' € H.T.V.A.',
      },
      {
        title: "Prix de vente à l'unité",
        field: 'converted_sell_price',
        type: 'number',
        currencyField: 'sell_currency',
        decimalsCount: 5,
        textAlign: 'left',
        numberUnit: ' € H.T.V.A.',
      },
      {
        title: 'Frais de gestion',
        field: 'converted_internal_fees',
        type: 'number',
        currencyField: 'sell_currency',
        decimalsCount: 2,
        textAlign: 'left',
        numberUnit: ' € H.T.V.A.',
      },
      {
        title: 'Frais fournisseur / découpe / transport',
        field: 'converted_supplier_fees',
        type: 'number',
        currencyField: 'buy_currency',
        decimalsCount: 2,
        textAlign: 'left',
        numberUnit: ' € H.T.V.A.',
      },
      {
        title: `Frais ${config.companyName} / création`,
        field: 'converted_external_fees',
        type: 'number',
        currencyField: 'sell_currency',
        decimalsCount: 2,
        textAlign: 'left',
        numberUnit: ' € H.T.V.A.',
      },
      {
        title: 'Commission agent',
        field: 'converted_agent_fees',
        type: 'number',
        currencyField: 'sell_currency',
        decimalsCount: 2,
        textAlign: 'left',
        numberUnit: ' € H.T.V.A.',
      },
      {
        title: 'Total de la commande',
        field: 'converted_totalAmount',
        type: 'number',
        currencyField: 'sell_currency',
        decimalsCount: 5,
        textAlign: 'left',
        numberUnit: ' € H.T.V.A.',
        readonly: true,
      },
      { title: 'Conditions de paiement fournisseur', type: 'text', field: 'supplier.deadline.name', readonly: true },
      { title: 'Nombre de facturations', field: 'invoicings' },
      { title: 'Nombre de livraisons', field: 'deliveries' },
      { title: 'Nombre de productions', field: 'productions' },
      { title: 'Mois de stockage', field: 'storage' },
      { title: 'N° ou type de commande client', field: 'customer_reference' },
      { title: 'Référence article client', field: 'article_reference' },
      { title: 'N° de notre CDE et accusé de réception', field: 'number', readonly: true },
      { title: 'N° du bon de livraison', field: 'delivery_number', readonly: true },
      {
        title: 'Délai de livraison',
        field: 'delivery_time',
        type: 'combo-box',
        disableFiltering: true,
        listItems: this.deliveryTimeData,
        listField: 'name',
        nullText: '(Aucun)',
      },
      {
        title: 'Conditions de paiement',
        field: 'deadline',
        type: 'combo-box',
        disableFiltering: true,
        multiSelect: false,
        listItems: this.deadlineData,
        listField: 'name',
        nullText: '(Aucun)',
      },
      {
        title: 'Escompte accordé',
        field: 'discount',
        type: 'foreign-list',
        multiSelect: false,
        listItems: this.discountsData,
        listField: 'name',
        nullText: '(Aucun)',
        allowBlankValues: true,
      },
    ],
  };
  @ViewChild('orderBlockComponent') stockBlockComponent;
  public stockBlock: InfoBlock = {
    title: '',
    backColor: 'rgb(54,96,146)',
    textColor: 'white',
    fields: [
      {
        title: 'Stock à utiliser',
        field: 'use_stock',
        type: 'foreign-list',
        multiSelect: false,
        listItems: this.allowed_stocks,
        listField: 'numberNameAndDate',
        nullText: '(Aucun)',
        allowBlankValues: true,
      },
    ],
  };

  public customerBlock: InfoBlock = {
    title: '',
    backColor: 'rgb(0,0,0)',
    textColor: 'white',
    fields: [{ title: 'Client', field: 'customer.nameWithIdentifier', readonly: true }],
  };

  public _ordinatorContact: Address = null;
  private ordinatorAddressInfoBlockField: InfoBlockField = {
    title: 'Adresse',
    field: 'ordinator_address_text',
    type: 'address-text',
    listItems: { items: [] },
    listField: 'title',
    nullText: '(Aucun)',
    addressFunction: (addr: Address) => {
      return addr.getPostalAddress();
    },
    textChange: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
      this.orderCopy.changed = true;
    },
    addressChange: (
      component: InfoBlockComponent,
      block: InfoBlock,
      field: InfoBlockField,
      object: any,
      addr: Address
    ) => {
      this.orderCopy.ordinator_address = addr;
      if (addr) {
        this.orderCopy.ordinator_address_text = addr.getPostalAddress();
        if (addr.name1 || addr.name2) {
          this._ordinatorContact = addr;
          this.orderCopy.ordinator_contact = addr.contactName;
        }
        this.updateOrdinatorMails();
      }
      this.orderCopy.changed = true;
    },
    comment:
      "Sélectionnez ici l'adresse du client ordonateur (choix via l'icone ci-contre), ou entrez une adresse manuellement.<br/><br/>REMARQUE : Cela modifiera automatiquement le champ 'E-mails' ci-dessous, et le champ 'Service - Nom du contact' si des informations de service/contact sont présents dans l'adresse sélectionnée.",
  };

  public updateOrdinatorMails() {
    let mails: string[] = [];
    mails = mails.concat(this.orderCopy.ordinator_address.getMailsArray());
    if (this._ordinatorContact && this._ordinatorContact !== this.orderCopy.ordinator_address)
      mails = mails.concat(this._ordinatorContact.getMailsArray());
    let unique_mails: string[] = [];
    for (let i = 0; i < mails.length; ++i) if (!unique_mails.includes(mails[i])) unique_mails.push(mails[i]);
    this.orderCopy.ordinator_emails = unique_mails.join('; ');
  }
  public ordinatorBlock: InfoBlock = {
    title: "Adresse\nd'ordonateur",
    backColor: 'rgb(0,0,0)',
    textColor: 'white',
    fields: [
      {
        title: 'Service - Nom du contact',
        field: 'ordinator_contact',
        type: 'combo-box',
        disableFiltering: true,
        listItems: this.contactsData,
        listField: 'addressContactLine',
        nullText: '(Aucun)',
        select: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this._ordinatorContact = event;
          this.updateOrdinatorMails();
          this.orderCopy.changed = true;
          //   this.updateView(this.orderCopy);
        },
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.orderCopy.changed = true;
        },
        comment:
          "Sélectionnez ou entrez ici le nom du contact, si besoin, pour le client ordonateur.<br/><br/>REMARQUE : Cela modifiera automatique le champ 'E-mails' ci-dessous !",
      },
      this.ordinatorAddressInfoBlockField,
      {
        title: 'E-mails ordonateur',
        field: 'ordinator_emails',
        comment: 'Entrez ici les e-mails (séparés par des <b>;</b> ) auxquels seront envoyés les documents',
      },
    ],
  };

  public _invoicingContact: Address = null;
  private invoicingAddressInfoBlockField: InfoBlockField = {
    title: 'Adresse',
    field: 'invoicing_address_text',
    type: 'address-text',
    listItems: { items: [] },
    listField: 'title',
    nullText: '(Aucun)',
    addressFunction: (addr: Address) => {
      return addr.getPostalAddress();
    },
    textChange: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
      this.orderCopy.changed = true;
    },
    addressChange: (
      component: InfoBlockComponent,
      block: InfoBlock,
      field: InfoBlockField,
      object: any,
      addr: Address
    ) => {
      this.orderCopy.invoicing_address = addr;
      if (addr) {
        this.orderCopy.invoicing_address_text = addr.getPostalAddress();
        if (addr.name1 || addr.name2) {
          this._invoicingContact = addr;
          this.orderCopy.invoicing_contact = addr.contactName;
        }
        this.updateInvoicingMails();
      }
      this.orderCopy.changed = true;
      // this.updateView(this.orderCopy);
    },
    comment:
      "Sélectionnez ici l'adresse de facturation (choix via l'icone ci-contre), ou entrez une adresse manuellement.<br/><br/>REMARQUE : Cela modifiera automatiquement le champ 'E-mails' ci-dessous, et le champ 'Service - Nom du contact' si des informations de service/contact sont présents dans l'adresse sélectionnée.",
  };
  public updateInvoicingMails() {
    // invoicing mails
    let mails: string[] = this.orderCopy.invoicing_address.getInvoicingMailsArray();
    if (this._invoicingContact && this._invoicingContact !== this.orderCopy.invoicing_address)
      mails = mails.concat(this._invoicingContact.getMailsArray());
    let unique_mails: string[] = [];
    for (let i = 0; i < mails.length; ++i) if (!unique_mails.includes(mails[i])) unique_mails.push(mails[i]);
    this.orderCopy.invoicing_emails = unique_mails.join('; ');
    // reminders mails
    mails = this.orderCopy.invoicing_address.getAccountingMailsArray();
    unique_mails = [];
    for (let i = 0; i < mails.length; ++i) if (!unique_mails.includes(mails[i])) unique_mails.push(mails[i]);
    this.orderCopy.reminders_emails = unique_mails.join('; ');
  }
  public invoicingBlock: InfoBlock = {
    title: 'Adresse\nde facturation',
    backColor: 'rgb(0,0,0)',
    textColor: 'white',
    fields: [
      {
        title: 'Service - Nom du contact',
        field: 'invoicing_contact',
        type: 'combo-box',
        disableFiltering: true,
        listItems: this.contactsData,
        listField: 'addressContactLine',
        nullText: '(Aucun)',
        select: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this._invoicingContact = event;
          this.updateInvoicingMails();
          this.orderCopy.changed = true;
        },
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.orderCopy.changed = true;
        },
        comment:
          "Sélectionnez ou entrez ici le nom du contact, si besoin, pour la facturation.<br/><br/>REMARQUE : Cela modifiera automatiquement le champ 'E-mails' ci-dessous !",
      },
      this.invoicingAddressInfoBlockField,
      {
        title: 'E-mails facturation',
        field: 'invoicing_emails',
        comment: 'Entrez ici les e-mails (séparés par des <b>;</b> ) auxquels seront envoyées les factures',
      },
      {
        title: 'E-mails réclamation',
        field: 'reminders_emails',
        comment: 'Entrez ici les e-mails (séparés par des <b>;</b> ) auxquels seront envoyées les rappels de paiement',
      },
    ],
  };

  public _deliveryContact: Address = null;
  private deliveryAddressInfoBlockField: InfoBlockField = {
    title: 'Adresse',
    field: 'delivery_address_text',
    type: 'address-text',
    listItems: { items: [] },
    listField: 'title',
    nullText: '(Aucun)',
    addressFunction: (addr: Address) => {
      return addr.getPostalAddress();
    },
    textChange: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
      this.orderCopy.changed = true;
    },
    addressChange: (
      component: InfoBlockComponent,
      block: InfoBlock,
      field: InfoBlockField,
      object: any,
      addr: Address
    ) => {
      this.orderCopy.delivery_address = addr;
      if (addr) {
        this.orderCopy.delivery_address_text = addr.getPostalAddress();
        this.orderCopy.opening_hours = addr.opening_hours;
        if (addr.name1 || addr.name2) {
          this._deliveryContact = addr;
          this.orderCopy.delivery_contact = addr.contactName;
        }
      }
      this.orderCopy.changed = true;
      // this.updateView(this.orderCopy);
    },
    comment:
      "Sélectionnez ici l'adresse de livraison (choix via l'icone ci-contre), ou entrez une adresse manuellement.<br/><br/>REMARQUE : Cela modifiera automatiquement le champ 'Service - Nom du contact' si des informations de service/contact sont présents dans l'adresse sélectionnée.",
  };
  public deliveryBlock: InfoBlock = {
    title: 'Adresse\nde livraison',
    backColor: 'rgb(0,0,0)',
    textColor: 'white',
    fields: [
      {
        title: 'Service - Nom du contact',
        field: 'delivery_contact',
        type: 'combo-box',
        disableFiltering: true,
        listItems: this.contactsData,
        listField: 'addressContactLine',
        nullText: '(Aucun)',
        comment: 'Sélectionnez ou entrez ici le nom du contact, si besoin, pour la livraison.',
        select: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.orderCopy.changed = true;
        },
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.orderCopy.changed = true;
        },
      },
      this.deliveryAddressInfoBlockField,
      { title: "Heures d'ouverture", field: 'opening_hours' },
      { title: 'Enlèvement par nos soins', field: 'self_delivery', type: 'checkbox' },
    ],
  };

  public marginsBlock: InfoBlock = {
    title: 'Marges',
    backColor: 'rgb(54,96,146)',
    textColor: 'white',
    fields: [
      {
        title: 'Marge',
        field: 'total_netMargin',
        type: 'number',
        decimalsCount: 2,
        textAlign: 'left',
        numberUnit: '€',
      },
      {
        title: 'Taux de marge',
        field: 'total_netMarginPerc',
        type: 'number',
        decimalsCount: 2,
        textAlign: 'left',
        numberUnit: '%',
      },
      {
        title: 'Taux de marque',
        field: 'total_netMarkupPerc',
        type: 'number',
        decimalsCount: 2,
        textAlign: 'left',
        numberUnit: '%',
      },
    ],
  };

  public accountingBlock: InfoBlock = {
    title: '',
    backColor: 'rgb(64,0,64)',
    textColor: 'white',
    fields: [
      {
        title: 'Comptabilité',
        field: 'accounting',
        type: 'foreign-list',
        multiSelect: false,
        listItems: this.accountingsData,
        listField: 'fullDescription',
        allowBlankValues: false,
        comment: 'Sélectionnez ici la comptabilité liée à ce dossier',
      },
      {
        title: "Devise d'achat",
        field: 'buy_currency',
        type: 'foreign-list',
        multiSelect: false,
        listItems: this.currenciesData,
        listField: 'fullDescription',
        allowBlankValues: false,
        comment: 'Sélectionnez ici la devise à utiliser pour ce dossier',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.orderCopy.buy_xrate = this.orderCopy.buy_currency.rate;
          //this.updateCurrenciesInPrices(this.saleCopy.currency, this.saleCopy.xrate);
        },
      },
      { title: "Taux de change d'achat", field: 'buy_xrate', type: 'number', decimalsCount: 6, textAlign: 'left' },
      {
        title: 'Devise de vente',
        field: 'sell_currency',
        type: 'foreign-list',
        multiSelect: false,
        listItems: this.currenciesData,
        listField: 'fullDescription',
        allowBlankValues: false,
        comment: 'Sélectionnez ici la devise à utiliser pour ce dossier',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.orderCopy.sell_xrate = this.orderCopy.sell_currency.rate;
          //this.updateCurrenciesInPrices(this.saleCopy.currency, this.saleCopy.xrate);
        },
      },
      { title: 'Taux de change de vente', field: 'sell_xrate', type: 'number', decimalsCount: 6, textAlign: 'left' },
    ],
  };

  public saleRemarksBlock: InfoBlock = {
    title: 'Remarques\ndossier',
    backColor: 'rgb(226, 107, 10)',
    textColor: 'white',
    fields: [
      {
        title: 'Remarques pour les demandes de prix',
        field: 'demand_remarks',
        type: 'textarea',
        comment:
          'Entrez ici les remarques que vous voulez faire apparaître sur les demandes de prix pour les fournisseurs, dans le cadre Remarques',
      },
      {
        title: "Remarques pour l'offre de prix",
        field: 'offer_remarks',
        type: 'textarea',
        comment:
          "Entrez ici les remarques que vous voulez faire apparaître sur l'offre de prix pour le client, dans le cadre Remarques",
      },
    ],
  };
  public orderRemarksBlock: InfoBlock = {
    title: 'Remarques\ncommande',
    backColor: 'rgb(255, 192, 0)',
    textColor: 'black',
    fields: [
      {
        title: 'Remarques pour la commande',
        field: 'remarks_order',
        type: 'textarea',
        comment:
          'Entrez ici les remarques que vous voulez faire apparaître sur le <b>BON DE COMMANDE</b>, dans le cadre Remarques',
      },
      {
        title: "Remarques pour l'accusé de réception",
        field: 'remarks_ack',
        type: 'textarea',
        comment:
          "Entrez ici les remarques que vous voulez faire apparaître sur l'<b>ACCUSÉ DE RÉCEPTION</b>, dans le cadre Remarques",
      },
      {
        title: 'Remarques pour le bon de livraison',
        field: 'remarks_note',
        type: 'textarea',
        comment:
          'Entrez ici les remarques que vous voulez faire apparaître sur le <b>BON DE LIVRAISON</b>, dans le cadre Remarques',
      },
      {
        title: '(Remarques internes)',
        field: 'internal_remarks',
        type: 'textarea',
        comment: 'Entrez ici les remarques internes pour la commande (visibles dans le livre des commandes)',
      },
    ],
  };

  public articleBlock: InfoBlock = ArticleInfoBlocks.generateReadOnlyArticleBlock();
  public deviceBlock: InfoBlock = ArticleInfoBlocks.generateReadOnlyDeviceBlock();
  public serviceBlock: InfoBlock = ArticleInfoBlocks.generateReadOnlyServiceBlock();

  public get orderCopyArticleIsArticle() {
    return this.orderCopyArticle && this.orderCopyArticle.type === 0;
  }
  public get orderCopyArticleIsDevice() {
    return this.orderCopyArticle && this.orderCopyArticle.type === 1;
  }
  public get orderCopyArticleIsService() {
    return this.orderCopyArticle && this.orderCopyArticle.type === 2;
  }
  public get orderCopyArticle() {
    if (this.orderCopy && this.orderCopy) return this.orderCopy.article;
    return null;
  }

  ngOnInit() {
    this.accountingsData.items = AccountingsService.accountings;
    this.currenciesData.items = CurrenciesService.currencies;
    Discount.load(null, ['name']).then(
      (result) => {
        this.discountsData.items = result;
      },
      (err) => {
        console.error(err);
      }
    );
    DeliveryTime.load(null, ['name']).then(
      (result) => {
        this.deliveryTimeData.items = ArrayUtil.sortQuantityArray(result, 'name');
      },
      (err) => {
        console.log(err);
      }
    );
    Deadline.load(null, ['name']).then(
      (result) => {
        this.deadlineData.items = ArrayUtil.sortQuantityArray(result, 'name');
      },
      (err) => {
        console.log(err);
      }
    );
    Supplier.load(null, ['name']).then(
      (result) => {
        this.suppliersData.items = result;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  initView(order: Order) {
    if (!order) {
      order = new Order();
      order.accounting = AccountingsService.currentAccounting;
      order.buy_currency = order.accounting.default_currency;
      order.buy_xrate = order.buy_currency.rate;
      order.sell_currency = order.accounting.default_currency;
      order.sell_xrate = order.sell_currency.rate;
    }
    this.order = order;
    this.orderCopy = order.clone(true);
    if (!this.orderCopy.id) this.reloadSales();
    this.selectedSale = this.orderCopy.sale;
    this.updateView(this.orderCopy);
  }

  onAccountingChanged(accounting: Accounting) {
    this.reloadSales();
  }

  reloadSales() {
    let conditions: string[] = [];
    if (AccountingsService.currentAccounting)
      conditions.push(`id_accounting='${AccountingsService.currentAccounting.id}'`);
    conditions.push('archived != 1');
    Sale.load(null, ['number'], null, null, conditions.join(' AND ')).then(
      (result) => {
        this.offersData.items = result;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  updateView(order: Order) {
    console.log('update view: ', order);
    this.priceSelectionField.visible = !order.id;
    this.priceField.visible = !!order.id;

    if (this.selectedSale instanceof Sale && !order.id) {
      if (!order.sale || this.selectedSale.id != order.sale.id) {
        this.selectedSale.prices.sort((a: Price, b: Price) => {
          return a.quantity - b.quantity;
        });
        this.pricesData.items = this.selectedSale.prices.filter((p) => p.show == 1);
        if (this.pricesData.items.length > 0) order.price = this.pricesData.items[0];
        else order.price = null;
        if (this.selectedSale.demand_remarks && this.selectedSale.copy_demand_remarks == 1)
          order.remarks_order = this.selectedSale.demand_remarks;
        if (this.selectedSale.offer_remarks && this.selectedSale.copy_offer_remarks == 1)
          order.remarks_ack = this.selectedSale.offer_remarks;
        this.selectedPriceChanged();
      }
      order.sale = this.selectedSale;
      if (!order.date) order.date = DateTimeUtil.toDateString(new Date());

      order.merchant = this.selectedSale.merchant;
      order.customer = this.selectedSale.customer;
      order.article = this.selectedSale.article;
      order.article_reference = this.selectedSale.article_reference;

      order.ordinator_address_text = this.selectedSale.ordinator_address_text;
      order.ordinator_contact = this.selectedSale.ordinator_contact;
      order.ordinator_emails = this.selectedSale.ordinator_emails;
      order.invoicing_address_text = this.selectedSale.invoicing_address_text;
      order.invoicing_contact = this.selectedSale.invoicing_contact;
      order.invoicing_emails = this.selectedSale.invoicing_emails;
      order.reminders_emails = this.selectedSale.reminders_emails;
      order.delivery_address_text = this.selectedSale.delivery_address_text;
      order.opening_hours = this.selectedSale.opening_hours;
      order.delivery_contact = this.selectedSale.delivery_contact;

      order.delivery_time = this.selectedSale.delivery_time;
      order.deadline = this.selectedSale.deadline;

      order.discount = this.selectedSale.discount;
      order.discount_days = this.selectedSale.discount_days;

      order.accounting = this.selectedSale.accounting;
      order.sell_currency = this.selectedSale.currency;
      order.sell_xrate = this.selectedSale.xrate;

      //if (!order.number)
      //{
      order.getNextNumber().then(
        (result) => {
          order.number = order.generateNumber(result.details);
          order.delivery_number = order.generateNumber(result.details, '13');
        },
        (err) => {
          console.log(err);
        }
      );

      ServerApi.callModule('customers', 'allowedStocks', {
        id_article: this.selectedSale.article.id,
        id_customer: this.selectedSale.customer.id,
      }).then(
        (result) => {
          this.orderCopy.use_stock = null;
          Stock.load(result.details).then(
            (result2) => {
              console.log('allowed stocks:', result2);
              this.allowed_stocks.items = result2;
            },
            (err) => {
              console.error(err);
              this.allowed_stocks.items = [];
            }
          );
        },
        (err) => {
          this.allowed_stocks.items = [];
        }
      );
      //}
    } else {
      if (order.sale && Array.isArray(order.sale.prices)) {
        this.pricesData.items = order.sale.prices;
        if (order.sale.prices.length > 0) order.price = order.sale.prices[0];
        else order.price = null;
      } else {
        this.pricesData.items = null;
        order.price = null;
      }
      //this.selectedPriceChanged();
    }

    let gsAddr: Address = new Address();
    gsAddr.name2 = 'G.S.';
    gsAddr.name1 = "A l'attention de Mme Gillian Townley";
    gsAddr.address1 = 'Rue du Vertbois, 11 bte 20';
    gsAddr.postcode = '4000';
    gsAddr.city = 'Liège';
    let pickupAddr: Address = new Address();
    pickupAddr.name1 = 'Enlèvement par nos soins';
    pickupAddr.country = null;
    this.contactsData.items = order.customer ? order.customer.contacts : [];
    this.deliveryAddressInfoBlockField.listItems.items = order.customer
      ? order.customer.deliveryAddressesList.concat(
          { name: `Chez ${config.companyName}`, address: gsAddr },
          { name: 'Enlèvement', address: pickupAddr }
        )
      : [];
    this.ordinatorAddressInfoBlockField.listItems.items = order.customer
      ? [{ name: "Adresse d'ordonateur", address: order.customer.ordinator_address }]
      : [];
    this.invoicingAddressInfoBlockField.listItems.items = order.customer ? order.customer.invoicingAddressesList : [];
  }
  selectedPriceChanged() {
    if (this.orderCopy.price != null) {
      this.orderCopy.supplier = this.orderCopy.price.supplier;
      this.orderCopy.unit = this.orderCopy.price.unit;
      this.orderCopy.quantity = this.orderCopy.price.quantity;
      this.orderCopy.buy_price = this.orderCopy.price.unit_price;
      this.orderCopy.internal_fees = this.orderCopy.price.internal_fees;
      this.orderCopy.external_fees = this.orderCopy.price.external_fees;
      this.orderCopy.supplier_fees = this.orderCopy.price.supplier_fees;
      this.orderCopy.agent_fees = this.orderCopy.price.agent_fees;
      this.orderCopy.sell_price = this.orderCopy.price.sell_price;
      this.orderCopy.supplier_reference = this.orderCopy.price.reference;
      this.orderCopy.supplier_date = this.orderCopy.price.date;
      this.orderCopy.invoicings = this.orderCopy.price.invoicings;
      this.orderCopy.deliveries = this.orderCopy.price.deliveries;
      this.orderCopy.productions = this.orderCopy.price.productions;
      this.orderCopy.storage = this.orderCopy.price.storage;
      this.orderCopy.buy_currency = this.orderCopy.price.buy_currency;
      this.orderCopy.buy_xrate = this.orderCopy.price.buy_xrate;
      this.orderCopy.use_stock = null;
    } else {
      this.orderCopy.supplier = null;
      this.orderCopy.unit = null;
      this.orderCopy.quantity = null;
      this.orderCopy.buy_price = null;
      this.orderCopy.sell_price = null;
      this.orderCopy.internal_fees = null;
      this.orderCopy.supplier_fees = null;
      this.orderCopy.external_fees = null;
      this.orderCopy.supplier_reference = null;
      this.orderCopy.supplier_date = null;
      this.orderCopy.invoicings = null;
      this.orderCopy.deliveries = null;
      this.orderCopy.productions = null;
      this.orderCopy.storage = null;
      this.orderCopy.buy_currency = null;
      this.orderCopy.buy_xrate = null;
      this.orderCopy.use_stock = null;
    }
  }

  editNumber() {
    DialogsComponent.displayComponent(ChangeOrderNumberComponent, {
      newNumber: {
        number: this.orderCopy.number,
        delivery_number: this.orderCopy.delivery_number,
      },
    }).then((result) => {
      if (result !== DialogButton.RESULT_CANCEL) {
        this.orderCopy.number = result.number;
        this.orderCopy.delivery_number = result.delivery_number;
      }
    });
  }

  validateForm() {
    let form: Form = {
      fields: [
        {
          name: 'Date',
          type: 'string',
          data: this.orderCopy,
          field: 'date',
          pattern: /[0-9]{4}-[0-9]{2}-[0-9]{2}/,
          element: this.orderBlockComponent.getElement('date'),
        },
        {
          name: 'Quantité et unité',
          type: 'foreign-list',
          data: this.orderCopy,
          field: 'price',
          pattern: null,
          element: this.orderBlockComponent.getElement('price'),
        },
        {
          name: 'Fournisseur',
          type: 'foreign-list',
          data: this.orderCopy,
          field: 'supplier',
          pattern: null,
          element: this.orderBlockComponent.getElement('supplier'),
        },
        {
          name: "Prix d'achat",
          type: 'string',
          data: this.orderCopy,
          field: 'buy_price',
          pattern: /.*/,
          element: this.orderBlockComponent.getElement('buy_price'),
        },
        {
          name: 'Prix de vente',
          type: 'string',
          data: this.orderCopy,
          field: 'sell_price',
          pattern: /.*/,
          element: this.orderBlockComponent.getElement('sell_price'),
        },
        {
          name: 'Nombre de facturations',
          type: 'string',
          data: this.orderCopy,
          field: 'invoicings',
          pattern: /[0-9]+/,
          element: this.orderBlockComponent.getElement('invoicings'),
        },
        {
          name: 'Nombre de livraisons',
          type: 'string',
          data: this.orderCopy,
          field: 'deliveries',
          pattern: /[0-9]+/,
          element: this.orderBlockComponent.getElement('deliveries'),
        },
        {
          name: 'Mois de stockage',
          type: 'string',
          data: this.orderCopy,
          field: 'storage',
          pattern: /[0-9]+/,
          element: this.orderBlockComponent.getElement('storage'),
        },
        {
          name: 'Délai de livraison',
          type: 'string',
          data: this.orderCopy,
          field: 'delivery_time',
          pattern: /.*/,
          element: this.orderBlockComponent.getElement('delivery_time'),
        },
        {
          name: 'Conditions de paiement',
          type: 'string',
          data: this.orderCopy,
          field: 'deadline',
          pattern: /.*/,
          element: this.orderBlockComponent.getElement('deadline'),
        },
      ],
    };
    let result = FormValidator.validateForm(form);
    FormValidator.showFormInvalidFields(form, result);
    if (result !== true) {
      FormValidator.showNotification(result);
      return false;
    } else return true;
  }

  save() {
    let canSave: boolean = true;
    let createStock: boolean = false;
    let useStock: boolean = false;
    let selectedStock: Stock = this.orderCopy.use_stock;
    return new Promise<any>((resolve, reject) => {
      if (this.validateForm() !== true) reject('invalid form');
      else {
        let order_date: string = this.orderCopy.date;
        let today_date: string = DateTimeUtil.toDateString(new Date());
        let promises: any[] = [];
        if (order_date != today_date) {
          promises.push(
            DialogsComponent.display({
              icon: 'question',
              title: 'Date du dossier',
              message:
                'Vous avez spécifié la date suivante pour la commande : <b>' +
                order_date +
                '</b><br/>' +
                'Voulez-vous changer pour la date du jour <b>' +
                today_date +
                '</b> ?',
              buttons: [
                { text: 'Oui, changer la date', result: DialogButton.RESULT_YES },
                { text: 'Non, merci !', result: DialogButton.RESULT_NO },
                DialogButton.cancelButton,
              ],
            }).then((result) => {
              if (result == DialogButton.RESULT_YES) this.orderCopy.date = today_date;
              if (result == DialogButton.RESULT_CANCEL) canSave = false;
            })
          );
        }
        if (this.orderCopy.deliveries > 1) {
          promises.push(
            DialogsComponent.display({
              icon: 'question',
              title: 'Commande pour stock ?',
              message:
                'Vous avez spécifié le nombre de <b>' +
                this.orderCopy.deliveries +
                '</b> livraisons.<br/>' +
                'Voulez-vous directement créer le <b>stock</b> correspondant à cette commande ?',
              buttons: [
                { text: 'Oui, créer le stock', result: DialogButton.RESULT_YES },
                { text: 'Non, le créer plus tard', result: DialogButton.RESULT_NO },
                DialogButton.cancelButton,
              ],
            }).then((result) => {
              if (result == DialogButton.RESULT_YES) createStock = true;
              if (result == DialogButton.RESULT_CANCEL) canSave = false;
            })
          );
        } else if (this.orderCopy.use_stock != null) {
          if (this.orderCopy.use_stock.availableStock < this.orderCopy.quantity) {
            canSave = false;
            promises.push(
              DialogsComponent.display({
                icon: 'warning',
                title: 'Stock insuffisant',
                message:
                  'Le stock sélectionné <b>' +
                  this.orderCopy.use_stock.number +
                  '</b> est insuffisant (<b>' +
                  this.orderCopy.use_stock.stockAndUnit +
                  '</b> restant(e)(s))',
                buttons: [DialogButton.okButton],
              })
            );
          } else {
            promises.push(
              DialogsComponent.display({
                icon: 'question',
                title: 'Utiliser le stock spécifié ?',
                message:
                  'Vous avez sélectionné le stock <b>' +
                  this.orderCopy.use_stock.number +
                  '</b><br/>' +
                  'Le stock va être réduit de <b>' +
                  this.orderCopy.quantityAndUnit +
                  '</b>. Confirmer ?',
                buttons: [
                  { text: 'Oui, utiliser ce stock', result: DialogButton.RESULT_YES },
                  { text: 'Non, annuler', result: DialogButton.RESULT_NO },
                  DialogButton.cancelButton,
                ],
              }).then((result) => {
                if (result == DialogButton.RESULT_YES) useStock = true;
                else canSave = false;
              })
            );
          }
        }
        Promise.all(promises).then(
          (result) => {
            if (!canSave) {
              NotificationsComponent.push({
                type: 'error',
                title: 'Sauvegarde annulée',
                summary: "L'enregistrement a été annulé par l'utilisateur",
              });
              reject('cancelled by user');
            } else
              this.save2().then(
                (result) => {
                  if (createStock) {
                    let stock: Stock = new Stock();
                    stock.copyFromOrder(this.order);
                    stock.creation_date = DateTimeUtil.toDateString(new Date());
                    stock.update_date = stock.creation_date;
                    stock.save2().then(
                      (result2) => {
                        resolve(result2);
                      },
                      (err2) => {
                        reject(err2);
                      }
                    );
                  } else if (useStock) {
                    let adjustment: StockAdjustment = new StockAdjustment();
                    adjustment.accounting = this.order.accounting;
                    adjustment.date = DateTimeUtil.toDateString(new Date());
                    adjustment.stock = this.orderCopy.use_stock;
                    adjustment.quantity = -this.order.quantity;
                    adjustment.order = this.order;
                    adjustment.remarks =
                      'Commande ' +
                      this.order.number +
                      ' du ' +
                      DateTimeUtil.format(new Date(this.order.date), 'd/m/Y') +
                      ' (client ' +
                      this.order.customer.name +
                      ')';
                    this.orderCopy.use_stock.adjustments.push(adjustment);
                    this.orderCopy.use_stock.save3().then(
                      (result2) => {
                        resolve(result2);
                      },
                      (err2) => {
                        reject(err2);
                      }
                    );
                  } else resolve(result);
                },
                (err) => {
                  console.error(err);
                  reject(err);
                }
              );
          },
          (err) => {
            NotificationsComponent.push({
              type: 'error',
              title: 'Sauvegarde annulée',
              summary: "Une erreur s'est produite lors de la pré-sauvegarde",
              content: err,
            });
            console.error(err);
            reject(err);
          }
        );
      }
    });
  }

  save2() {
    return new Promise<any>((resolve, reject) => {
      console.log(this.orderCopy);
      this.orderCopy.save2().then(
        (result) => {
          this.orderCopy.clone(true, this.order);
          this.initView(this.order);
          resolve(result);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  public iterateFiles(includeCustomer: boolean = true, includeSupplier: boolean = true, callback) {
    let models: PrintingModel[] = [];
    if (includeCustomer === true) models.push(OrderAckPrintingModel.generate(this.orderCopy));
    if (includeSupplier === true) {
      models.push(OrderPrintingModel.generate(this.orderCopy));
      if (this.orderCopy.deliveries === 1) {
        models.push(DeliveryNotePrintingModel.generate(this.orderCopy));
        models.push(StickersPrintingModel.generate(this.orderCopy));
      }
    }
    return PrintPreviewViewComponent.globalInstance.iteratePrintingModels(models, callback);
  }

  public saveFiles() {
    this.iterateFiles(true, true, (model: PrintingModel, index: number) => {
      let path: string = '';
      if (model instanceof OrderPrintingModel)
        path = FilesystemUtil.resolveSpecialPath(SettingsComponent.get('pdf_orders_save_path'));
      else if (model instanceof OrderAckPrintingModel)
        path = FilesystemUtil.resolveSpecialPath(SettingsComponent.get('pdf_order_acks_save_path'));
      else if (model instanceof DeliveryNotePrintingModel)
        path = FilesystemUtil.resolveSpecialPath(SettingsComponent.get('pdf_delivery_notes_save_path'));
      else if (model instanceof StickersPrintingModel)
        path = FilesystemUtil.resolveSpecialPath(SettingsComponent.get('pdf_labels_save_path'));
      return PrintPreviewViewComponent.globalInstance.saveFileAs(path + model.saveFilename);
    });
  }

  public sendToSupplier() {
    return LoadingPromise.create<any>((resolve, reject) => {
      let lang: string = this.orderCopy.supplier.lang ? this.orderCopy.supplier.lang.code || 'fr' : 'fr';
      let bcc: string[] = config.orderMailBcc;
      let filenames: string[] = [];
      if (!bcc.includes(this.orderCopy.merchant.email)) bcc.push(this.orderCopy.merchant.email);
      this.iterateFiles(false, true, (model: PrintingModel, index: number) => {
        return PrintPreviewViewComponent.globalInstance.saveAsTempFilename(model.mailFilename).then(
          (result) => {
            filenames.push(result + '|' + model.mailFilename);
            resolve(result);
          },
          (err) => {
            reject(err);
          }
        );
      }).then(
        (result) => {
          filenames.push(FilesystemUtil.resolveSpecialPath(config.tcPdfPath) + '|CONDITIONS GÉNÉRALES ACHATS.pdf');
          ExternalService.createMail(
            this.orderCopy.supplier.commercial_address.email,
            null,
            bcc.join('; '),
            {
              fr: 'Notre commande : ' + this.orderCopy.number,
              en: 'Our order : ' + this.orderCopy.number,
              nl: 'Our order : ' + this.orderCopy.number,
            }[lang],
            {
              fr:
                '<p>Madame, Monsieur,</p>' +
                "<p>Faisant suite à votre offre, nous avons le plaisir de vous passer commande.<br/>Vous trouverez les documents d'exécution en pièces jointes." +
                '<p>En vous remerciant, par avance, de votre bonne collaboration, nous vous prions de croire, Madame, Monsieur, en nos salutations les plus sincères.</p>' +
                '<p>Cordialement,</p>',
              en:
                '<p>Dear Partner,</p>' +
                '<p>Following your offer, please accept our attached order with all the relevant documents.</p>' +
                `<p>We have included our ${config.companyName} branded delivery note and labels. Could you please use 1 label per box or pallet and inform us when the order has been shipped.</p>` +
                '<p>Thanking you in advance for your collaboration.</p>' +
                '<p>Yours faithfully,</p>',
              nl:
                '<p>Dear Partner,</p>' +
                '<p>Following your offer, please accept our attached order with all the relevant documents.</p>' +
                `<p>We have included our ${config.companyName} branded delivery note and labels. Could you please use 1 label per box or pallet and inform us when the order has been shipped.</p>` +
                '<p>Thanking you in advance for your collaboration.</p>' +
                '<p>Yours faithfully,</p>',
            }[lang],
            filenames,
            CredentialsService.loggedMerchant.outlook_signature
          ).then(
            (result) => {
              resolve(result);
            },
            (err) => {
              reject(err);
            }
          );
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  public sendToCustomer() {
    let lang: string = this.orderCopy.customer.lang ? this.orderCopy.customer.lang.code || 'fr' : 'fr';
    let bcc: string[] = config.orderMailBcc;
    if (!bcc.includes(this.orderCopy.merchant.email)) bcc.push(this.orderCopy.merchant.email);
    return this.iterateFiles(true, false, (model: PrintingModel, index: number) => {
      return PrintPreviewViewComponent.globalInstance.sendByMail(
        this.orderCopy.ordinator_emails,
        null,
        bcc.join('; '),
        {
          fr:
            'Notre commande : ' +
            this.orderCopy.number +
            (this.orderCopy.customer_reference ? ' - Votre commande : ' + this.orderCopy.customer_reference : ''),
          en:
            'Our order : ' +
            this.orderCopy.number +
            (this.orderCopy.customer_reference ? ' - Your order : ' + this.orderCopy.customer_reference : ''),
          nl:
            'Our order : ' +
            this.orderCopy.number +
            (this.orderCopy.customer_reference ? ' - Your order : ' + this.orderCopy.customer_reference : ''),
        }[lang],
        {
          fr:
            '<p>Madame, Monsieur,</p>' +
            '<p>Faisant suite à votre commande susmentionnée, pour laquelle nous vous remercions, vous trouverez notre accusé de réception de commande, en pièce jointe.</p>' +
            "<p>En restant à votre entière disposition pour d'éventuelles questions, nous vous prions de croire, Madame, Monsieur, en nos salutations les plus sincères.</p>" +
            '<p>Cordialement,</p>',
          en:
            '<p>Dear Sir / Madam,</p>' +
            '<p>Thank you for your valued order.</p>' +
            '<p>Attached you will find our order confirmation with all the relevant information and references.</p>' +
            '<p>Please do not hesitate to contact us for any questions.</p>' +
            '<p>Yours faithfully,</p>',
          nl:
            '<p>Dear Sir / Madam,</p>' +
            '<p>Thank you for your valued order.</p>' +
            '<p>Attached you will find our order confirmation with all the relevant information and references.</p>' +
            '<p>Please do not hesitate to contact us for any questions.</p>' +
            '<p>Yours faithfully,</p>',
        }[lang],
        model.mailFilename
      );
      // Tells the print preview component to stop sending the mail
    }).then(() => false);
  }
}
