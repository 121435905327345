import { AccountingsService } from 'src/app/services/accountings/accountings.service';
import { Charge } from './Charge.class';
import { ChargeType } from './ChargeType.class';
import { DataService } from 'src/app/services/data/data.service';
import { LoadingPromise } from 'src/app/classes/objects/LoadingPromise.class';
import { NumberUtil } from '../../utils/classes/NumberUtil.class';
import { ObjectUtil } from '../../utils/classes/ObjectUtil.class';
import { ReportCell } from '../../reports/classes/ReportCell.class';
import { ReportHeaderRow } from '../../reports/classes/ReportHeaderRow.class';
import { ReportModel } from '../../reports/classes/ReportModel.class';
import { ReportRow } from '../../reports/classes/ReportRow.class';
import { ReportSetting } from '../../reports/classes/ReportSetting.class';

export class ChargesReportModel extends ReportModel {
  public title: string = 'Consolidation des charges';
  public settings: ReportSetting[] = [
    { name: 'year', title: 'Année', type: 'number', value: new Date().getFullYear() },
  ];

  public headerRows: ReportHeaderRow[] = [{ cells: [] }, { cells: [] }];
  public rows: ReportRow[] = [];

  private static monthCellStyle: any = {
    'background-color': 'rgb(128,128,128)',
    color: 'white',
    'font-weight': 'bold',
  };
  private static totalHeaderCellStyle: any = {
    'background-color': 'rgb(49,134,155)',
    color: 'white',
  };
  private static totalRowCellStyle: any = {
    'background-color': 'rgb(153, 204, 255)',
    color: 'black',
  };

  public chargeTypes: ChargeType[] = [];
  public charges: Charge[] = [];

  public static generate(settings: ReportSetting[] = null) {
    let model: ChargesReportModel = new ChargesReportModel();
    if (settings) model.settings = settings;
    model.regenerate();
    return model;
  }

  public regenerate(): Promise<any> {
    return LoadingPromise.create<any>((resolve, reject) => {
      var self = this;
      let symbol: string = AccountingsService.currentAccounting.default_currency.symbol;
      ChargeType.load(null, ['id']).then(
        (result: ChargeType[]) => {
          this.headerRows[0].cells = [new ReportCell()];
          this.headerRows[1].cells = [new ReportCell()];
          this.chargeTypes = result;
          for (let i = 0; i < result.length; ++i) {
            this.headerRows[0].cells.push({ value: result[i].name, colSpan: 2 });
            this.headerRows[1].cells.push({ value: 'Montant de la charge', style: { 'min-width': '100px' } });
            this.headerRows[1].cells.push({
              value: 'T.V.A. à récupérer' + (result[i].default_vat ? ' (' + result[i].default_vat + ' %)' : ''),
              style: { 'min-width': '100px' },
            });
          }
          this.headerRows[0].cells.push({
            value: 'CHARGES TOTALES',
            colSpan: 3,
            style: ChargesReportModel.totalHeaderCellStyle,
          });
          this.headerRows[1].cells.push({
            value: 'Montant des charges',
            style: ObjectUtil.merge(ChargesReportModel.totalHeaderCellStyle, { 'min-width': '100px' }),
          });
          this.headerRows[1].cells.push({
            value: 'T.V.A. à récupérer',
            style: ObjectUtil.merge(ChargesReportModel.totalHeaderCellStyle, { 'min-width': '100px' }),
          });
          this.headerRows[1].cells.push({
            value: 'Montants + TVA',
            style: ObjectUtil.merge(ChargesReportModel.totalHeaderCellStyle, { 'min-width': '100px' }),
          });

          this.rows = [
            { cells: [{ value: 'Janvier', style: ChargesReportModel.monthCellStyle }] },
            { cells: [{ value: 'Février', style: ChargesReportModel.monthCellStyle }] },
            { cells: [{ value: 'Mars', style: ChargesReportModel.monthCellStyle }] },
            { cells: [{ value: 'Avril', style: ChargesReportModel.monthCellStyle }] },
            { cells: [{ value: 'Mai', style: ChargesReportModel.monthCellStyle }] },
            { cells: [{ value: 'Juin', style: ChargesReportModel.monthCellStyle }] },
            { cells: [{ value: 'Juillet', style: ChargesReportModel.monthCellStyle }] },
            { cells: [{ value: 'Aout', style: ChargesReportModel.monthCellStyle }] },
            { cells: [{ value: 'Septembre', style: ChargesReportModel.monthCellStyle }] },
            { cells: [{ value: 'Octobre', style: ChargesReportModel.monthCellStyle }] },
            { cells: [{ value: 'Novembre', style: ChargesReportModel.monthCellStyle }] },
            { cells: [{ value: 'Décembre', style: ChargesReportModel.monthCellStyle }] },
            { cells: [{ value: 'TOTAUX', style: ChargesReportModel.totalHeaderCellStyle }] },
          ];

          DataService.select({
            definition: {
              database: { table: 'charges' },
              values: {
                month: { type: 'number', column_formula: 'MONTH(a.date)' },
                amount: { type: 'number', column_formula: 'SUM(a.amount*a.xrate)' },
                vat: { type: 'number', column_formula: 'SUM(a.vat*a.xrate)' },
                id_type: { type: 'number' },
              },
            },
            conditions:
              "id_accounting='" +
              AccountingsService.currentAccounting.id +
              "' AND date BETWEEN CAST('" +
              this.getSetting('year').value +
              "-01-01' AS DATE) AND CAST('" +
              this.getSetting('year').value +
              "-12-31' AS DATE)",
            orderBy: null,
            groupBy: ['MONTH(a.date)', 'a.id_type'],
          }).then(
            (result2) => {
              console.log('result:', result2);
              let total_amounts_by_month: any = {};
              let total_vats_by_month: any = {};
              let total_amounts_by_type: any = {};
              let total_vats_by_type: any = {};
              for (let i = 0; i < result2.details.length; ++i) {
                try {
                  let month: number = parseInt(result2.details[i]['month']);
                  let id_type: string = result2.details[i]['id_type'];
                  let type: ChargeType = this.chargeTypes.find((value: ChargeType) => value.id == id_type);
                  let row: ReportRow = this.rows[month - 1];
                  let col: number = this.chargeTypes.indexOf(type);
                  let cell1: ReportCell = new ReportCell();
                  let amount: any = result2.details[i]['amount'];
                  let amount_value: number = parseFloat(amount);
                  cell1.value =
                    amount_value && amount_value != 0 ? NumberUtil.formatMoney(amount_value, symbol, 2, '.') : '';
                  cell1.style = ReportCell.moneyCellStyle;
                  row.cells[2 * col + 1] = cell1;
                  let cell2: ReportCell = new ReportCell();
                  let vat: any = result2.details[i]['vat'];
                  let vat_value: number = parseFloat(vat);
                  cell2.value = vat_value && vat_value != 0 ? NumberUtil.formatMoney(vat_value, symbol, 2, '.') : '';
                  cell2.style = ReportCell.moneyCellStyle;
                  row.cells[2 * col + 2] = cell2;

                  if (!total_amounts_by_month[month]) total_amounts_by_month[month] = 0;
                  if (!total_amounts_by_type[id_type]) total_amounts_by_type[id_type] = 0;
                  if (!total_vats_by_month[month]) total_vats_by_month[month] = 0;
                  if (!total_vats_by_type[id_type]) total_vats_by_type[id_type] = 0;

                  total_amounts_by_month[month] += amount_value || 0;
                  total_amounts_by_type[id_type] += amount_value || 0;
                  total_vats_by_month[month] += vat_value || 0;
                  total_vats_by_type[id_type] += vat_value || 0;
                } catch {
                  continue;
                }
              }

              let w: number = this.getTableWidth();
              for (let m = 1; m <= 12; ++m) {
                let amountCell = new ReportCell();
                amountCell.value =
                  total_amounts_by_month[m] > 0
                    ? NumberUtil.formatMoney(total_amounts_by_month[m], symbol, 2, '.')
                    : '';
                amountCell.style = ObjectUtil.merge(ReportCell.moneyCellStyle, ChargesReportModel.totalRowCellStyle);
                let vatCell = new ReportCell();
                vatCell.value =
                  total_vats_by_month[m] > 0 ? NumberUtil.formatMoney(total_vats_by_month[m], symbol, 2, '.') : '';
                vatCell.style = ObjectUtil.merge(ReportCell.moneyCellStyle, ChargesReportModel.totalRowCellStyle);
                let totalCell = new ReportCell();
                totalCell.value =
                  total_amounts_by_month[m] + total_vats_by_month[m] > 0
                    ? NumberUtil.formatMoney(total_amounts_by_month[m] + total_vats_by_month[m], symbol, 2, '.')
                    : '';
                totalCell.style = ObjectUtil.merge(ReportCell.moneyCellStyle, ChargesReportModel.totalRowCellStyle);
                this.rows[m - 1].cells[w - 3] = amountCell;
                this.rows[m - 1].cells[w - 2] = vatCell;
                this.rows[m - 1].cells[w - 1] = totalCell;
              }
              let totalsRow: ReportRow = this.rows[this.rows.length - 1];
              let total_amount_all: number = 0;
              let total_vat_all: number = 0;
              for (let i = 0; i < this.chargeTypes.length; ++i) {
                let id_type: string = this.chargeTypes[i].id;
                totalsRow.cells.push({
                  value:
                    total_amounts_by_type[id_type] > 0
                      ? NumberUtil.formatMoney(total_amounts_by_type[id_type], symbol, 2, '.')
                      : '',
                  style: ObjectUtil.merge(ReportCell.moneyCellStyle, ChargesReportModel.totalRowCellStyle, {
                    'min-width': '100px',
                  }),
                });
                totalsRow.cells.push({
                  value:
                    total_vats_by_type[id_type] > 0
                      ? NumberUtil.formatMoney(total_vats_by_type[id_type], symbol, 2, '.')
                      : '',
                  style: ObjectUtil.merge(ReportCell.moneyCellStyle, ChargesReportModel.totalRowCellStyle, {
                    'min-width': '100px',
                  }),
                });
                total_amount_all += total_amounts_by_type[id_type] || 0;
                total_vat_all += total_vats_by_type[id_type] || 0;
              }

              totalsRow.cells.push({
                value: total_amount_all > 0 ? NumberUtil.formatMoney(total_amount_all, symbol, 2, '.') : '',
                style: ObjectUtil.merge(ReportCell.moneyCellStyle, ChargesReportModel.totalHeaderCellStyle, {
                  'min-width': '100px',
                }),
              });
              totalsRow.cells.push({
                value: total_vat_all > 0 ? NumberUtil.formatMoney(total_vat_all, symbol, 2, '.') : '',
                style: ObjectUtil.merge(ReportCell.moneyCellStyle, ChargesReportModel.totalHeaderCellStyle, {
                  'min-width': '100px',
                }),
              });
              totalsRow.cells.push({
                value:
                  total_amount_all + total_vat_all > 0
                    ? NumberUtil.formatMoney(total_amount_all + total_vat_all, symbol, 2, '.')
                    : '',
                style: ObjectUtil.merge(ReportCell.moneyCellStyle, ChargesReportModel.totalHeaderCellStyle, {
                  'min-width': '100px',
                }),
              });

              this.createEmptyCells();
              resolve(true);
            },
            (err) => {
              console.error(err);
              reject(err);
            }
          );
        },
        (err) => {
          console.error(err);
          reject(err);
        }
      );
    });
  }
}
