import { Component, OnInit, ViewChild } from '@angular/core';

import { AccessViewComponent } from 'src/app/components/views/access-view.component';
import { Accounting } from 'src/app/modules/other-data/classes/Accounting.class';
import { AccountingsService } from 'src/app/services/accountings/accountings.service';
import { ArrayUtil } from 'src/app/modules/utils/classes/ArrayUtil.class';
import { DataGridComponent } from 'src/app/components/data-grid/data-grid.component';
import { DataGridRowAction } from '../../../../components/data-grid/classes/DataGridRowAction.class';
import { DateTimeUtil } from 'src/app/modules/utils/classes/DateTimeUtil.class';
import { Invoice } from '../../classes/Invoice.class';
import { InvoiceViewComponent } from '../invoice-view/invoice-view.component';
import { NotificationsComponent } from 'src/app/components/notifications/notifications.component';
import { Toolbar } from 'src/app/components/toolbar/classes/Toolbar.class';
import { ViewsComponent } from 'src/app/components/views/views.component';

@Component({
  selector: 'app-invoices-list-view',
  templateUrl: './invoices-list-view.component.html',
  styleUrls: ['./invoices-list-view.component.css'],
})
export class InvoicesListViewComponent extends AccessViewComponent {
  public permission: string = 'ecriture_facturation_facturiers';

  public toolbar: Toolbar = {
    class: 'toolbar-big',
    viewTitle: 'Facturier commercial',
    data: this,
    elements: [
      {
        type: 'button',
        text: 'Créer une<br/>facture',
        icon: 'euro-sign',
        click: function (view: InvoicesListViewComponent) {
          view.createInvoice();
        },
        access: this.writeAccess,
      },
      { type: 'separator' },
      {
        type: 'button',
        text: 'Supprimer',
        icon: 'times',
        click: function (view: InvoicesListViewComponent) {
          view.deleteInvoices();
        },
        access: this.writeAccess,
      },
      { type: 'separator-large' },
      {
        name: 'proforma_button',
        type: 'button',
        text: 'Convertir la<br/>facture proforma',
        icon: 'check',
        click: function (view: InvoicesListViewComponent) {
          view.convertProformaInvoice();
        },
        access: this.writeAccess,
      },
      { type: 'spacing' },
    ],
  };

  public paidInvoices: string[] = null;

  public invoices: Invoice[] = [];
  public totalAmountColumn: any = {
    title: 'Montant total\nfacturé H.T.V.A.',
    field: 'converted_totalAmount',
    type: 'number',
    currencyField: 'sell_currency',
    decimalsCount: 2,
    headerBackColor: 'rgb(149, 55, 53)',
    headerTextColor: 'white',
    width: 100,
  };
  public vatAmountColumn: any = {
    title: 'Montant de\nla T.V.A.',
    field: 'converted_vatAmount',
    type: 'number',
    currencyField: 'sell_currency',
    decimalsCount: 2,
    headerBackColor: 'rgb(149, 55, 53)',
    headerTextColor: 'white',
    width: 100,
  };
  public totalWithVatAmountColumn: any = {
    title: 'Montant total\nfacturé T.V.A.C.',
    field: 'converted_totalWithTax',
    type: 'number',
    currencyField: 'sell_currency',
    decimalsCount: 2,
    headerBackColor: 'rgb(149, 55, 53)',
    headerTextColor: 'white',
    width: 100,
  };
  public rawMarginColumn: any = {
    title: 'Montant de\nla marge\nbrute H.T.V.A.',
    field: 'converted_total_rawMargin',
    type: 'number',
    decimalsCount: 2,
    unit: '€',
    headerBackColor: 'rgb(149, 55, 53)',
    headerTextColor: 'white',
    width: 100,
  };
  public rawMarginPercentageColumn: any = {
    title: 'Marge brute',
    field: 'total_rawMarginPerc',
    type: 'number',
    decimalsCount: 2,
    unit: '%',
    headerBackColor: 'rgb(149, 55, 53)',
    headerTextColor: 'white',
    width: 100,
  };
  public netMarginColumn: any = {
    title: 'Montant de\nla marge\nnette H.T.V.A.',
    field: 'converted_total_netMargin',
    type: 'number',
    decimalsCount: 2,
    unit: '€',
    headerBackColor: 'rgb(149, 55, 53)',
    headerTextColor: 'white',
    width: 100,
  };
  public netMarginPercentageColumn: any = {
    title: 'Marge nette',
    field: 'total_netMarginPerc',
    type: 'number',
    decimalsCount: 2,
    unit: '%',
    headerBackColor: 'rgb(149, 55, 53)',
    headerTextColor: 'white',
    width: 100,
  };
  public totalBuyPriceColumn: any = {
    title: 'Montant\nacheté H.T.V.A.',
    field: 'converted_total_buyPrice',
    type: 'number',
    decimalsCount: 2,
    currencyField: 'buy_currency',
    headerBackColor: 'rgb(149, 55, 53)',
    headerTextColor: 'white',
    width: 100,
  };
  public invoicesGridColumns = [
    { title: 'N° de facture', field: 'number', width: 200 },
    { title: 'Date', field: 'date', type: 'date', width: 80 },
    { title: 'Client', field: 'customer.nameWithIdentifier', width: 200 },
    { title: 'Représentant', field: 'merchant.numberAndName', width: 150 },
    { title: 'N° de commande', field: 'order.number', width: 200 },
    { title: 'Fournisseur', field: 'supplier.name', width: 200 },
    { title: 'Désignation', field: 'article.designation', width: 450 },
    { title: 'Quantité', field: 'quantityAndUnit', width: 150 },
    {
      title: 'Prix de vente\nunitaire',
      field: 'converted_sell_price',
      type: 'number',
      currencyField: 'sell_currency',
      decimalsCount: 5,
      width: 130,
    },
    {
      title: 'N° de commande\ndu client',
      field: 'customer_reference',
      width: 150,
    },
    { title: 'N° du bon\nde livraison', field: 'delivery_number', width: 200 },
    {
      title: 'Date de\nlivraison',
      field: 'delivery_date',
      type: 'text',
      width: 80,
    },
    { title: 'Conditions de\npaiement', field: 'order.deadline', width: 150 },
    {
      title: 'N° du dossier\ncommercial',
      field: 'order.sale.number',
      width: 200,
    },
    {
      title: 'Paiement\nfournisseur',
      field: 'has_supplier_payment',
      width: 50,
    },
    this.totalAmountColumn,
    this.vatAmountColumn,
    this.totalWithVatAmountColumn,
    this.rawMarginColumn,
    this.rawMarginPercentageColumn,
    this.netMarginColumn,
    this.netMarginPercentageColumn,
    {
      title: 'Frais de\ngestion',
      field: 'converted_total_internalFees',
      type: 'number',
      currencyField: 'sell_currency',
      decimalsCount: 5,
      width: 100,
    },
    {
      title: 'Frais de\ncompo',
      field: 'converted_total_externalFees',
      type: 'number',
      currencyField: 'sell_currency',
      decimalsCount: 5,
      width: 100,
    },
    this.totalBuyPriceColumn,
  ];

  @ViewChild('grid') grid: DataGridComponent;
  public gridRowActions: DataGridRowAction[] = [
    {
      name: 'Afficher',
      icon: 'search',
      click: (event: any, item: any) => {
        this.invoiceDblClick(event, item);
      },
    },
  ];

  public logisticsView: boolean = false;
  initView(logisticsView: boolean = false) {
    this.logisticsView = logisticsView;
    if (logisticsView) {
      this.toolbar.viewTitle = 'Facturier logistique';
      this.totalAmountColumn.headerBackColor = 'rgb(102, 102, 255)';
      this.rawMarginColumn.headerBackColor = 'rgb(102, 102, 255)';
      this.rawMarginPercentageColumn.headerBackColor = 'rgb(102, 102, 255)';
      this.netMarginColumn.headerBackColor = 'rgb(102, 102, 255)';
      this.netMarginPercentageColumn.headerBackColor = 'rgb(102, 102, 255)';
      this.totalBuyPriceColumn.headerBackColor = 'rgb(102, 102, 255)';
    }
  }

  ngOnInit() {
    Invoice.loadSupplierPayments();
    this.reloadInvoices();
  }

  onAccountingChanged(accounting: Accounting) {
    this.reloadInvoices();
  }

  public reloadInvoices() {
    Invoice.load(
      null,
      ['~number'],
      null,
      false,
      (AccountingsService.currentAccounting
        ? `id_accounting='${AccountingsService.currentAccounting.id}'` + ' AND '
        : '') +
        ('number LIKE "__/__' + (this.logisticsView ? '1' : '0') + '%"')
    ).then(
      (result) => {
        console.log('invoices:', result);
        this.invoices = result;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  createInvoice() {
    let invoice: Invoice = new Invoice();
    invoice.date = DateTimeUtil.toDateString(new Date());
    this.invoices.push(invoice);
    ViewsComponent.openView(InvoiceViewComponent, invoice);
  }

  deleteInvoices() {
    let invoices: Invoice[] = this.grid.selectedItems as Invoice[];
    if (Array.isArray(invoices) && invoices.length > 0) {
      let promises: any[] = [];
      for (let i = 0; i < invoices.length; ++i) promises.push(invoices[i].delete());
      Promise.all(promises).then(
        (result) => {
          NotificationsComponent.push({
            type: 'success',
            title: 'Factures supprimées',
            summary: 'Les factures de prix ont été supprimées avec succès',
          });
          ArrayUtil.removeElements(this.invoices, invoices);
        },
        (err) => {
          NotificationsComponent.push({
            type: 'error',
            title: 'Erreur lors de la suppression',
            summary: "Une erreur s'est produite lors de la suppression",
            content: err,
          });
          console.error(err);
        }
      );
    }
  }

  invoiceDblClick(event, invoice) {
    ViewsComponent.openView(InvoiceViewComponent, invoice, this.logisticsView);
  }

  selectionChange(items: Invoice[]) {
    Toolbar.getToolbarItem(this.toolbar, 'proforma_button').disabled = !(items.length === 1 && items[0].proforma);
  }

  private convertProformaInvoice() {
    let items: Invoice[] = this.grid.selectedItems;
    if (!(items.length && items[0].proforma))
      NotificationsComponent.push({
        title: 'Sélection invalide',
        summary: 'Veuillez sélectionner une seule facture proforma',
        type: 'warning',
      });
    else {
      let invoiceCopy: Invoice = items[0].clone(false);
      invoiceCopy.proforma = 0;
      invoiceCopy.date = DateTimeUtil.toDateString(new Date());
      invoiceCopy.getNextNumber().then(
        (result) => {
          setTimeout(() => {
            invoiceCopy.number = invoiceCopy.generateNumber(result.details);
            invoiceCopy.save3(false).then(
              (result) => {
                let promises: Promise<any>[] = [];
                invoiceCopy.order.invoices.push(invoiceCopy);
                promises.push(invoiceCopy.order.save3(false));
                if (invoiceCopy.move) {
                  invoiceCopy.move.invoice = invoiceCopy;
                  promises.push(invoiceCopy.move.save3(false));
                }
                Promise.all(promises).then(
                  (result) => {
                    NotificationsComponent.push({
                      title: 'Facture convertie',
                      summary: 'La facture proforma a été convertie avec succès en facture normale',
                      type: 'success',
                    });
                  },
                  (err) => {
                    NotificationsComponent.push({
                      title: 'Erreur',
                      summary:
                        "Une erreur s'est produite lors de la liaison de la facture avec la commande et/ou l'appel de stock",
                      type: 'error',
                    });
                    console.error(err);
                  }
                );
                this.reloadInvoices();
              },
              (err) => {
                NotificationsComponent.push({
                  title: 'Erreur',
                  summary: "Une erreur s'est produite lors de la duplication de la facture",
                  type: 'error',
                });
                console.error(err);
              }
            );
          }, 0);
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }
}
