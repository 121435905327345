import { ClassManager } from '../../classes/objects/ClassManager.class';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../../components/components.module';
import { FormsModule } from '@angular/forms';
import { HomepageViewComponent } from '../homepage/views/homepage-view/homepage-view.component';
import { InteropViewComponent } from '../interop/interop-view.component';
import { InvoiceViewComponent } from '../invoicing/views/invoice-view/invoice-view.component';
import { InvoicesListViewComponent } from '../invoicing/views/invoices-list-view/invoices-list-view.component';
import { ModuleBase } from '../module-base.class';
import { NgModule } from '@angular/core';
import { ParentStock } from './classes/ParentStock.class';
import { ParentStockViewComponent } from './views/parent-stock-view/parent-stock-view.component';
import { ParentStocksListViewComponent } from './views/parent-stocks-list-view/parent-stocks-list-view.component';
import { PrintingModule } from '../printing/printing.module';
import { ReimbursementViewComponent } from '../invoicing/views/reimbursement-view/reimbursement-view.component';
import { ReimbursementsListViewComponent } from '../invoicing/views/reimbursements-list-view/reimbursements-list-view.component';
import { SelectStockDialogComponent } from './views/select-stock-dialog/select-stock-dialog.component';
import { Stock } from './classes/Stock.class';
import { StockAdjustment } from './classes/StockAdjustment.class';
import { StockAdjustmentViewComponent } from './views/stock-adjustment-view/stock-adjustment-view.component';
import { StockMove } from './classes/StockMove.class';
import { StockMoveViewComponent } from './views/stock-move-view/stock-move-view.component';
import { StockMovesFollowupViewComponent } from './views/stock-moves-followup-view/stock-moves-followup-view.component';
import { StockMovesListViewComponent } from './views/stock-moves-list-view/stock-moves-list-view.component';
import { StockViewComponent } from './views/stock-view/stock-view.component';
import { StocksAdjustmentsListViewComponent } from './views/stocks-adjustments-list-view/stocks-adjustments-list-view.component';
import { StocksListViewComponent } from './views/stocks-list-view/stocks-list-view.component';
import { config } from '../../classes/config';

@NgModule({
  imports: [CommonModule, FormsModule, ComponentsModule, PrintingModule],
  declarations: [
    StocksListViewComponent,
    StockViewComponent,
    StockMovesListViewComponent,
    StockMoveViewComponent,
    ParentStockViewComponent,
    ParentStocksListViewComponent,
    StockMovesFollowupViewComponent,
    SelectStockDialogComponent,
    StockAdjustmentViewComponent,
    StocksAdjustmentsListViewComponent,
  ],
  exports: [
    StocksListViewComponent,
    StockViewComponent,
    StockMovesListViewComponent,
    StockMoveViewComponent,
    ParentStockViewComponent,
    ParentStocksListViewComponent,
    StockMovesFollowupViewComponent,
    SelectStockDialogComponent,
    StockAdjustmentViewComponent,
    StocksAdjustmentsListViewComponent,
  ],
  entryComponents: [
    StocksListViewComponent,
    StockViewComponent,
    StockMovesListViewComponent,
    StockMoveViewComponent,
    ParentStockViewComponent,
    ParentStocksListViewComponent,
    StockMovesFollowupViewComponent,
    SelectStockDialogComponent,
    StockAdjustmentViewComponent,
    StocksAdjustmentsListViewComponent,
  ],
})
export class StocksModule extends ModuleBase {
  public static moduleName = 'suppliers';

  public ngOnInit() {
    ClassManager.registerClass('Stock', Stock);
    ClassManager.registerClass('StockAdjustment', StockAdjustment);
    ClassManager.registerClass('ParentStock', ParentStock);
    ClassManager.registerClass('StockMove', StockMove);

    HomepageViewComponent.registerGroups({
      stocks: {
        name: 'Stocks',
        backColor: 'rgb(0, 51, 205)',
        textColor: 'white',
        permission: 'home_groupe_stocks',
        children: [
          {
            name: 'Liste des articles de stock',
            permission: 'home_bouton_stocks_liste',
            component: StocksListViewComponent,
            visible: !this.isProd || !config.interop.enabled || !config.interop.modules.stocks.list,
          },
          {
            name: 'Liste des articles de stock',
            new: true,
            permission: 'home_bouton_ventes_incidents',
            href: '/stocks',
            visible: !this.isProd || (config.interop.enabled && config.interop.modules.stocks.list),
          },

          {
            name: 'Ajustements de stock',
            permission: 'home_bouton_stocks_ajustements',
            component: StocksAdjustmentsListViewComponent,
            visible: !this.isProd || !config.interop.enabled || !config.interop.modules.stockAdjustments.list,
          },
          {
            name: 'Ajustements de stock',
            new: true,
            permission: 'home_bouton_stocks_ajustements',
            href: '/stocks_adjustments',
            visible: !this.isProd || (config.interop.enabled && config.interop.modules.stockAdjustments.list),
          },

          {
            name: "Choix de l'article à traiter",
            permission: 'home_bouton_stocks_choix',
            backColor: 'rgb(51,102,255)',
            textColor: 'white',
            component: StockMoveViewComponent,
            visible: !this.isProd || !config.interop.enabled || !config.interop.modules.stockCalls.details,
          },
          {
            name: "Choix de l'article à traiter",
            new: true,
            permission: 'home_bouton_stocks_choix',
            backColor: 'rgb(51,102,255)',
            textColor: 'white',
            href: '/stock_move/new',
            visible: !this.isProd || (config.interop.enabled && config.interop.modules.stockCalls.details),
          },

          {
            name: 'Appels sur stock',
            permission: 'home_bouton_stocks_appels',
            backColor: 'rgb(149,179,215)',
            textColor: 'black',
            component: StockMovesListViewComponent,
            visible: !this.isProd || !config.interop.enabled || !config.interop.modules.stockCalls.list,
          },
          {
            name: 'Appels sur stock',
            new: true,
            permission: 'home_bouton_stocks_appels',
            backColor: 'rgb(149,179,215)',
            textColor: 'black',
            href: '/stock_moves',
            visible: !this.isProd || (config.interop.enabled && config.interop.modules.stockCalls.list),
          },

          {
            name: 'Suivi des appels',
            permission: 'home_bouton_stocks_suivi',
            backColor: 'rgb(149,179,215)',
            textColor: 'black',
            component: StockMovesFollowupViewComponent,
            visible: !this.isProd || !config.interop.enabled || !config.interop.modules.stockFollowUp,
          },
          {
            name: 'Suivi des appels',
            new: true,
            permission: 'home_bouton_stocks_suivi',
            backColor: 'rgb(149,179,215)',
            textColor: 'black',
            href: '/stock_moves_followup',
            visible: !this.isProd || (config.interop.enabled && config.interop.modules.stockFollowUp),
          },
        ],
      },
    });

    return super.ngOnInit();
  }
}
