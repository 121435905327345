import { Accounting } from '../../other-data/classes/Accounting.class';
import { Article } from '../../articles/classes/Article.class';
import { Customer } from '../../customers/classes/Customer.class';
import { DateTimeUtil } from '../../utils/classes/DateTimeUtil.class';
import { Invoice } from '../../invoicing/classes/Invoice.class';
import { NumberUtil } from '../../utils/classes/NumberUtil.class';
import { ObjectDefinition } from '../../../classes/objects/ObjectDefinition.class';
import { ObjectModel2 } from '../../../classes/objects/ObjectModel2.class';
import { ObjectModel3 } from 'src/app/classes/objects/ObjectModel3.class';
import { Order } from '../../orders/classes/Order.class';
import { Stock } from './Stock.class';
import { StockMove } from './StockMove.class';
import { Supplier } from '../../suppliers/classes/Supplier.class';

export class ParentStock extends ObjectModel3 {
  public accounting: Accounting = null;
  public creation_date: string = null;
  public supplier: Supplier = null;

  public unit: string = null;
  public buy_price: number = null;

  public stocks: Stock[] = [];

  public get quantityAndUnit() {
    let arr: string[] = [];
    let quantity: number = this.totalQuantity;
    if (quantity != null) arr.push(NumberUtil.formatNumber(quantity, null, '.'));
    if (this.unit != null) arr.push(this.unit);
    return arr.join(' ');
  }
  public get totalQuantity() {
    let total: number = 0;
    if (this.stocks) {
      for (let i = 0; i < this.stocks.length; ++i) {
        if (this.stocks[i].quantity) total += this.stocks[i].quantity;
      }
    }
    return total;
  }
  public get remainingQuantity() {
    let total: number = 0;
    if (this.stocks) {
      for (let i = 0; i < this.stocks.length; ++i) {
        if (this.stocks[i].quantity) total += this.stocks[i].availableStock;
      }
    }
    return total;
  }
  public get moves() {
    let moves: StockMove[] = [];
    if (this.stocks) {
      for (let i = 0; i < this.stocks.length; ++i) {
        if (this.stocks[i].moves) moves = moves.concat(this.stocks[i].moves);
      }
    }
    return moves;
  }
  public get article() {
    if (!this.stocks || this.stocks.length == 0) return null;
    return this.stocks[0].article;
  }

  constructor() {
    super(ParentStock);
  }

  /* ObjectModel FUNCTIONS */

  public static definition: ObjectDefinition = {
    trashDelete: true,
    database: {
      table: 'parent_stocks',
      id: 'id',
    },
    values: {
      creation_date: { type: 'string' },
      unit: { type: 'string' },
      buy_price: { type: 'number' },
    },
    children: {
      accounting: { type: 'Accounting', clone: false, save: false, delete: false },
      supplier: { type: 'Supplier', clone: false, save: false, delete: false },
    },
    links: {
      stocks: {
        type: 'Stock',
        table: 'parent_stocks_stocks',
        id_self: 'id_parent_stock',
        id_link: 'id_stock',
        clone: false,
        save: true,
        delete: false,
      },
    },
  };
}
