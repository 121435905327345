import { ObjectDefinition } from '../../../classes/objects/ObjectDefinition.class';
import { ObjectModel3 } from 'src/app/classes/objects/ObjectModel3.class';
import { User } from 'src/app/classes/credentials/User.class';

export class ItRequest extends ObjectModel3 {
  public user: User = null;
  public date: string = null;

  public subject: string = null;
  public description: string = null;

  public estimation: number = null;
  public response_date: string = null;
  public response: string = null;
  public validated: number = null;

  constructor() {
    super(ItRequest);
  }

  /* ObjectModel FUNCTIONS */

  // public static instances: { [id: number]: Address; } = {};

  public static definition: ObjectDefinition = {
    trashDelete: true,
    database: {
      table: 'it_requests',
      id: 'id',
    },
    values: {
      date: { type: 'string' },
      subject: { type: 'string' },
      description: { type: 'string' },
      estimation: { type: 'number' },
      response_date: { type: 'string' },
      response: { type: 'string' },
      validation_date: { type: 'string' },
      validated: { type: 'number' },
    },
    children: {
      user: { type: 'User', clone: false, save: false, delete: false },
    },
    links: {},
  };
}
