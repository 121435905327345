import { Merchant } from '../../other-data/classes/Merchant.class';
import { ObjectDefinition } from '../../../classes/objects/ObjectDefinition.class';
import { ObjectModel3 } from 'src/app/classes/objects/ObjectModel3.class';

export class Wage extends ObjectModel3 {
  public merchant: Merchant = null;
  public year: number = null;
  public amount1: number = null;
  public amount2: number = null;
  public amount3: number = null;
  public amount4: number = null;
  public amount5: number = null;
  public amount6: number = null;
  public amount7: number = null;
  public amount8: number = null;
  public amount9: number = null;
  public amount10: number = null;
  public amount11: number = null;
  public amount12: number = null;

  public get total() {
    return (
      this.amount1 +
      this.amount2 +
      this.amount3 +
      this.amount4 +
      this.amount5 +
      this.amount6 +
      this.amount7 +
      this.amount8 +
      this.amount9 +
      this.amount10 +
      this.amount11 +
      this.amount12
    );
  }

  public constructor() {
    super(Wage);
  }

  /* ObjectModel FUNCTIONS */

  public static definition: ObjectDefinition = {
    database: {
      table: 'wages',
      id: 'id',
    },
    values: {
      year: { type: 'number' },
      amount1: { type: 'number' },
      amount2: { type: 'number' },
      amount3: { type: 'number' },
      amount4: { type: 'number' },
      amount5: { type: 'number' },
      amount6: { type: 'number' },
      amount7: { type: 'number' },
      amount8: { type: 'number' },
      amount9: { type: 'number' },
      amount10: { type: 'number' },
      amount11: { type: 'number' },
      amount12: { type: 'number' },
    },
    children: {
      merchant: { type: 'Merchant', clone: false, save: false, delete: false },
    },
    links: {},
  };
}
