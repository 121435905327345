import { Component, Input, OnInit, ViewChild, forwardRef } from '@angular/core';

import { AccessViewComponent } from '../../../../components/views/access-view.component';
import { ArrayUtil } from '../../../utils/classes/ArrayUtil.class';
import { Article } from '../../classes/Article.class';
import { ArticleBase } from '../../classes/ArticleBase.class';
import { Customer } from '../../../customers/classes/Customer.class';
import { DataTreeComponent } from '../../../../components/data-tree/data-tree.component';
import { Device } from '../../classes/Device.class';
import { DialogButton } from '../../../../components/dialogs/classes/DialogButton.class';
import { DialogsComponent } from '../../../../components/dialogs/dialogs.component';
import { HttpClient } from '@angular/common/http';
import { InfoBlock } from '../../../../components/info-block/classes/InfoBlock.class';
import { InfoBlockComponent } from '../../../../components/info-block/info-block.component';
import { InfoBlockField } from '../../../../components/info-block/classes/InfoBlockField.class';
import { Nomenclature } from '../../classes/Nomenclature.class';
import { ObjectModel2 } from '../../../../classes/objects/ObjectModel2.class';
import { ObjectModel3 } from 'src/app/classes/objects/ObjectModel3.class';
import { RollingDirection } from 'src/app/modules/other-data/classes/RollingDirection.class';
import { Supplier } from '../../../suppliers/classes/Supplier.class';
import { Toolbar } from '../../../../components/toolbar/classes/Toolbar.class';
import { ViewsComponent } from '../../../../components/views/views.component';

@Component({
  selector: 'app-nomenclatures-list-view',
  templateUrl: './nomenclatures-list-view.component.html',
  styleUrls: ['./nomenclatures-list-view.component.css'],
})
export class NomenclaturesListViewcomponent extends AccessViewComponent {
  public permission: string = 'ecriture_basededonnees_nomenclatures';

  public toolbar: Toolbar = {
    class: 'toolbar-big',
    viewTitle: 'Articles',
    data: this,
    onPrevPage: () => {
      if (this.itemsChanged()) {
        DialogsComponent.display({
          icon: 'question',
          title: 'Enregistrer les modifications',
          message: 'Des modifications ont été effectuées.<br/>Voulez-vous enregistrer les modifications ?',
          buttons: DialogButton.yesNoCancelButtons,
        }).then((result: any) => {
          if (result === DialogButton.RESULT_YES) {
            this.save().then((result2: any) => {
              ViewsComponent.closeView();
            });
          } else if (result !== DialogButton.RESULT_CANCEL) ViewsComponent.closeView();
        });
        return false;
      }
      return true;
    },
    elements: [
      { type: 'separator' },
      {
        type: 'button',
        text: 'Enregistrer',
        icon: 'save',
        click: function (view: NomenclaturesListViewcomponent) {
          // if (view.selectedCustomer) view.selectedCustomer.save2();
          view.save();
        },
        access: this.writeAccess,
      },
      { type: 'separator-large' },
      {
        name: 'createNomenclature',
        type: 'button',
        text: 'Créer une<br/>nomenclature',
        icon: 'plus',
        click: function (view: NomenclaturesListViewcomponent) {
          view.createNomenclature();
        },
        access: this.writeAccess,
      },
      { type: 'separator' },
      {
        name: 'deleteNomenclature',
        type: 'button',
        text: 'Supprimer la<br/>nomenclature',
        icon: 'times',
        click: function (view: NomenclaturesListViewcomponent) {
          view.deleteNomenclature();
        },
        disabled: false,
        access: this.writeAccess,
      },
      { type: 'spacing' },
    ],
  };

  public customers: Customer[] = [];
  public nomenclaturesData: any = { items: [] };

  public fieldChanged(event: any) {
    this.objectCopy.changed = true;
  }

  public rollingField: InfoBlockField = {
    title: "Sens d'enroulement",
    field: 'field9',
    type: 'combo-box',
    listItems: { items: [] },
    listField: 'name',
  };
  public articleBlock: InfoBlock = {
    title: 'Informations article consommable',
    backColor: 'rgb(0,156,218)',
    textColor: 'white',
    fields: [
      { title: 'Aperçu', field: 'field15', type: 'image' }, //picture
      { title: 'Désignation', field: 'designation' },
      {
        title: 'Nomenclature',
        field: 'nomenclature',
        type: 'foreign-list',
        //change: (item, column) => { this.priceSupplierChange(item); },
        multiSelect: false,
        listItems: this.nomenclaturesData,
        listField: 'name',
        nullText: '(Aucun)',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          //   this.updateSuppliersList();
        },
      },
      { title: 'Format', field: 'field1' }, //format
      { title: 'Matière', field: 'field2' }, //material
      {
        title: 'Matière (clients)',
        field: 'field3',
        type: 'nullable-text',
        nullText: '(Afficher la matière comme pour les fournisseurs)',
      }, //material2
      { title: 'Impression', field: 'field4' }, //printing
      { title: 'ø du mandrin / ø du rouleau', field: 'field5' }, //diameter
      { title: 'Présentation', field: 'field6' }, //presentation
      { title: "Nb. d'éti. / feuil. / bob. / parav.", field: 'field7' }, //labels_count
      { title: 'Esp. vertical / Esp. horizontal', field: 'field8' }, //space
      // { title: 'Sens d\'enroulement', field: 'field9' }, //rolling_direction
      this.rollingField,
      { title: 'Laize', field: 'field10' }, //width
      { title: 'Pinfeed', field: 'field11' }, //pinfeed
      { title: 'Perforations ou façonnage', field: 'field12' }, //perforations
      { title: 'Autre', field: 'field13' }, //other
      { title: 'Conditionnement', field: 'field14' }, //packaging
    ],
  };
  public deviceBlock: InfoBlock = {
    title: 'Informations article matériel',
    backColor: 'rgb(55, 136, 105)',
    textColor: 'white',
    fields: [
      { title: 'Aperçu', field: 'field15', type: 'image' }, //picture
      { title: 'Désignation', field: 'designation' }, //name
      {
        title: 'Nomenclature',
        field: 'nomenclature',
        type: 'foreign-list', //parent
        multiSelect: false,
        listItems: this.nomenclaturesData,
        listField: 'name',
        nullText: '(Aucun)',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          //   this.updateSuppliersList();
        },
      },
      { title: 'Marque et modèle', field: 'field1' }, //model
      { title: 'Technologie', field: 'field2' }, //technology
      { title: 'Résolution', field: 'field3' }, //resoution
      { title: 'Vitesse maximum', field: 'field4' }, //max_speed
      { title: 'Largeur du média', field: 'field5' }, //media_width
      { title: 'Epaisseur du média', field: 'field6' }, //media_thickness
      { title: 'Mémoire', field: 'field7' }, //memory
      { title: 'Couteau', field: 'field8' }, //knife
      { title: 'Interface (standard)', field: 'field9' }, //interface
      { title: 'Programmation', field: 'field10' }, //programming
      { title: 'Options', field: 'field11' }, //options
      { title: 'Construction', field: 'field12' }, //building
      { title: 'Garantie', field: 'field13' }, //warranty
      { title: 'Autre', field: 'field14' }, //other
    ],
  };
  public nomenclatureBlock: InfoBlock = {
    title: '',
    backColor: 'rgb(0,156,218)',
    textColor: 'white',
    fields: [
      { title: 'Nom', field: 'name' },
      { title: 'Visible dans les\nobjectifs représentants\n(budgets)', field: 'budget', type: 'checkbox' },
      { title: "Verrouillée (impossible d'ajouter\ndes articles)", field: 'locked', type: 'checkbox' },
    ],
  };

  @ViewChild('dataTree') dataTree: DataTreeComponent;

  public articles: Article[] = [];
  public devices: Device[] = [];
  public nomenclatures: Nomenclature[] = [];
  public suppliers: Supplier[] = [];
  public treeItems: any[] = [];
  @Input() rootId: string = null;
  public selectedObjects: any[] = [];
  public deletedObjects: ObjectModel3[] = [];

  public objectCopy: Nomenclature = null;
  @ViewChild('articleBlockComponent') articleBlockComponent: InfoBlockComponent;
  @ViewChild('deviceBlockComponent') deviceBlockComponent: InfoBlockComponent;

  public displayType: string = 'hierarchy';

  get items() {
    if (this.displayType == 'hierarchy') return this.treeItems;
    else return this.articlesData.items;
  }

  public articlesData: any = { items: [] };
  public addArticles(articles: any[]) {
    this.articlesData.items = this.articlesData.items.concat(articles);
    this.articlesData.items.sort((a: any, b: any) => {
      return a.designation < b.designation ? -1 : a.designation == b.designation ? 0 : 1;
    });
  }

  ngOnInit() {
    let self = this;
    this.updateView();
    Supplier.load(null, ['name']).then(
      (result) => {
        this.suppliers = result;
      },
      (err) => {
        console.error(err);
      }
    );
    Nomenclature.load(null, ['name']).then(
      (result: Nomenclature[]) => {
        self.nomenclatures = result;
        self.nomenclaturesData.items = result;
        self.treeItems = self.nomenclatures.filter((value, index, arr) => {
          return (self.rootId == null && value.parent == null) || (value.parent && value.parent.id == self.rootId);
        });
      },
      function (err) {
        console.error('loading nomenclatures failed:', err);
      }
    );
    RollingDirection.load(null, ['name']).then(
      (result) => {
        this.rollingField.listItems.items = result;
      },
      (err) => {
        console.error('rollings loading failed', err);
      }
    );
  }

  updateView() {
    this.selectedObjects = [];
  }

  selectionChange(event) {
    this.objectCopy = event[0];
  }

  createNomenclature() {
    let sel = this.selectedObjects;
    let item: any = new Nomenclature();
    item.name = 'Nouvelle nomenclature';
    item.changed = true;
    this.nomenclatures.push(item);
    console.log('current selection:', sel);
    if (sel && sel.length === 1 && sel[0] instanceof Nomenclature) {
      if (!sel[0].children) sel[0].children = [];
      sel[0].children.push(item);
      item.parent = sel[0];
      //setTimeout(() => { this.dataTree.getItemComponent(item).rename(item); }, 0);
    } else {
      this.treeItems.push(item);
      setTimeout(() => {
        this.dataTree.dataTree.rename(item);
      }, 0);
    }
    this.dataTree.expandTo(item);
  }

  deleteNomenclature(item: any = null) {
    if (!item) {
      let sel = this.selectedObjects;
      if (sel && sel.length === 1 && sel[0] instanceof Nomenclature) this.deleteNomenclature(sel[0]);
    } else {
      if (Array.isArray(item.children)) {
        for (let i = item.children.length - 1; i >= 0; --i) {
          this.deleteNomenclature(item.children[i]);
        }
      }
      if (Array.isArray(item.files)) {
        for (let i = item.files.length - 1; i >= 0; --i) {
          this.deleteSelection(item.files[i]);
        }
      }
      let parent = item.parent;
      if (parent && Array.isArray(parent.children) && parent.children.includes(item))
        ArrayUtil.removeElements(parent.children, [item]);
      else if (!parent) ArrayUtil.removeElements(this.treeItems, [item]);
      console.log('deleting directory', item);
      this.deletedObjects.push(item);
    }
  }

  createItem(type: any) {
    let item: any = new type();
    item.changed = true;
    this.articlesData.items.push(item);
    this.dataTree.expandTo(item);
  }

  duplicateSelection(item: any = null) {
    if (!item) {
      let sel = this.selectedObjects;
      if (sel && sel.length === 1 && !(sel[0] instanceof Nomenclature)) item = sel[0];
    }
    if (item) {
      let new_item = item.clone(false);
      if (new_item.name) new_item.name += ' (2)';
      new_item.changed = true;
      //if (!Array.isArray(parent.files)) parent.files = [];
      this.articlesData.items.push(new_item);
      this.dataTree.expandTo(new_item, true);
    }
  }

  deleteSelection(item: any = null) {
    if (!item) {
      let sel = this.selectedObjects;
      if (sel && sel.length === 1 && !(sel[0] instanceof Nomenclature)) item = sel[0];
    }
    if (item) {
      let parent = item.parent;
      if (parent && Array.isArray(parent.files) && parent.files.includes(item))
        ArrayUtil.removeElements(parent.files, [item]);
      console.log('deleting item', item);
      this.deletedObjects.push(item);
      if (!(item instanceof Nomenclature)) ArrayUtil.removeElements(this.articlesData.items, [item]);
    }
  }

  save() {
    return Promise.all([this.saveNomenclatures(), this.saveArticles()]);
  }
  saveNomenclatures(items: any[] = null) {
    return new Promise<any>((resolve, reject) => {
      if (!items) {
        for (let i = 0; i < this.deletedObjects.length; ++i) this.deletedObjects[i].moveToTrash();
        items = this.treeItems;
      }
      let promises: Promise<any>[] = [];
      for (let i = 0; i < items.length; ++i) {
        if (items[i].changed === true) promises.push(items[i].save2());
        if (Array.isArray(items[i].children)) promises.push(this.saveNomenclatures(items[i].children));
        //if (Array.isArray(items[i].files)) promises.push(this.saveNomenclatures(items[i].files));
      }
      Promise.all(promises).then(
        (result) => {
          resolve(result);
        },
        (err) => {
          console.error(err);
          reject(err);
        }
      );
    });
  }
  saveArticles() {
    return new Promise<any>((resolve, reject) => {
      let promises: Promise<any>[] = [];
      for (let i = 0; i < this.articlesData.items.length; ++i) {
        let article: ObjectModel3 = this.articlesData.items[i];
        if (article.changed === true) promises.push(article.save2());
      }
      Promise.all(promises).then(
        (result) => {
          resolve(result);
        },
        (err) => {
          console.error(err);
        }
      );
    });
  }

  public itemsChanged(items: any[] = null): boolean {
    if (!items) {
      for (let i = 0; i < this.deletedObjects.length; ++i) this.deletedObjects[i].delete();
      items = this.treeItems;
    }
    for (let i = 0; i < items.length; ++i) {
      if (items[i].changed === true) return true;
      if (Array.isArray(items[i].children)) {
        let result: boolean = this.itemsChanged(items[i].children);
        if (result === true) return true;
      }
      if (Array.isArray(items[i].files)) {
        let result: boolean = this.itemsChanged(items[i].files);
        if (result === true) return true;
      }
    }
  }

  itemRenamed(item: ObjectModel2) {
    if (item) item.changed = true;
    console.log('item renamed:', item);
  }

  clearTreeSelection() {
    this.selectedObjects = [];
    this.dataTree.clearSelection();
  }
}
