<div class="wrapper">
  <ul class="groups">
    <li *ngFor="let group of filteredHomepageGroups" class="group">
      <span
        class="group-name"
        [ngStyle]="{
          'background-color': group.backColor,
          'font-size': group.fontSize,
          color: group.textColor,
          'line-height': group.lineHeight,
          height: group.height
        }"
        >{{ group.name }}</span
      >
      <ul class="group-children">
        <li
          *ngFor="let child of group.children"
          class="group-child"
          (click)="openView(child)"
          [ngStyle]="{
            'background-color': child.backColor || group.backColor,
            color: child.textColor || group.textColor
          }"
        >
          <span class="group-child-link">{{ child.name }}</span>
          <span class="new" *ngIf="child.new">NEW</span>
        </li>
      </ul>
    </li>
  </ul>
</div>
