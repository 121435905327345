import { ClassManager } from 'src/app/classes/objects/ClassManager.class';
import { LoadSequence } from 'src/app/classes/objects/LoadSequence.class';
import { Nomenclature } from './Nomenclature.class';
import { ObjectDefinition } from '../../../classes/objects/ObjectDefinition.class';
import { ObjectModel2 } from '../../../classes/objects/ObjectModel2.class';
import { ObjectModel3 } from 'src/app/classes/objects/ObjectModel3.class';

export class ArticleBase extends ObjectModel3 {
  public static TYPE_ARTICLE: number = 0;
  public static TYPE_DEVICE: number = 1;
  public static TYPE_SERVICE: number = 2;

  public type: number = null;
  public nomenclature: Nomenclature = null;
  public designation: string = null;
  public field1: string = null;
  public field2: string = null;
  public field3: string = null;
  public field4: string = null;
  public field5: string = null;
  public field6: string = null;
  public field7: string = null;
  public field8: string = null;
  public field9: string = null;
  public field9_img: number = 0;
  public field10: string = null;
  public field11: string = null;
  public field12: string = null;
  public field13: string = null;
  public field14: string = null;
  public field15: string = null;

  public internal_notes: string = null;

  public id_old: string = null;

  public get article() {
    return this.type == ArticleBase.TYPE_ARTICLE ? ClassManager.cast(this, ClassManager.getClass('Article')) : null;
  }
  public get device() {
    return this.type == ArticleBase.TYPE_DEVICE ? ClassManager.cast(this, ClassManager.getClass('Device')) : null;
  }
  public get suppliers() {
    return this.nomenclature != null ? this.nomenclature.suppliers : null;
  }
  public get nomenclatureName() {
    return this.nomenclature && this.nomenclature.name;
  }

  constructor() {
    super(ArticleBase);
  }

  /* ObjectModel FUNCTIONS */

  public static definition: ObjectDefinition = {
    trashDelete: true,
    database: {
      table: 'articles',
      id: 'id',
    },
    values: {
      type: { type: 'number' },
      designation: { type: 'string' },
      field1: { type: 'string' },
      field2: { type: 'string' },
      field3: { type: 'string' },
      field4: { type: 'string' },
      field5: { type: 'string' },
      field6: { type: 'string' },
      field7: { type: 'string' },
      field8: { type: 'string' },
      field9: { type: 'string' },
      field9_img: { type: 'number' },
      field10: { type: 'string' },
      field11: { type: 'string' },
      field12: { type: 'string' },
      field13: { type: 'string' },
      field14: { type: 'string' },
      field15: { type: 'string' },
      internal_notes: { type: 'string' },
      id_old: { type: 'string' },
    },
    children: {
      nomenclature: { type: 'Nomenclature', clone: false, save: false, delete: false },
    },
    links: {},
  };
}
