import { DateTimeUtil } from '../../utils/classes/DateTimeUtil.class';
import { ObjectDefinition } from '../../../classes/objects/ObjectDefinition.class';
import { ObjectModel2 } from '../../../classes/objects/ObjectModel2.class';
import { ObjectModel3 } from 'src/app/classes/objects/ObjectModel3.class';

export class Currency extends ObjectModel3 {
  public symbol: string = null;
  public name: string = null;
  public description: string = null;
  public rate: number = 0;
  public singular_units: string = null;
  public plural_units: string = null;
  public singular_cents: string = null;
  public plural_cents: string = null;
  public date: string = null;

  public get fullDescription() {
    return (
      this.name +
      ' - ' +
      this.description +
      ' (=' +
      this.rate +
      ' € le ' +
      DateTimeUtil.format(new Date(this.date), 'Y-m-d') +
      ')'
    );
  }

  private getUnitFromArray(units: string, lang: string) {
    let arr: string[] = units.split(',');
    for (let i = 0; i < arr.length; ++i) {
      if (arr[i].startsWith(lang + ':')) return arr[i].substring(lang.length + 1);
    }
    return null;
  }

  public getSingularUnit(lang: string) {
    return this.getUnitFromArray(this.singular_units, lang);
  }
  public getPluralUnit(lang: string) {
    return this.getUnitFromArray(this.plural_units, lang);
  }
  public getSingularCent(lang: string) {
    return this.getUnitFromArray(this.singular_cents, lang);
  }
  public getPluralCent(lang: string) {
    return this.getUnitFromArray(this.plural_cents, lang);
  }

  public constructor() {
    super(Currency);
  }

  /* ObjectModel FUNCTIONS */

  public static definition: ObjectDefinition = {
    database: {
      table: 'currencies',
      id: 'id',
    },
    values: {
      symbol: { type: 'string' },
      name: { type: 'string' },
      description: { type: 'string' },
      rate: { type: 'string' },
      singular_units: { type: 'string' },
      plural_units: { type: 'string' },
      singular_cents: { type: 'string' },
      plural_cents: { type: 'string' },
      date: { type: 'string' },
    },
    children: {},
    links: {},
  };
}
