import { AddressesModule } from '../addresses/addresses.module';
import { ClassManager } from '../../classes/objects/ClassManager.class';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../../components/components.module';
import { Customer } from './classes/Customer.class';
import { CustomerCategory } from './classes/CustomerCategory.class';
import { CustomerViewComponent } from './views/customer-view/customer-view.component';
import { CustomersListViewComponent } from './views/customers-list-view/customers-list-view.component';
import { FormsModule } from '@angular/forms';
import { HomepageModule } from '../homepage/homepage.module';
import { HomepageViewComponent } from '../homepage/views/homepage-view/homepage-view.component';
import { ModuleBase } from '../module-base.class';
import { NgModule } from '@angular/core';
import { ResetPasswordModalComponent } from './views/reset-password-modal/reset-password-modal.component';
import { config } from '../../classes/config';

@NgModule({
  imports: [CommonModule, FormsModule, ComponentsModule, HomepageModule, AddressesModule],
  declarations: [CustomersListViewComponent, CustomerViewComponent, ResetPasswordModalComponent],
  exports: [CustomersListViewComponent, CustomerViewComponent, ResetPasswordModalComponent],
  entryComponents: [CustomersListViewComponent, CustomerViewComponent, ResetPasswordModalComponent],
})
export class CustomersModule extends ModuleBase {
  public static moduleName = 'customers';

  public ngOnInit() {
    ClassManager.registerClass('Customer', Customer);
    ClassManager.registerClass('CustomerCategory', CustomerCategory);

    HomepageViewComponent.registerGroups({
      database: {
        name: 'Base de données',
        permission: 'home_groupe_basededonnees',
        children: [
          {
            name: 'Clients',
            permission: 'home_bouton_basededonnees_clients',
            component: CustomersListViewComponent,
            visible: !this.isProd || !config.interop.enabled || !config.interop.modules.customers.list,
          },
          {
            name: 'Clients',
            new: true,
            permission: 'home_bouton_basededonnees_clients',
            href: '/customers',
            visible: !this.isProd || (config.interop.enabled && config.interop.modules.customers.list),
          },
        ],
      },
    });

    return super.ngOnInit();
  }
}
