import { DeviceInstance } from './DeviceInstance.class';
import { ObjectDefinition } from '../../../classes/objects/ObjectDefinition.class';
import { ObjectModel3 } from 'src/app/classes/objects/ObjectModel3.class';

export class RMA extends ObjectModel3 {
  public device: DeviceInstance = null;

  public date: string = null;
  public number: string = null;
  public request_date: string = null;

  public deposit_date: string = null;
  public shipping_date: string = null;
  public delivery_date: string = null;

  public problem: string = null;
  public supplier_reference: string = null;
  public price: number = null;
  public remarks: string = null;
  public archived: number = 0;

  public get brandAndModel() {
    return this.device ? this.device.brandAndModel : null;
  }
  public get serial() {
    return this.device ? this.device.serial : null;
  }
  public get product_reference() {
    return this.device ? this.device.product_reference : null;
  }
  public get order() {
    return this.device ? this.device.order : null;
  }
  public get manufacture_date() {
    return this.device ? this.device.manufacture_date : null;
  }
  public get is_under_warranty() {
    return this.device ? this.device.is_under_warranty : null;
  }

  constructor() {
    super(RMA);
  }

  /* ObjectModel FUNCTIONS */

  // public static instances: { [id: number]: Address; } = {};

  public static definition: ObjectDefinition = {
    database: {
      table: 'rmas',
      id: 'id',
    },
    trashDelete: true,
    values: {
      date: { type: 'string' },
      number: { type: 'string' },
      request_date: { type: 'string' },
      deposit_date: { type: 'string' },
      shipping_date: { type: 'string' },
      delivery_date: { type: 'string' },
      problem: { type: 'string' },
      supplier_reference: { type: 'string' },
      price: { type: 'number' },
      remarks: { type: 'string' },
      archived: { type: 'number' },
    },
    children: {
      device: {
        type: 'DeviceInstance',
        clone: false,
        save: false,
        delete: false,
      },
    },
    links: {},
  };
}
