import { AccessViewComponent } from 'src/app/components/views/access-view.component';
import { Accounting } from 'src/app/modules/other-data/classes/Accounting.class';
import { Injectable } from '@angular/core';
import { LoadingPromise } from 'src/app/classes/objects/LoadingPromise.class';
import { ViewsComponent } from 'src/app/components/views/views.component';

@Injectable({
  providedIn: 'root',
})
export class AccountingsService {
  public static accountings: Accounting[] = null;
  public static currentAccounting: Accounting = null;

  public static get otherAccountings(): Accounting[] {
    return AccountingsService.accountings.filter((a) => a.id !== AccountingsService.currentAccounting.id);
  }

  constructor() {}

  public static init(): Promise<any> {
    return LoadingPromise.create<any>((resolve, reject) => {
      Accounting.load(null, ['number']).then(
        (result: Accounting[]) => {
          AccountingsService.accountings = result;
          if (result.length > 0) AccountingsService.currentAccounting = result[0];
          resolve(result);
        },
        (err) => {
          console.error(err);
          reject(err);
        }
      );
    });
  }

  public static setCurrentAccounting(accounting: Accounting) {
    if (accounting) {
      AccountingsService.currentAccounting = accounting;
      let viewComponent: AccessViewComponent = ViewsComponent.instance.currentView;
      if (
        viewComponent &&
        viewComponent.onAccountingChanged &&
        typeof viewComponent.onAccountingChanged === 'function'
      ) {
        viewComponent.onAccountingChanged(accounting);
      }
    }
  }
}
