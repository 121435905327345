import { ObjectDefinition } from '../../../classes/objects/ObjectDefinition.class';
import { ObjectModel2 } from '../../../classes/objects/ObjectModel2.class';
import { ObjectModel3 } from 'src/app/classes/objects/ObjectModel3.class';

export class Signature extends ObjectModel3 {
  public name: string = null;
  public service: string = null;
  public picture: string = null;

  public constructor() {
    super(Signature);
  }

  /* ObjectModel FUNCTIONS */

  public static definition: ObjectDefinition = {
    trashDelete: true,
    database: {
      table: 'signatures',
      id: 'id',
    },
    values: {
      name: { type: 'string' },
      service: { type: 'string' },
      picture: { type: 'string' },
    },
    children: {},
    links: {},
  };
}
