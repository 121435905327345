import { Accounting } from '../../other-data/classes/Accounting.class';
import { Article } from '../../articles/classes/Article.class';
import { ArticleBase } from '../../articles/classes/ArticleBase.class';
import { Currency } from '../../other-data/classes/Currency.class';
import { Customer } from '../../customers/classes/Customer.class';
import { DateTimeUtil } from '../../utils/classes/DateTimeUtil.class';
import { Invoice } from '../../invoicing/classes/Invoice.class';
import { NumberUtil } from '../../utils/classes/NumberUtil.class';
import { ObjectDefinition } from '../../../classes/objects/ObjectDefinition.class';
import { ObjectModel2 } from '../../../classes/objects/ObjectModel2.class';
import { ObjectModel3 } from 'src/app/classes/objects/ObjectModel3.class';
import { Order } from '../../orders/classes/Order.class';
import { ParentStock } from './ParentStock.class';
import { StockAdjustment } from './StockAdjustment.class';
import { StockMove } from './StockMove.class';
import { Supplier } from '../../suppliers/classes/Supplier.class';

export class Stock extends ObjectModel3 {
  public accounting: Accounting = null;

  public _parent: ParentStock = null;
  public get parent() {
    return this._parent;
  }
  public set parent(value: ParentStock) {
    this._parent = value;
  }

  public order: Order = null;
  public _supplier: Supplier = null;
  public article: ArticleBase = null;
  public article_reference: string = null;

  public quantity: number = null;

  public creation_date: string = null;
  public update_date: string = null;
  public remarks: string = null;
  public archived: number = 0;

  public moves: StockMove[] = [];
  public adjustments: StockAdjustment[] = [];
  public allowed_customers: Customer[] = [];

  public get sale() {
    return this.order ? this.order.sale : null;
  }
  public get merchant() {
    return this.order ? this.order.merchant : null;
  }
  public get number() {
    return this.order ? this.order.number : null;
  }
  // public get customer() { return this.order ? this.order.customer : null; }
  public customer: Customer = null;
  public get supplier() {
    return this._supplier ? this._supplier : this.order ? this.order.supplier : null;
  }
  public set supplier(value: Supplier) {
    this._supplier = value;
  }
  // public get article() { return this.order ? this.order.article : null; }
  //public get quantity() { return this.order ? this.order.quantity : null; }
  public get unit() {
    return this.order ? this.order.unit : null;
  }
  public get buy_price() {
    return this.order ? this.order.buy_price : null;
  }
  public get unitBuyPrice() {
    return this.order ? this.order.unitBuyPrice : null;
  }
  public get sell_price() {
    return this.order ? this.order.sell_price : null;
  }

  public get buy_currency() {
    return this.order ? this.order.buy_currency : null;
  }
  public get buy_xrate() {
    return this.order ? this.order.buy_xrate : null;
  }
  public get sell_currency() {
    return this.order ? this.order.sell_currency : null;
  }
  public get sell_xrate() {
    return this.order ? this.order.sell_xrate : null;
  }

  public get converted_buy_price() {
    return this.order ? this.order.converted_buy_price : null;
  }
  public get converted_unitBuyPrice() {
    return this.order ? this.order.converted_unitBuyPrice : null;
  }
  public get converted_sell_price() {
    return this.order ? this.order.converted_sell_price : null;
  }

  public get numberNameAndDate() {
    let arr: string[] = [];
    if (this.order && this.order.number) arr.push(this.order.number);
    if (this.article && this.article.designation) arr.push(this.article.designation);
    if (this.creation_date) arr.push(DateTimeUtil.format(new Date(this.creation_date), 'd/m/Y'));
    return arr.join(' - ');
  }
  public get fullDescription() {
    let arr: string[] = [];
    if (this.creation_date) arr.push(DateTimeUtil.format(new Date(this.creation_date), '[d/m/Y]'));
    if (this.article && this.article.designation) arr.push(this.article.designation);
    if (this.customer) arr.push(this.customer.name);
    if (this.quantity != null || this.unit != null) arr.push(this.quantityAndUnit);
    return arr.join(' - ');
  }

  public get quantityAndUnit() {
    let arr: string[] = [];
    if (this.quantity != null) arr.push(NumberUtil.formatNumber(this.quantity, null, '.'));
    if (this.order && this.order.unit) arr.push(this.order.unit);
    return arr.join(' ');
  }
  public get availableStock() {
    let total: number = this.quantity;
    for (let i = 0; i < this.moves.length; ++i) total -= this.moves[i].quantity - this.moves[i].reimbursements_quantity;
    for (let i = 0; i < this.adjustments.length; ++i) total += this.adjustments[i].quantity;
    return total;
  }
  public get stockAndUnit() {
    return this.availableStock + '/' + this.quantityAndUnit;
  }
  public get movesCount() {
    return this.moves.length;
  }
  public get gain() {
    if (!this.parent || !this.quantity || !this.buy_price) return 0;
    else return this.quantity * (this.buy_price - this.parent.buy_price);
  }

  constructor() {
    super(Stock);
  }

  public copyFromOrder(order: Order) {
    this.accounting = order.accounting;
    this.order = order;
    this.customer = order.customer;
    this.article = order.article;
    this.quantity = this.order.quantity;
    this.article_reference = this.order.article_reference;
  }

  /* ObjectModel FUNCTIONS */

  public static definition: ObjectDefinition = {
    trashDelete: true,
    database: {
      table: 'stocks',
      id: 'id',
    },
    values: {
      creation_date: { type: 'string' },
      update_date: { type: 'string' },
      quantity: { type: 'number' },
      article_reference: { type: 'string' },
      remarks: { type: 'string' },
      archived: { type: 'number' },
    },
    children: {
      accounting: { type: 'Accounting', clone: false, save: false, delete: false },
      parent: { type: 'ParentStock', clone: false, save: false, delete: false },
      order: { type: 'Order', clone: false, save: false, delete: false },
      _supplier: { type: 'Supplier', clone: false, save: false, delete: false, db_column: 'id_supplier' },
      customer: { type: 'Customer', clone: false, save: false, delete: false },
      article: { type: 'ArticleBase', clone: false, save: false, delete: false },
    },
    links: {
      moves: {
        type: 'StockMove',
        table: 'stocks_stock_moves',
        id_self: 'id_stock',
        id_link: 'id_stock_move',
        clone: true,
        save: true,
        delete: true,
      },
      adjustments: {
        type: 'StockAdjustment',
        table: 'stocks_stock_adjustments',
        id_self: 'id_stock',
        id_link: 'id_stock_adjustment',
        clone: true,
        save: true,
        delete: true,
      },
      allowed_customers: {
        type: 'Customer',
        table: 'stocks_allowed_customers',
        id_self: 'id_stock',
        id_link: 'id_customer',
        clone: false,
        save: false,
        delete: false,
      },
    },
  };
}
