import { AfterViewInit, ChangeDetectorRef, Component, ComponentRef, ElementRef, OnInit } from '@angular/core';

import { Accounting } from 'src/app/modules/other-data/classes/Accounting.class';
import { LoginViewComponent } from '../../modules/login/views/login-view/login-view.component';
import { Toolbar } from '../toolbar/classes/Toolbar.class';
import { ViewsComponent } from './views.component';

@Component({
  selector: 'app-view-base',
  templateUrl: './view-base.component.html',
})
export class ViewBaseComponent implements OnInit, AfterViewInit {
  public toolbar: Toolbar;
  public instance: ViewBaseComponent;
  public permission: string = null;
  public viewLevel: number = 0;
  public _ref: ComponentRef<ViewBaseComponent> = null;
  public _routeId: string = null;
  public views: ViewsComponent = null;

  constructor(protected ref: ChangeDetectorRef, protected elRef: ElementRef) {
    this.instance = this;
  }

  ngOnInit() {}

  onAccountingChanged(accounting: Accounting) {}

  private changesEnabled: boolean = true;
  public enableChangeDetection() {
    if (!this.changesEnabled) {
      this.ref.reattach();
      this.changesEnabled = true;
    }
  }
  public disableChangeDetection() {
    if (this.changesEnabled) {
      this.ref.detach();
      this.changesEnabled = false;
    }
  }

  ngOnDestroy() {
    console.log('view base component destroying!!');
    this.instance = null;
    this.views = null;
    if (this.toolbar) this.toolbar.data = null;
    this.toolbar = null;
  }

  ngAfterViewInit() {}

  onActivate() {}

  initView(...args) {}

  public printView() {
    this.views.printMode = !this.views.printMode;
    setTimeout(() => {
      window.print();
      this.views.printMode = false;
    }, 0);
  }
}
