import { LoadSequence } from 'src/app/classes/objects/LoadSequence.class';
import { ObjectDefinition } from '../../../classes/objects/ObjectDefinition.class';
import { ObjectModel2 } from '../../../classes/objects/ObjectModel2.class';
import { ObjectModel3 } from 'src/app/classes/objects/ObjectModel3.class';

export class CustomerCategory extends ObjectModel3 {
  public name: string = null;

  public constructor() {
    super(CustomerCategory);
  }

  /* ObjectModel FUNCTIONS */

  public static definition: ObjectDefinition = {
    database: {
      table: 'customer_categories',
      id: 'id',
    },
    children: {},
    links: {},
    values: {
      name: { type: 'string' },
      visit_days: { type: 'number' },
    },
  };
}
