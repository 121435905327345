import { AccessViewComponent } from 'src/app/components/views/access-view.component';
import { Component } from '@angular/core';
import { DateTimeUtil } from 'src/app/modules/utils/classes/DateTimeUtil.class';
import { Deadline } from 'src/app/modules/other-data/classes/Deadline.class';
import { DeliveryTime } from 'src/app/modules/other-data/classes/DeliveryTime.class';
import { DialogButton } from 'src/app/components/dialogs/classes/DialogButton.class';
import { DialogsComponent } from 'src/app/components/dialogs/dialogs.component';
import { Discount } from 'src/app/modules/other-data/classes/Discount.class';
import { Incident } from '../../classes/Incident.class';
import { IncidentPrintingModel } from '../../classes/IncidentPrintingModel.class';
import { InfoBlock } from 'src/app/components/info-block/classes/InfoBlock.class';
import { InfoBlockComponent } from 'src/app/components/info-block/info-block.component';
import { InfoBlockField } from 'src/app/components/info-block/classes/InfoBlockField.class';
import { Order } from 'src/app/modules/orders/classes/Order.class';
import { PrintPreviewViewComponent } from 'src/app/modules/printing/views/print-preview-view/print-preview-view.component';
import { Supplier } from '../../../suppliers/classes/Supplier.class';
import { Toolbar } from 'src/app/components/toolbar/classes/Toolbar.class';
import { ViewsComponent } from 'src/app/components/views/views.component';
import { config } from '../../../../classes/config';

@Component({
  selector: 'app-incident-view',
  templateUrl: './incident-view.component.html',
  styleUrls: ['./incident-view.component.css'],
})
export class IncidentViewComponent extends AccessViewComponent {
  public toolbar: Toolbar = {
    class: 'toolbar-big',
    viewTitle: 'Fiche incident',
    data: this,
    onPrevPage: () => {
      if (this.incidentCopy && this.incidentCopy.changed) {
        DialogsComponent.display({
          icon: 'question',
          title: 'Enregistrer les modifications',
          message: 'Des modifications ont été effectuées.<br/>Voulez-vous enregistrer les modifications ?',
          buttons: DialogButton.yesNoCancelButtons,
        }).then((result: any) => {
          if (result === DialogButton.RESULT_YES) {
            this.save().then((result2: any) => {
              ViewsComponent.closeView();
            });
          } else if (result !== DialogButton.RESULT_CANCEL) ViewsComponent.closeView();
        });
        return false;
      }
      return true;
    },
    elements: [
      { type: 'separator' },
      {
        type: 'button',
        name: 'saveButton',
        text: 'Enregistrer',
        icon: 'save',
        click: function (view: IncidentViewComponent) {
          view.save().then((result) => {
            view.updateView(view.incidentCopy);
          });
        },
        options: { visible: true },
      },
      { type: 'separator-large' },
      {
        type: 'button',
        text: 'Aperçu avant<br/>impression',
        icon: 'search',
        click: function (view: IncidentViewComponent) {
          view.sendByMail();
        },
      },
      { type: 'spacing' },
    ],
  };

  public incident: Incident = null;
  public incidentCopy: Incident = null;

  public pricesData: any = { items: [] };
  public ordersData: any = { items: [] };
  public suppliersData: any = { items: [] };
  public discountsData: any = { items: [] };
  public deliveryTimeData: any = { items: [] };
  public deadlineData: any = { items: [] };
  public selectedOrder: Order = null;

  public selectBlock: InfoBlock = {
    title: '',
    backColor: 'rgb(0,0,0)',
    textColor: 'white',
    fields: [
      {
        title: 'N° de la commande',
        field: 'selectedOrder',
        type: 'searchable-foreign-list',
        multiSelect: false,
        listItems: this.ordersData,
        listField: 'numberAndName',
        nullText: '(Aucun)',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.updateView(this.incidentCopy);
        },
      },
    ],
  };

  public orderBlock: InfoBlock = {
    title: 'Infos\ncommande',
    backColor: 'rgb(54,96,146)',
    textColor: 'white',
    fields: [
      { title: 'Date', type: 'date', field: 'order.date', readonly: true },
      { title: 'N° de dossier', field: 'order.sale.number', readonly: true },
      {
        title: 'Fournisseur',
        field: 'supplier',
        type: 'foreign-list',
        multiSelect: false,
        listItems: this.suppliersData,
        listField: 'name',
        nullText: '(Aucun)',
      },
      {
        title: "Prix de vente à l'unité",
        field: 'order.buy_price',
        type: 'number',
        decimalsCount: 5,
        textAlign: 'left',
        numberUnit: ' € H.T.V.A.',
        readonly: true,
      },
    ],
  };

  public incidentBlock: InfoBlock = {
    title: 'Infos\nincident',
    backColor: 'rgb(54,96,146)',
    textColor: 'white',
    fields: [
      { title: 'Date', type: 'date', field: 'date' },
      { title: 'Dernière mise à jour', type: 'date', field: 'last_update', readonly: true },
      {
        title: 'Quantité défectueuse',
        field: 'quantity',
        type: 'number',
        textAlign: 'left',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          if (this.incidentCopy.amount)
            this.incidentCopy.amount = this.incidentCopy.quantity * this.selectedOrder.buy_price;
        },
      },
      { title: 'Unité', field: 'unit' },
      {
        title: 'Montant estimé du litige',
        field: 'amount',
        type: 'number',
        decimalsCount: 5,
        textAlign: 'left',
        numberUnit: ' € H.T.V.A.',
      },
      { title: 'Nature du litige', field: 'description', type: 'textarea' },
    ],
  };

  public closedBlock: InfoBlock = {
    title: '',
    backColor: 'rgb(28,50,75)',
    textColor: 'white',
    fields: [{ title: 'Clôturé', type: 'checkbox', field: 'closed' }],
  };

  public internalNotesBlock: InfoBlock = {
    title: '',
    backColor: 'rgb(32, 128, 128)',
    textColor: 'white',
    fields: [{ title: 'Notes internes', field: 'internal_notes', type: 'textarea' }],
  };

  public rollingField: InfoBlockField = {
    title: "Sens d'enroulement",
    field: 'field9',
    type: 'combo-box',
    listItems: { items: [] },
    listField: 'name',
  };
  public articleBlock: InfoBlock = {
    title: 'Informations article consommable',
    backColor: 'rgb(0,156,218)',
    textColor: 'white',
    fields: [
      { title: 'Aperçu', field: 'field15', type: 'image' }, //picture
      { title: 'Désignation', field: 'designation' },
      { title: 'Nomenclature', field: 'nomenclature.name', type: 'text', readonly: true },
      { title: 'Format', field: 'field1' }, //format
      { title: 'Matière', field: 'field2' }, //material
      {
        title: 'Matière (clients)',
        field: 'field3',
        type: 'nullable-text',
        nullText: '(Afficher la matière comme pour les fournisseurs)',
      }, //material2
      { title: 'Impression', field: 'field4' }, //printing
      { title: 'ø du mandrin / ø du rouleau', field: 'field5' }, //diameter
      { title: 'Présentation', field: 'field6' }, //presentation
      { title: "Nb. d'éti. / feuil. / bob. / parav.", field: 'field7' }, //labels_count
      { title: 'Esp. vertical / Esp. horizontal', field: 'field8' }, //space
      // { title: 'Sens d\'enroulement', field: 'field9' }, //rolling_direction
      this.rollingField,
      { title: 'Laize', field: 'field10' }, //width
      { title: 'Pinfeed', field: 'field11' }, //pinfeed
      { title: 'Perforations ou façonnage', field: 'field12' }, //perforations
      { title: 'Autre', field: 'field13' }, //other
      { title: 'Conditionnement', field: 'field14' }, //packaging
    ],
  };
  public deviceBlock: InfoBlock = {
    title: 'Informations article matériel',
    backColor: 'rgb(55, 136, 105)',
    textColor: 'white',
    fields: [
      { title: 'Aperçu', field: 'field15', type: 'image' }, //picture
      { title: 'Désignation', field: 'designation' }, //name
      { title: 'Nomenclature', field: 'nomenclature.name', type: 'text', readonly: true },
      { title: 'Marque et modèle', field: 'field1' }, //model
      { title: 'Technologie', field: 'field2' }, //technology
      { title: 'Résolution', field: 'field3' }, //resoution
      { title: 'Vitesse maximum', field: 'field4' }, //max_speed
      { title: 'Largeur du média', field: 'field5' }, //media_width
      { title: 'Epaisseur du média', field: 'field6' }, //media_thickness
      { title: 'Mémoire', field: 'field7' }, //memory
      { title: 'Couteau', field: 'field8' }, //knife
      { title: 'Interface (standard)', field: 'field9' }, //interface
      { title: 'Programmation', field: 'field10' }, //programming
      { title: 'Options', field: 'field11' }, //options
      { title: 'Construction', field: 'field12' }, //building
      { title: 'Garantie', field: 'field13' }, //warranty
      { title: 'Autre', field: 'field14' }, //other
    ],
  };

  ngOnInit() {
    Supplier.load(null, ['name']).then(
      (result) => {
        this.suppliersData.items = result;
      },
      (err) => {
        console.error(err);
      }
    );
    Order.load(null, ['number']).then(
      (result) => {
        this.ordersData.items = result;
      },
      (err) => {
        console.error(err);
      }
    );
    Discount.load(null, ['name']).then(
      (result) => {
        this.discountsData.items = result;
      },
      (err) => {
        console.error(err);
      }
    );
    DeliveryTime.load(null, ['name']).then(
      (result) => {
        this.deliveryTimeData.items = result;
      },
      (err) => {
        console.log(err);
      }
    );
    Deadline.load(null, ['name']).then(
      (result) => {
        this.deadlineData.items = result;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  initView(incident: Incident) {
    if (!incident) incident = new Incident();
    this.incident = incident;
    this.incidentCopy = incident.clone(true);
    this.selectedOrder = this.incidentCopy.order;
    this.updateView(this.incidentCopy);
  }

  updateView(incident: Incident) {
    if (this.selectedOrder instanceof Order && !this.incidentCopy.id) {
      incident.order = this.selectedOrder;
      incident.supplier = this.selectedOrder.supplier;
      if (!incident.date) incident.date = DateTimeUtil.toDateString(new Date());

      incident.quantity = this.selectedOrder.quantity;
      incident.unit = this.selectedOrder.unit;
      if (incident.quantity) incident.amount = incident.quantity * this.selectedOrder.buy_price;
    }
  }

  validateForm() {
    return true;
  }

  save() {
    return new Promise<any>((resolve, reject) => {
      if (this.validateForm() !== true) reject('invalid form');
      else {
        let today_date: string = DateTimeUtil.toDateString(new Date());
        this.incidentCopy.last_update = today_date;
        this.save2().then(
          (result) => {
            resolve(result);
          },
          (err) => {
            console.error(err);
          }
        );
      }
    });
  }

  save2() {
    return new Promise<any>((resolve, reject) => {
      this.incidentCopy.save2().then(
        (result) => {
          this.incidentCopy.clone(true, this.incident);
          this.initView(this.incident);
          resolve(result);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  sendByMail() {
    let view: PrintPreviewViewComponent = ViewsComponent.openView(
      PrintPreviewViewComponent,
      IncidentPrintingModel,
      this.incidentCopy
    ) as PrintPreviewViewComponent;
    let lang: string = this.incidentCopy.customer.lang ? this.incidentCopy.customer.lang.code || 'fr' : 'fr';
    let mail_to: string[] = [this.incidentCopy.supplier.commercial_address.email];
    let mail_cc: string[] = [null];
    let mail_bcc: string[] = [...config.mailBcc];
    let mail_subject: string[] = [
      {
        fr: 'Fiche incident - Commande ' + this.incidentCopy.order.number,
        en: 'Issue report - Order ' + this.incidentCopy.order.number,
        nl: 'Probleem verslag - Bestelling ' + this.incidentCopy.order.number,
      }[lang],
    ];
    let mail_body: string[] = [
      {
        fr: '<p>Cordialement,</p>',
        en: '<p>Yours faithfully,</p>',
        nl: '<p>Yours faithfully,</p>',
      }[lang],
    ];
    view.mail_to = mail_to;
    view.mail_cc = mail_cc;
    view.mail_bcc = mail_bcc;
    view.mail_subject = mail_subject;
    view.mail_body = mail_body;
  }
}
