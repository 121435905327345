import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import {
  foreingListColumn,
  numberColumn,
  textColumn,
} from 'src/app/components/data-grid2/classes/DataGridColumn.class';

import { AccessViewComponent } from 'src/app/components/views/access-view.component';
import { Accounting } from 'src/app/modules/other-data/classes/Accounting.class';
import { AccountingsService } from 'src/app/services/accountings/accountings.service';
import { ApiService } from '../../../../services/api/api.service';
import { ArrayUtil } from 'src/app/modules/utils/classes/ArrayUtil.class';
import { CredentialsService } from '../../../../services/credentials/credentials.service';
import { Customer } from '../../../customers/classes/Customer.class';
import { DataGrid2Component } from 'src/app/components/data-grid2/data-grid2.component';
import { DateTimeUtil } from 'src/app/modules/utils/classes/DateTimeUtil.class';
import { DialogButton } from '../../../../components/dialogs/classes/DialogButton.class';
import { DialogsComponent } from '../../../../components/dialogs/dialogs.component';
import { DomSanitizer } from '@angular/platform-browser';
import { Goal } from '../../classes/Goal.class';
import { GoalsPrintingModel } from '../../classes/GoalsPrintingModel.class';
import { LoadingPromise } from '../../../../classes/objects/LoadingPromise.class';
import { Merchant } from 'src/app/modules/other-data/classes/Merchant.class';
import { Nomenclature } from '../../../articles/classes/Nomenclature.class';
import { NotificationsComponent } from 'src/app/components/notifications/notifications.component';
import { NumberUtil } from 'src/app/modules/utils/classes/NumberUtil.class';
import { PrintPreviewViewComponent } from '../../../printing/views/print-preview-view/print-preview-view.component';
import { Toolbar } from 'src/app/components/toolbar/classes/Toolbar.class';
import { ViewsComponent } from 'src/app/components/views/views.component';
import { windowWithInterop } from '../../../interop/types';

@Component({
  selector: 'app-goals-view',
  templateUrl: './goals-list-view.component.html',
  styleUrls: ['./goals-list-view.component.scss'],
})
export class GoalsListViewComponent extends AccessViewComponent {
  public permission: string = 'ecriture_consolidations_budgets';

  public toolbar: Toolbar = {
    class: 'toolbar-big',
    viewTitle: 'Budgets',
    data: this,
    onPrevPage: () => {
      if (this.changed === true) {
        DialogsComponent.display({
          title: 'Enregistrer les modifications ?',
          message: 'Des modifications ont été faites dans le tableau.<br/>Que voulez-vous faire ?',
          buttons: [
            {
              result: DialogButton.RESULT_YES,
              text: 'Enregistrer les modifications',
            },
            {
              result: DialogButton.RESULT_NO,
              text: 'Abandonner les modifications',
            },
            { result: DialogButton.RESULT_CANCEL, text: 'Annuler' },
          ],
        }).then(
          (result) => {
            if (result === DialogButton.RESULT_YES)
              this.save().then(
                (result) => {
                  ViewsComponent.closeView();
                },
                (err) => {
                  console.error(err);
                }
              );
            else if (result === DialogButton.RESULT_NO) ViewsComponent.closeView();
          },
          (err) => {
            console.error(err);
          }
        );
      } else ViewsComponent.closeView();
      return false;
    },
    elements: [
      {
        type: 'button',
        text: 'Enregistrer',
        icon: 'save',
        click: function (view: GoalsListViewComponent) {
          view.save();
        },
        disabled: !this.writeAccess,
      },
      { type: 'separator-large' },
      {
        type: 'button',
        text: "Copier l'année<br/>précédente",
        icon: 'copy',
        click: function (view: GoalsListViewComponent) {
          view.copyLastYear();
        },
        disabled: !this.writeAccess,
      },
      { type: 'separator-large' },
      {
        type: 'button',
        text: 'Ajouter<br/>un objectif',
        icon: 'plus',
        click: function (view: GoalsListViewComponent) {
          view.createGoal();
        },
        disabled: !this.writeAccess,
      },
      { type: 'separator' },
      {
        type: 'button',
        text: 'Supprimer',
        icon: 'times',
        click: function (view: GoalsListViewComponent) {
          view.deleteGoals();
        },
        disabled: !this.writeAccess,
      },
      { type: 'separator-large' },
      {
        type: 'button',
        text: 'Imprimer',
        icon: 'print',
        click: function (view: GoalsListViewComponent) {
          view.print();
        },
      },
      { type: 'spacing' },
    ],
  };

  public goalsData: any = { items: [] };
  public history: any[] = [];
  public nomenclaturesData: any = { items: [] };
  @ViewChild('grid') grid: DataGrid2Component = null;
  @ViewChild('detailsContainer') detailsContainer: ElementRef = null;

  public additionalHeadersHtml: any[] = [];
  public fixedAdditionalHeadersHtml: any[] = [];
  public headerColumns: any[] = [];
  public bodyColumns: any[] = [];
  public fixedFooterRowsHtml: string[] = null;
  public footerRowsHtml: string[] = null;

  selectedMerchant: Merchant = null;
  selectedYear: number = DateTimeUtil.getCurrentYear();

  merchants: Merchant[] = [];
  customers: Customer[] = [];
  customersData: any = { items: [] };
  years: number[] = (() => {
    let _years: number[] = [];
    for (let year = 2019; year <= DateTimeUtil.getCurrentYear() + 1; ++year) _years.push(year);
    return _years;
  })();
  changed: boolean = false;
  nomenclatures: Nomenclature[] = [];
  public get currency() {
    return AccountingsService.currentAccounting.default_currency.symbol;
  }

  constructor(private sanitizer: DomSanitizer, public ref: ChangeDetectorRef, protected elRef: ElementRef) {
    super(ref, elRef);
  }

  ngOnInit() {
    let promises: Promise<any>[] = [];

    const conditions = ['archived != 1'];

    if (!CredentialsService.isUserAllowed('consolidations_budgets_voir_tous_representants')) {
      conditions.push(`id_user = '${CredentialsService.loggedMerchant.user.id}'`);
    }

    promises.push(
      Merchant.load(null, null, null, false, conditions.join(' AND ')).then(
        (result) => {
          this.merchants = result;
          this.selectedMerchant = this.merchants[0];
          this.reloadGoals();
        },
        (err) => {
          console.error(err);
        }
      )
    );
    promises.push(
      Customer.load(null, ['name']).then(
        (result) => {
          this.customers = result;
        },
        (err) => {
          console.error(err);
        }
      )
    );
    promises.push(
      Nomenclature.load(null, ['name'], null, false, '`budget` = 1').then(
        (result) => {
          this.nomenclaturesData.items = result;
        },
        (err) => {
          console.error(err);
        }
      )
    );

    Promise.all(promises).then(
      () => {
        this.reloadGoals();
      },
      (error) => {
        console.error(error);
      }
    );
    // Customer.load().then(
    //     (result) => { this.merchants = result; },
    //     (err) => { console.error(err); }
    // );
  }

  onActivate() {
    this.grid.detectChanges();
  }
  onAccountingChanged(accounting: Accounting) {
    this.reloadGoals();
  }

  createProxy(goal: Goal) {
    return new Proxy(goal, {
      get: (target, prop: string, receiver) => {
        if (prop.startsWith('amount_')) return target.amounts[prop.substring(7)];
        else if (prop.startsWith('margin_')) return target.margins[prop.substring(7)];
        else if (prop.startsWith('marginamount_'))
          return (target.amounts[prop.substring(13)] || 0) * (target.margins[prop.substring(13)] || 0);
        else if (prop === 'history_balance' && target.customer)
          return this.history[target.customer.id] ? this.history[target.customer.id].balance : null;
        else if (prop === 'history_goals' && target.customer)
          return this.history[target.customer.id] ? this.history[target.customer.id].goals : null;
        else if (prop === 'history_margin' && target.customer)
          return this.history[target.customer.id] ? this.history[target.customer.id].margin : null;
        else if (prop === 'history_markup_perc' && target.customer)
          return this.history[target.customer.id] ? this.history[target.customer.id].markup_perc * 100 : null;
        else if (prop === 'total') {
          if (!target.amounts) return null;
          let total = 0;
          for (var id in target.amounts) total += target.amounts[id];
          return total;
        } else if (prop === 'total_perc') {
          if (!target.amounts) return null;
          let total = 0;
          for (var id in target.amounts) total += target.amounts[id];
          return (100 * total) / this.total;
        } else if (prop === 'total_margin') {
          return target.rawMargin - target.rawMargin * (this.selectedMerchant ? this.selectedMerchant.margin / 100 : 0);
        } else if (prop === 'total_merchant') {
          return target.rawMargin * (this.selectedMerchant ? this.selectedMerchant.margin / 100 : 0);
        }
        return Reflect.get(target, prop, receiver);
      },
      set: (target: any, prop: string, value: any, receiver: any) => {
        if (prop.startsWith('amount_')) {
          target.amounts[prop.substring(7)] = value;
          this._updateFooterRow();
          return true;
        }
        if (prop.startsWith('margin_')) {
          target.margins[prop.substring(7)] = value;
          this._updateFooterRow();
          return true;
        }
        return Reflect.set(target, prop, value, receiver);
      },
    });
  }

  loadGoals(year?: number) {
    if (!year) year = this.selectedYear;
    let conditions: string[] = [];
    if (AccountingsService.currentAccounting)
      conditions.push(`id_accounting='${AccountingsService.currentAccounting.id}'`);
    conditions.push('`id_merchant` = "' + this.selectedMerchant.id + '"');
    if (year) conditions.push('year = ' + year);
    return Goal.load(null, null, null, false, conditions.join(' AND '));
  }

  loadHistory(year?: number) {
    if (!year) year = this.selectedYear;
    return ApiService.callModule('goals', 'goalsByCustomers', {
      year,
      id_merchant: this.selectedMerchant.id,
    }).then(
      (result) => (this.history = result.details),
      (err) => console.error(err)
    );
  }

  reloadGoals() {
    if (this.selectedMerchant) {
      const promises: Promise<any>[] = [];

      promises.push(
        this.loadGoals(this.selectedYear).then(
          (result) => (this.goalsData.items = result.map((goal) => this.createProxy(goal))),
          (err) => console.error(err)
        )
      );
      promises.push(this.loadHistory(this.selectedYear - 1));

      Promise.all(promises).then(
        () => {
          this.customersData.items = this.filteredCustomers;
          this.updateColumns();
          setTimeout(() => {
            this.grid.showAllChanged();
          }, 0);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  updateColumns() {
    let fixed_html1: string = `
            <th class="additional-header-cell">&nbsp;</th>
            <th class="additional-header-cell yellow" colspan="2">Client</th>
        `;
    let html1: string = `
            <th class="additional-header-cell yellow" colspan="4">Récapitulatif ${this.selectedYear - 1}</th>
        `;

    if (this.nomenclaturesData.items && this.nomenclaturesData.items.length > 0)
      html1 += `
            <th class="additional-header-cell yellow" colspan="${this.nomenclaturesData.items.length * 2}">Objectif ${
        this.selectedYear
      } par famille de produits</th>
            <th class="additional-header-cell yellow" colspan="5">TOTAUX</th>
        `;
    let categoryCol = textColumn('customerCategoryName', {
      title: 'Catégorie',
      width: 120,
      headerBackColor: 'gold',
    });
    let customerCol = foreingListColumn('customer', this.customersData, 'nameWithIdentifier', {
      title: 'Nom du client',
      width: 240,
      headerBackColor: 'gold',
      readonly: false,
      allowNull: true,
      nullText: 'Autres clients et prospects',
    });
    let realCol = numberColumn('history_balance', 2, {
      title: 'CA réel',
      width: 120,
      headerBackColor: 'gold',
      unit: '€',
    });
    let estimatedCol = numberColumn('history_goals', 2, {
      title: 'CA estimé',
      width: 120,
      headerBackColor: 'gold',
      unit: '€',
    });
    let marginCol = numberColumn('history_margin', 2, {
      title: 'Marge (€)',
      width: 120,
      headerBackColor: 'gold',
      unit: '€',
    });
    let markupCol = numberColumn('history_markup_perc', 2, {
      title: 'Taux de <br/>marque (%)',
      width: 120,
      headerBackColor: 'gold',
      unit: '%',
    });
    let totalCol = numberColumn('total', 2, {
      title: 'Total',
      width: 120,
      headerBackColor: 'gold',
      unit: '€',
    });
    let totalPercCol = numberColumn('total_perc', 2, {
      title: 'Pourcentage <br/>portefeuille',
      width: 120,
      headerBackColor: 'gold',
      unit: '%',
    });
    let totalMarginCol = numberColumn('totalNetMargin', 2, {
      title: 'Marge nette<br/>totale',
      width: 120,
      headerBackColor: 'gold',
      unit: '€',
    });
    let calculatedMarkupCol = numberColumn('calculatedMarkup', 2, {
      title: 'Taux de <br/>marque (%)',
      width: 120,
      headerBackColor: 'gold',
      unit: '%',
      readonly: true,
    });
    let totalMerchantCol = numberColumn('merchantAmount', 2, {
      title: `Revenus <br/>bruts <br/>représentant <br/>${
        this.selectedMerchant ? '(' + this.selectedMerchant.margin + '%)' : ''
      }`,
      width: 120,
      headerBackColor: 'gold',
      unit: '€',
    });
    this.headerColumns = [categoryCol, customerCol, realCol, estimatedCol, marginCol, markupCol];
    if (this.nomenclaturesData.items && this.nomenclaturesData.items.length > 0) {
      for (let nomencl of this.nomenclaturesData.items) {
        const col = numberColumn(`amount_${nomencl.id}`, 2, {
          title: nomencl.name,
          width: 240,
          headerBackColor: 'gold',
          change: this.somethingChanged,
          unit: '€',
          readonly: false,
        });
        const marginCol = numberColumn(`margin_${nomencl.id}`, 2, {
          title: 'Taux de <br/>marque (%)',
          width: 120,
          headerBackColor: 'gold',
          change: this.somethingChanged,
          unit: '%',
          readonly: false,
        });
        this.headerColumns.push(col);
        this.headerColumns.push(marginCol);
      }
    }
    this.headerColumns.push(totalCol, totalPercCol, calculatedMarkupCol, totalMarginCol, totalMerchantCol);
    this.bodyColumns = this.headerColumns;
    this.additionalHeadersHtml[0] = this.sanitizer.bypassSecurityTrustHtml(html1);
    this.fixedAdditionalHeadersHtml[0] = this.sanitizer.bypassSecurityTrustHtml(fixed_html1);
    this._updateFooterRow();
  }

  private _updateFooterRow() {
    this.fixedFooterRowsHtml = [
      `
            <td class="yellow footer-cell" colspan="3">TOTAUX</td>
        `,
    ];
    this.footerRowsHtml = [
      `
            <td class="yellow footer-cell">${NumberUtil.formatMoney(this.subtotal('history_balance'), '€', 2)}</td>
            <td class="yellow footer-cell">${NumberUtil.formatMoney(this.subtotal('history_goals'), '€', 2)}</td>
            <td class="yellow footer-cell">${NumberUtil.formatMoney(this.subtotal('history_margin'), '€', 2)}</td>
            <td class="yellow footer-cell">${NumberUtil.formatMoney(this.subtotal('history_markup_perc'), '€', 2)}</td>
            ${this.nomenclaturesData.items
              .map(
                (n) => `
                <td class="yellow footer-cell">${NumberUtil.formatMoney(this.subtotal('amount_' + n.id), '€', 2)}</td>
                <td class="yellow footer-cell">${NumberUtil.formatMoney(
                  this.subtotal('marginamount_' + n.id) / this.subtotal('amount_' + n.id),
                  '%',
                  2
                )}</td>
            `
              )
              .join('\n')}
            <td class="yellow footer-cell">${NumberUtil.formatMoney(this.subtotal('total'), '€', 2)}</td>
            <td class="yellow footer-cell">${NumberUtil.formatMoney(this.subtotal('total_perc'), '%', 2)}</td>
            <td class="yellow footer-cell">${NumberUtil.formatMoney(
              (100 * this.subtotal('totalRawMargin')) / this.subtotal('total'),
              '%',
              2
            )}</td>
            <td class="yellow footer-cell">${NumberUtil.formatMoney(this.subtotal('totalNetMargin'), '€', 2)}</td>
            <td class="yellow footer-cell">${NumberUtil.formatMoney(this.subtotal('merchantAmount'), '€', 2)}</td>
        `,
    ];
  }

  subtotal(property: string) {
    return this.goalsData.items.reduce((prev, cur) => prev + (cur[property] || 0), 0);
  }

  copyLastYear() {
    this.loadGoals(this.selectedYear - 1).then(
      (result) => {
        console.log('goals to copy:', result);
        const proxies = result.map((g: Goal) => {
          g.year = this.selectedYear;
          g.id = null;
          return this.createProxy(g);
        });
        this.goalsData.items.push(...proxies);
        this.updateColumns();
      },
      (error) => {
        console.error(error);
      }
    );
  }

  createGoal() {
    const goal: Goal = new Goal();
    goal.merchant = this.selectedMerchant;
    goal.year = this.selectedYear;
    goal.accounting = AccountingsService.currentAccounting;
    goal.amounts = {};
    this.goalsData.items.push(this.createProxy(goal));
    this.changed = true;
    this.grid.items = this.goalsData.items;
    setTimeout(() => {
      this.grid.setActiveCell(this.grid._filteredItems.length - 1, 1);
      this.grid.startEditingCurrentCell();
    }, 0);
  }

  deleteGoals() {
    DialogsComponent.display({
      title: 'Supprimer ?',
      message: 'Êtes-vous sûr(e) de vouloir supprimer les objectifs sélectionés ?',
      icon: 'warning',
      buttons: DialogButton.yesNoButtons,
    }).then(
      (result) => {
        if (result === DialogButton.RESULT_YES) {
          let goals: Goal[] = this.grid.selectedItems;
          if (Array.isArray(goals) && goals.length > 0) {
            let promises: any[] = [];
            for (let i = 0; i < goals.length; ++i) promises.push(goals[i].delete());
            Promise.all(promises).then(
              (result) => {
                NotificationsComponent.push({
                  type: 'success',
                  title: 'Goals supprimées',
                  summary: 'Les goals ont été supprimés avec succès',
                });
                ArrayUtil.removeElements(this.goalsData.items, goals);
                this.grid.items = this.goalsData.items;
                this.grid.regenerateVisibleItems();
              },
              (err) => {
                NotificationsComponent.push({
                  type: 'error',
                  title: 'Erreur lors de la suppression',
                  summary: "Une erreur s'est produite lors de la suppression",
                  content: err,
                });
                console.error(err);
              }
            );
          }
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }

  save() {
    return LoadingPromise.create<any>((resolve, reject) => {
      let promises: Promise<any>[] = [];
      for (let i = 0; i < this.goalsData.items.length; ++i)
        promises.push(this.goalsData.items[i].instance.save3(false));
      return Promise.all(promises).then(
        (result) => {
          NotificationsComponent.push({
            title: 'Budgets enregistrés',
            summary: 'Les modifications ont bien été enregistrées',
            type: 'success',
          });
          this.changed = false;
          resolve(true);
        },
        (err) => {
          console.error(err);
          NotificationsComponent.push({
            title: 'Erreur',
            summary: "Une erreur s'est produite lors de l'enregistrement",
            type: 'error',
          });
          reject(err);
        }
      );
    });
  }

  public get filteredCustomers() {
    console.log('customer', this.customers);
    return this.customers.filter((customer: Customer) => {
      return customer.merchant.id === this.selectedMerchant.id;
    });
  }

  updateBudgetsTable() {
    LoadingPromise.create<any>((resolve, reject) => {
      if (this.changed === true) {
        DialogsComponent.display({
          title: 'Enregistrer les modifications ?',
          message: 'Des modifications ont été faites dans le tableau.<br/>Que voulez-vous faire ?',
          buttons: [
            {
              result: DialogButton.RESULT_YES,
              text: 'Enregistrer les modifications',
            },
            {
              result: DialogButton.RESULT_NO,
              text: 'Abandonner les modifications',
            },
          ],
        }).then(
          (result) => {
            if (result === DialogButton.RESULT_YES)
              this.save().then(
                (result) => {
                  resolve(result);
                },
                (err) => {
                  reject(err);
                }
              );
            else resolve(true);
          },
          (err) => {
            reject(err);
          }
        );
      } else resolve(true);
    }).then(
      (result) => {
        this.changed = false;
        this.reloadGoals();
      },
      (err) => {
        console.error(err);
      }
    );
  }

  customerChanged(goal: Goal) {
    ApiService.callModule('goals', 'goalsByCustomers', {
      year: this.selectedYear - 1,
      id_merchant: this.selectedMerchant ? this.selectedMerchant.id : null,
      id_customer: goal.customer ? goal.customer.id : null,
    }).then(
      (result) => {
        this.history = Object.assign(this.history, result.details);
      },
      (err) => {
        console.error(err);
      }
    );
    this.somethingChanged();
  }

  somethingChanged() {
    this.changed = true;
  }

  public get total(): number {
    let total: number = 0;
    for (let i = 0; i < this.goalsData.items.length; ++i) total += this.goalsData.items[i].total;
    return total;
  }

  percentage(goal) {
    return (100 * goal.total) / this.total;
  }

  public nomenclatureSubtotal(nomenclatureId: string) {
    const goals: Goal[] = this.goalsData.items;
    let total: number = 0;
    for (let i = 0; i < goals.length; ++i)
      total += goals[i].amounts[nomenclatureId] ? goals[i].amounts[nomenclatureId] : 0;
    return total;
  }

  public get overallTotal() {
    const goals: Goal[] = this.goalsData.items;
    let total: number = 0;
    for (let i = 0; i < goals.length; ++i) total += goals[i].total;
    return total;
  }
  public get overallMargin() {
    const goals: Goal[] = this.goalsData.items;
    let total: number = 0;
    for (let i = 0; i < goals.length; ++i) total += goals[i].totalRawMargin;
    return total;
  }
  public get overallMarginPerc() {
    const total: number = this.overallTotal;
    return total != 0 ? (100 * this.overallMargin) / total : 0;
  }

  public print() {
    const view: PrintPreviewViewComponent = ViewsComponent.openView(
      PrintPreviewViewComponent,
      GoalsPrintingModel,
      this.goalsData.items,
      this.nomenclaturesData.items,
      this.selectedYear,
      this.selectedMerchant
    ) as PrintPreviewViewComponent;
    view.mail_to = [this.selectedMerchant.email];
    view.mergePages = true;
  }

  public onActiveCellsChanged(activeCells: number[]) {
    const [activeCol, activeRow] = activeCells;
    const selectedGoal = this.grid.filteredItems[activeRow];
    if (!selectedGoal) return;

    const selectedCustomer = selectedGoal.customer;
    if (!selectedCustomer) return;

    const nomenclatureIndex = Math.min(
      this.nomenclaturesData.items.length || 0,
      Math.max(Math.floor((activeCol - 6) / 2), 0)
    );
    const nomenclature = this.nomenclaturesData.items.length ? this.nomenclaturesData.items[nomenclatureIndex] : null;

    const customerId = selectedCustomer.id;
    const nomenclatureId = nomenclature ? nomenclature.id : undefined;
    const year = this.selectedYear - 1;

    console.log('injecting...', customerId, nomenclatureId, year);
    windowWithInterop.reactInterop.injectComponent(
      'CustomerGoalsSummary',
      this.detailsContainer.nativeElement,
      'CustomerGoalsSummary',
      {
        customerId,
        nomenclatureId,
        year,
      }
    );
  }
}
