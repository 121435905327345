import { AccessViewComponent } from 'src/app/components/views/access-view.component';
import { Accounting } from 'src/app/modules/other-data/classes/Accounting.class';
import { AccountingsService } from 'src/app/services/accountings/accountings.service';
import { ArrayUtil } from 'src/app/modules/utils/classes/ArrayUtil.class';
import { Component } from '@angular/core';
import { CurrenciesService } from 'src/app/services/currencies/currencies.service';
import { DateTimeUtil } from 'src/app/modules/utils/classes/DateTimeUtil.class';
import { Deadline } from 'src/app/modules/other-data/classes/Deadline.class';
import { Delivery } from 'src/app/modules/other-data/classes/Delivery.class';
import { DeliveryTime } from 'src/app/modules/other-data/classes/DeliveryTime.class';
import { DialogButton } from 'src/app/components/dialogs/classes/DialogButton.class';
import { DialogsComponent } from 'src/app/components/dialogs/dialogs.component';
import { Discount } from 'src/app/modules/other-data/classes/Discount.class';
import { FilesystemUtil } from 'src/app/modules/utils/classes/FilesystemUtil.class';
import { InfoBlock } from 'src/app/components/info-block/classes/InfoBlock.class';
import { InfoBlockComponent } from 'src/app/components/info-block/info-block.component';
import { InfoBlockField } from 'src/app/components/info-block/classes/InfoBlockField.class';
import { Invoice } from '../../classes/Invoice.class';
import { PrintPreviewViewComponent } from 'src/app/modules/printing/views/print-preview-view/print-preview-view.component';
import { PrintingModel } from 'src/app/modules/printing/classes/PrintingModel.class';
import { Reimbursement } from '../../classes/Reimbursement.class';
import { ReimbursementPrintingModel } from '../../classes/ReimbursementPrintingModel.class';
import { SettingsComponent } from 'src/app/components/settings/settings.component';
import { Toolbar } from 'src/app/components/toolbar/classes/Toolbar.class';
import { ViewsComponent } from 'src/app/components/views/views.component';
import { config } from '../../../../classes/config';

@Component({
  selector: 'app-reimbursement-view',
  templateUrl: './reimbursement-view.component.html',
  styleUrls: ['./reimbursement-view.component.css'],
})
export class ReimbursementViewComponent extends AccessViewComponent {
  public permission: string = 'ecriture_facturation_nc';

  public toolbar: Toolbar = {
    class: 'toolbar-big',
    viewTitle: 'N.C. commerciale',
    data: this,
    elements: [
      // { type: 'separator' },
      // {
      //     type: 'button',
      //     name: 'saveButton',
      //     text: 'Enregistrer',
      //     icon: 'save',
      //     click: function (view: ReimbursementViewComponent) {
      //         view.save().then((result) => { view.updateView(view.reimbursementCopy); });
      //     },
      //     options: { visible: true }
      // },
      { type: 'separator' },
      {
        type: 'button',
        text: 'Enregistrer<br/>(ERP + PDF)',
        icon: 'save',
        click: function (view: ReimbursementViewComponent) {
          view.save().then((result) => {
            view.updateView(view.reimbursementCopy);
            view.saveFile();
          });
        },
        access: this.writeAccess,
      },
      { type: 'separator' },
      {
        type: 'button',
        text: 'Aperçu avant<br/>impression',
        icon: 'search',
        click: function (view: ReimbursementViewComponent) {
          view.openPreview();
        },
      },
      // { type: 'separator' },
      // {
      //     type: 'button',
      //     text: 'Imprimer',
      //     icon: 'print',
      //     click: function (view: ReimbursementViewComponent) {
      //         PrintPreviewViewComponent.globalInstance.model = ReimbursementPrintingModel.generate(view.reimbursementCopy);
      //         setTimeout(() => {
      //             PrintPreviewViewComponent.globalInstance.print();
      //         }, 0)
      //     }
      // },
      // { type: 'separator' },
      // {
      //     type: 'button',
      //     text: 'Envoyer<br/>au client',
      //     icon: ' fas fa-at',
      //     click: function (view: ReimbursementViewComponent) {
      //         view.sendToCustomer();
      //     }
      // },
      // { type: 'separator-large' },
      // {
      //     type: 'button',
      //     text: 'Ouvrir dans<br/>Acrobat',
      //     icon: ' far fa-file-pdf',
      //     click: function (view: ReimbursementViewComponent) {
      //         PrintPreviewViewComponent.globalInstance.model = ReimbursementPrintingModel.generate(view.reimbursementCopy);
      //         setTimeout(() => {
      //             PrintPreviewViewComponent.globalInstance.openFile();
      //         }, 0)
      //     }
      // },
      { type: 'spacing' },
    ],
  };

  public reimbursement: Reimbursement = null;
  public reimbursementCopy: Reimbursement = null;
  public model: ReimbursementPrintingModel = null;

  public pricesData: any = { items: [] };
  public invoicesData: any = { items: [] };
  public discountsData: any = { items: [] };
  public deliveriesData: any = { items: [] };
  public deliveryTimeData: any = { items: [] };
  public deadlineData: any = { items: [] };
  public accountingsData: any = { items: [] };
  public currenciesData: any = { items: [] };
  public selectedInvoice: Invoice = null;

  public selectBlock: InfoBlock = {
    title: '',
    backColor: 'rgb(0,0,0)',
    textColor: 'white',
    fields: [
      {
        title: 'N° de la facture à éditer',
        field: 'selectedInvoice',
        type: 'foreign-list',
        multiSelect: false,
        listItems: this.invoicesData,
        listField: 'number',
        nullText: '(Aucun)',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.reimbursementCopy.number = null;
          this.updateView(this.reimbursementCopy);
        },
      },
    ],
  };
  public reimbursementBlock: InfoBlock = {
    title: '',
    backColor: 'rgb(189, 74, 72)',
    textColor: 'white',
    fields: [
      {
        title: 'Date',
        type: 'date',
        field: 'date',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.updateView(this.reimbursementCopy);
        },
      },
      { title: 'Numéro', type: 'text', field: 'number', readonly: true },
      { title: 'NC financière', type: 'checkbox', field: 'financial' },
    ],
  };

  public saleBlock: InfoBlock = {
    title: '',
    backColor: 'rgb(149, 55, 53)',
    textColor: 'white',
    fields: [
      { title: 'Fournisseur', field: 'order.supplier.name', readonly: true },
      { title: "N° de l'offre", field: 'order.sale.number', readonly: true },
      { title: 'Représentant', type: 'text', field: 'order.merchant.numberAndName', readonly: true },
    ],
  };

  public amountsBlock: InfoBlock = {
    title: '',
    backColor: 'rgb(109, 35, 33)',
    textColor: 'white',
    fields: [
      {
        title: 'Désignation',
        field: 'designation',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.updateView(this.reimbursementCopy);
        },
      },
      {
        title: 'Quantité',
        field: 'quantity',
        type: 'number',
        textAlign: 'left',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          //this.reimbursementCopy.internal_fees = this.reimbursementCopy.invoice.internal_fees * (this.reimbursementCopy.quantity / this.reimbursementCopy.invoice.quantity);
          this.updateView(this.reimbursementCopy);
        },
      },
      {
        title: 'Unité',
        field: 'unit',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.updateView(this.reimbursementCopy);
        },
      },
      {
        title: 'Prix de vente',
        field: 'converted_sell_price',
        type: 'number',
        decimalsCount: 5,
        textAlign: 'left',
        currencyField: 'sell_currency',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.updateView(this.reimbursementCopy);
        },
      },
      {
        title: 'Taux TVA',
        field: 'vat',
        type: 'number',
        decimalsCount: 1,
        numberUnit: '%',
        textAlign: 'left',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.updateView(this.reimbursementCopy);
        },
      },
      {
        title: 'Frais de gestion',
        field: 'converted_internal_fees',
        type: 'number',
        decimalsCount: 2,
        currencyField: 'sell_currency',
        textAlign: 'left',
      },
      {
        title: 'Frais à imputer\nau représentant',
        field: 'merchant_fees',
        type: 'number',
        decimalsCount: 2,
        numberUnit: '€',
        textAlign: 'left',
      },
      {
        title: 'Échéance',
        field: 'deadline',
        type: 'combo-box',
        disableFiltering: true,
        multiSelect: false,
        listItems: this.deadlineData,
        listField: 'name',
        nullText: '(Aucun)',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.updateView(this.reimbursementCopy);
        },
      },
    ],
  };

  public accountingBlock: InfoBlock = {
    title: '',
    backColor: 'rgb(64,0,64)',
    textColor: 'white',
    fields: [
      {
        title: 'Comptabilité',
        field: 'accounting',
        type: 'foreign-list',
        readonly: true,
        multiSelect: false,
        listItems: this.accountingsData,
        listField: 'fullDescription',
        allowBlankValues: false,
        comment: 'Sélectionnez ici la comptabilité liée à ce dossier',
      },
      {
        title: "Devise d'achat",
        field: 'buy_currency',
        type: 'foreign-list',
        readonly: true,
        multiSelect: false,
        listItems: this.currenciesData,
        listField: 'fullDescription',
        allowBlankValues: false,
      },
      {
        title: "Taux de change d'achat",
        field: 'buy_xrate',
        type: 'number',
        decimalsCount: 6,
        textAlign: 'left',
        readonly: true,
      },
      {
        title: 'Devise de vente',
        field: 'sell_currency',
        type: 'foreign-list',
        readonly: true,
        multiSelect: false,
        listItems: this.currenciesData,
        listField: 'fullDescription',
        allowBlankValues: false,
      },
      {
        title: 'Taux de change de vente',
        field: 'sell_xrate',
        type: 'number',
        decimalsCount: 6,
        textAlign: 'left',
        readonly: true,
      },
    ],
  };

  public remarksBlock: InfoBlock = {
    title: '',
    backColor: 'rgb(69, 0, 0)',
    textColor: 'white',
    fields: [
      {
        title: 'Remarques',
        field: 'remarks',
        type: 'textarea',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.updateView(this.reimbursementCopy);
        },
      },
    ],
  };

  public get reimbursementCopyArticleIsArticle() {
    return this.reimbursementCopyArticle && this.reimbursementCopyArticle.type === 0;
  }
  public get reimbursementCopyArticleIsDevice() {
    return this.reimbursementCopyArticle && this.reimbursementCopyArticle.type === 1;
  }
  public get reimbursementCopyArticle() {
    if (this.reimbursementCopy && this.reimbursementCopy.order && this.reimbursementCopy.order.sale)
      return this.reimbursementCopy.order.sale.article;
    return null;
  }

  ngOnInit() {
    this.accountingsData.items = AccountingsService.accountings;
    this.currenciesData.items = CurrenciesService.currencies;
    this.reloadInvoices();
    Discount.load(null, ['name']).then(
      (result) => {
        this.discountsData.items = result;
      },
      (err) => {
        console.error(err);
      }
    );
    Delivery.load(null, ['description']).then(
      (result) => {
        this.deliveriesData.items = result;
      },
      (err) => {
        console.log(err);
      }
    );
    DeliveryTime.load(null, ['name']).then(
      (result) => {
        this.deliveryTimeData.items = result;
      },
      (err) => {
        console.log(err);
      }
    );
    Deadline.load(null, ['name']).then(
      (result) => {
        this.deadlineData.items = ArrayUtil.sortQuantityArray(result, 'name');
      },
      (err) => {
        console.log(err);
      }
    );
  }
  onAccountingChanged(accounting: Accounting) {
    this.reloadInvoices();
  }

  reloadInvoices() {
    Invoice.load(
      null,
      ['number'],
      null,
      false,
      (AccountingsService.currentAccounting
        ? `id_accounting='${AccountingsService.currentAccounting.id}'` + ' AND '
        : '') +
        'number LIKE "__/__' +
        (this.logisticsView ? '1' : '0') +
        '%"'
    ).then(
      (result) => {
        this.invoicesData.items = result;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  public logisticsView: boolean = false;
  initView(reimbursement: Reimbursement, logisticsView: boolean = false) {
    this.logisticsView = logisticsView;
    if (logisticsView) {
      this.reimbursementBlock.backColor = 'rgb(60, 95, 189)';
      this.saleBlock.backColor = 'rgb(40, 65, 149)';
      this.amountsBlock.backColor = 'rgb(20, 35, 109)';
      this.remarksBlock.backColor = 'rgb(0, 0, 69)';
      this.toolbar.viewTitle = 'N.C. logistique';
    }
    if (!reimbursement) {
      reimbursement = new Reimbursement();
      reimbursement.accounting = AccountingsService.currentAccounting;
    }
    this.reimbursement = reimbursement;
    this.reimbursementCopy = reimbursement.clone(true);
    this.selectedInvoice = this.reimbursementCopy.invoice;
    this.updateView(this.reimbursementCopy);
  }

  updateView(reimbursement: Reimbursement) {
    if (this.selectedInvoice instanceof Invoice && (!reimbursement.id || reimbursement.number == null)) {
      reimbursement.invoice = this.selectedInvoice;
      reimbursement.accounting = this.selectedInvoice.accounting;
      if (!reimbursement.date) reimbursement.date = DateTimeUtil.toDateString(new Date());
      if (!reimbursement.designation)
        reimbursement.designation = this.selectedInvoice.article ? this.selectedInvoice.article.designation : '';
      if (!reimbursement.quantity) reimbursement.quantity = this.selectedInvoice.quantity;
      if (!reimbursement.unit) reimbursement.unit = this.selectedInvoice.unit;
      if (!reimbursement.sell_price) reimbursement.sell_price = -this.selectedInvoice.sell_price;
      if (!reimbursement.vat) reimbursement.vat = this.selectedInvoice.vat;
      if (!reimbursement.deadline) reimbursement.deadline = 'Comptant';
      if (!reimbursement.internal_fees)
        reimbursement.internal_fees =
          -this.selectedInvoice.internal_fees * (reimbursement.quantity / this.selectedInvoice.quantity);
      if (!reimbursement.number) {
        reimbursement.getNextNumber().then(
          (result) => {
            setTimeout(() => {
              reimbursement.number = reimbursement.generateNumber(result.details);
              this.model = ReimbursementPrintingModel.generate(reimbursement) as ReimbursementPrintingModel;
            }, 0);
          },
          (err) => {
            console.log(err);
          }
        );
      } else this.model = ReimbursementPrintingModel.generate(reimbursement) as ReimbursementPrintingModel;
    } else if (reimbursement.id) {
      this.model = ReimbursementPrintingModel.generate(reimbursement) as ReimbursementPrintingModel;
    }
  }

  validateForm() {
    return true;
  }

  save() {
    return new Promise<any>((resolve, reject) => {
      if (this.validateForm() !== true) reject('invalid form');
      else {
        let reimbursement_date: string = this.reimbursementCopy.date;
        let today_date: string = DateTimeUtil.toDateString(new Date());
        if (reimbursement_date != today_date) {
          DialogsComponent.display({
            icon: 'question',
            title: 'Date de la facture',
            message:
              'Vous avez spécifié la date suivante pour la facture : <b>' +
              reimbursement_date +
              '</b><br/>' +
              'Voulez-vous changer pour la date du jour <b>' +
              today_date +
              '</b> ?',
            buttons: [
              { text: 'Oui, changer la date', result: DialogButton.RESULT_YES },
              { text: 'Non, merci !', result: DialogButton.RESULT_NO },
              DialogButton.cancelButton,
            ],
          }).then((result) => {
            if (result == DialogButton.RESULT_YES) this.reimbursementCopy.date = today_date;
            if (result == DialogButton.RESULT_CANCEL) reject('cancelled by user');
            else
              this.save2().then(
                (result) => {
                  resolve(result);
                },
                (err) => {
                  console.error(err);
                }
              );
          });
        } else
          this.save2().then(
            (result) => {
              resolve(result);
            },
            (err) => {
              console.error(err);
            }
          );
      }
    });
  }

  save2() {
    return new Promise<any>((resolve, reject) => {
      this.reimbursementCopy.save2().then(
        (result) => {
          this.reimbursementCopy.clone(true, this.reimbursement);
          if (!this.reimbursement.invoice.reimbursements) this.reimbursement.invoice.reimbursements = [];
          this.reimbursement.invoice.reimbursements.push(this.reimbursement);
          let promises: Promise<any>[] = [];
          promises.push(this.reimbursement.invoice.save2());
          Promise.all(promises).then(
            (result2) => {
              this.initView(this.reimbursement);
              resolve(result2);
            },
            (err) => {
              console.error(err);
              reject(err);
            }
          );
        },
        (err) => {
          console.error(err);
          reject(err);
        }
      );
    });
  }

  public iterateFiles(callback) {
    let models: PrintingModel[] = [];
    models.push(ReimbursementPrintingModel.generate(this.reimbursementCopy));
    return PrintPreviewViewComponent.globalInstance.iteratePrintingModels(models, callback);
  }

  public saveFile() {
    this.iterateFiles((model: PrintingModel, index: number) => {
      let path: string = FilesystemUtil.resolveSpecialPath(
        SettingsComponent.get(
          this.logisticsView ? 'pdf_logistics_reimbursements_save_path' : 'pdf_reimbursements_save_path'
        )
      );
      if (!path) return null;
      else return PrintPreviewViewComponent.globalInstance.saveFileAs(path + model.saveFilename);
    });
  }

  public printPreview() {
    if (this.validateForm()) {
      let lang: string = this.reimbursementCopy.customer.lang
        ? this.reimbursementCopy.customer.lang.code || 'fr'
        : 'fr';
      let bcc: string[] = [...config.mailBcc];
      if (!bcc.includes(this.reimbursementCopy.merchant.email)) bcc.push(this.reimbursementCopy.merchant.email);
      let model: ReimbursementPrintingModel =
        this.model || (ReimbursementPrintingModel.generate(this.reimbursementCopy) as ReimbursementPrintingModel);
      let view: PrintPreviewViewComponent = ViewsComponent.openView(
        PrintPreviewViewComponent,
        ReimbursementPrintingModel,
        this.reimbursementCopy
      ) as PrintPreviewViewComponent;
      // this.iterateFiles((model: PrintingModel, index: number) => {
      //     return PrintPreviewViewComponent.globalInstance.sendByMail(
      view.mail_to = [this.reimbursementCopy.order.invoicing_emails];
      view.mail_cc = null;
      view.mail_bcc = bcc;
      view.mail_subject = [
        {
          fr: 'Notre note de crédit pour votre commande ' + this.reimbursementCopy.order.customer_reference,
          en: 'Our credit note for your order ' + this.reimbursementCopy.order.customer_reference,
          nl: 'Our credit note for your order ' + this.reimbursementCopy.order.customer_reference,
        }[lang],
      ];
      view.mail_body = [
        {
          fr:
            '<p>Madame, Monsieur,</p>' +
            '<p>Suite à votre commande susmentionnée, pour laquelle nous vous remercions, nous tenons à vous informer que nous avons établi une note de crédit.</p>' +
            '<p>Vous trouverez, en pièce jointe, ' +
            (this.dontSendByPost
              ? 'la note de crédit'
              : 'la copie de la note de crédit que nous vous avons expédiée par voie postale.') +
            '</p>' +
            '<p>Nous restons à votre entière disposition, et nous vous prions de croire, Madame, Monsieur, en nos salutations les plus sincères.</p>' +
            '<p>Cordialement,</p>',
          en:
            '<p>Dear Sir / Madam,</p>' +
            '<p>Following your above-mentioned order, a credit note has been created.</p>' +
            '<p>The reason for this credit is clearly mentioned in the attached document, ' +
            (this.dontSendByPost
              ? 'which is the only copy you will receive'
              : 'which will also be sent in hard copy by post') +
            '.</p>' +
            '<p>Please do not hesitate to contact us for any questions.</p>' +
            '<p>Yours faithfully,</p>',
          nl:
            '<p>Dear Sir / Madam,</p>' +
            '<p>Following your above-mentioned order, a credit note has been created.</p>' +
            '<p>The reason for this credit is clearly mentioned in the attached document, ' +
            (this.dontSendByPost
              ? 'which is the only copy you will receive'
              : 'which will also be sent in hard copy by post') +
            '.</p>' +
            '<p>Please do not hesitate to contact us for any questions.</p>' +
            '<p>Yours faithfully,</p>',
        }[lang],
      ];
      view.mail_filename = [model.mailFilename];
    }
  }

  get dontSendByPost() {
    if (!this.reimbursementCopy) return false;
    if (!this.reimbursementCopy.customer) return false;
    if (!this.reimbursementCopy.customer.billing_address) return false;
    else return !this.reimbursementCopy.customer.billing_address.invoicing_by_paper;
  }

  get dontSendByMail() {
    if (!this.reimbursementCopy) return false;
    if (!this.reimbursementCopy.customer) return false;
    if (!this.reimbursementCopy.customer.billing_address) return false;
    else return !this.reimbursementCopy.customer.billing_address.invoicing_by_mail;
  }

  private openPreview() {
    // if (this.logisticsView) this.printPreviewLogistics();
    // else this.printPreview();
    this.printPreview();
  }
}
