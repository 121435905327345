import { ClassManager } from './ClassManager.class';
import { DataService } from 'src/app/services/data/data.service';
import { DeleteSequence } from './DeleteSequence.class';
import { LoadSequence } from './LoadSequence.class';
import { LoadingPromise } from './LoadingPromise.class';
import { NotificationsComponent } from 'src/app/components/notifications/notifications.component';
import { ObjectChildDefinition } from './ObjectChildDefinition.class';
import { ObjectDefinition } from './ObjectDefinition.class';
import { ObjectLinkDefinition } from './ObjectLinkDefinition.class';
import { ObjectModel2 } from './ObjectModel2.class';
import { SaveSequence } from './SaveSequence.class';
import { TextUtil } from 'src/app/modules/utils/classes/TextUtil.class';

export class ObjectModel3 extends ObjectModel2 {
  public __source: any = null;

  public static getInstances(currentClass: any) {
    if (!currentClass.instances) currentClass.instances = {};
    return currentClass.instances;
  }

  public static loadObjectById(
    currentClass: any,
    id: string,
    loadSequence: LoadSequence = null
  ) {
    return LoadingPromise.create<any>((resolve, reject) => {
      let obj: ObjectModel3 = ObjectModel3.getObjectById(currentClass, id);
      if (obj) resolve(obj);
      if (Array.isArray(loadSequence)) {
        // add to load sequence if there is one
        if (!loadSequence.includes(id)) loadSequence.push(id);
        resolve(null);
      } else {
        // load single object
        ObjectModel3.load3([id]).then(
          (result) => {
            resolve(result[0]);
          },
          (err) => {
            reject(err);
          }
        );
      }
    });
  }

  public static getObjectById(
    currentClass: any,
    id: string,
    loadSequence: LoadSequence = null
  ) {
    let instances: any = ObjectModel3.getInstances(currentClass);
    if (!instances[id]) return null;
    return instances[id];
  }

  public static load(
    ids: string[] = null,
    orderBy: string[] = null,
    groupBy: string[] = null,
    deleted: boolean = false,
    conditions: string = null,
    forceReload: boolean = false
  ) {
    let currentClass: any = this.name;
    return LoadSequence.create(
      currentClass,
      ids,
      orderBy,
      groupBy,
      deleted,
      conditions,
      forceReload
    ).load();
  }

  public static load3(
    currentClass: any,
    ids: string[] = null,
    orderBy: string[] = null,
    groupBy: string[] = null,
    deleted: boolean = false,
    conditions: string = null,
    forceReload: boolean = false
  ) {
    return LoadingPromise.create<any>((resolve, reject) => {
      if (typeof currentClass === 'string')
        currentClass = ClassManager.getClass(currentClass);
      let definition: ObjectDefinition = currentClass.definition;

      if (definition.trashDelete === true && deleted != null)
        conditions =
          (conditions != null ? '(' + conditions + ') AND ' : '') +
          'DeletedAt ' +
          (deleted === true ? 'IS NOT' : 'IS') +
          ' NULL';

      DataService.select({
        definition: definition,
        ids: ids,
        orderBy: orderBy,
        conditions: conditions,
      }).then(
        (result) => {
          let objs: ObjectModel3[] = [];
          for (let i = 0; i < result.details.length; ++i) {
            let obj: any = result.details[i];
            objs.push(obj);
          }
          resolve(objs);
        },
        (err) => {
          console.error(err);
        }
      );
    });
  }

  public save2(seq: number = null) {
    return this.save3();
  }

  public save3(notify: boolean = true) {
    let className: string = ClassManager.getClassName(this);
    let currentClass: any = ClassManager.getClass(className);
    return SaveSequence.create([this])
      .save()
      .then(
        (result) => {
          if (notify === true) {
            NotificationsComponent.push({
              type: 'success',
              title: 'Enregistrement effectué',
              summary:
                TextUtil.capitalizeFirst(
                  currentClass.definition.name
                    ? currentClass.definition.name[1] +
                        currentClass.definition.name[0]
                    : "l'objet"
                ) + ' a été enregistré avec succès',
            });
          }
        },
        (err) => {
          if (notify === true) {
            NotificationsComponent.push({
              type: 'error',
              title: "Erreur d'enregistrement",
              summary:
                "Erreur lors de l'enregistrement " +
                (currentClass.definition.name
                  ? currentClass.definition.name[2] +
                    currentClass.definition.name[0]
                  : "de l'objet"),
              content: JSON.stringify(err),
            });
          }
          console.error('save to db error:', err);
        }
      );
  }

  public delete() {
    let className: string = ClassManager.getClassName(this);
    let currentClass: any = ClassManager.getClass(className);
    let definition: ObjectDefinition = currentClass.definition;
    if (definition.trashDelete === true) return this.moveToTrash();
    else return DeleteSequence.create([this]).delete();
  }

  public moveToTrash() {
    return LoadingPromise.create<any>((resolve, reject) => {
      let className: string = ClassManager.getClassName(this);
      let currentClass: any = ClassManager.getClass(className);
      let instances: any = ClassManager.getInstances(currentClass);
      let definition: ObjectDefinition = currentClass.definition;
      let id_field: string = definition.database.id || 'id';
      if (definition.children) {
        for (let childName in definition.children) {
          let childDef: ObjectChildDefinition = definition.children[childName];
          let child: ObjectModel3 = this[childName];
          if (childDef.delete === true && child) child.moveToTrash();
        }
      }
      if (definition.links) {
        for (let linkName in definition.links) {
          let linkDef: ObjectChildDefinition = definition.links[linkName];
          let link: ObjectModel3[] = this[linkName];
          if (linkDef.delete === true && link && link.length > 0)
            link.forEach((val) => val.moveToTrash());
        }
      }
      let id: string = this[id_field];
      DataService.sendCommand('TRASH_DELETE', {
        table: definition.database.table,
        id_field: id_field,
        ids: [id],
      }).then(
        (result) => {
          console.log('trash delete:', result);
          if (instances) delete instances[id];
          resolve(result);
        },
        (err) => {
          console.log('trash delete:', err);
          reject(err);
        }
      );
    });
  }

  public restoreFromTrash() {
    let className: string = ClassManager.getClassName(this);
    let currentClass: any = ClassManager.getClass(className);
    let definition: ObjectDefinition = currentClass.definition;
    let id_field: string = definition.database.id || 'id';
    let id: string = this[id_field];
    return DataService.sendCommand('TRASH_RESTORE', {
      table: definition.database.table,
      id_field: id_field,
      ids: [id],
    });
  }

  public clone(cloneIds: boolean = false, cloneTo: any = null) {
    let className: string = ClassManager.getClassName(this);
    let currentClass: any = ClassManager.getClass(className);
    if (cloneTo == null) cloneTo = new currentClass();
    let obj = cloneTo;
    if (cloneIds === true) {
      obj.id = this.id;
    } else {
      obj.id = '';
    }
    obj.changed = this.changed;
    if (currentClass.definition.values) {
      for (const valueName in currentClass.definition.values) {
        obj[valueName] = this[valueName];
      }
    }
    if (currentClass.definition.children) {
      for (const childName in currentClass.definition.children) {
        let childDef: ObjectChildDefinition =
          currentClass.definition.children[childName];
        if (this[childName] == null) obj[childName] = null;
        else {
          let child = this[childName];
          if (childDef.clone === true) child = child.clone(cloneIds);
          obj[childName] = child;
          //obj.childrenIds[childName] = child.id;
        }
      }
    }
    if (currentClass.definition.links) {
      for (const linkName in currentClass.definition.links) {
        let linkDef: ObjectLinkDefinition =
          currentClass.definition.links[linkName];
        let links = this[linkName];
        if (linkDef.clone === true) {
          let arr = [];
          for (let i = 0; i < links.length; ++i)
            arr.push(links[i].clone(cloneIds));
          links = arr;
        }
        obj[linkName] = links;
        // if (links != null) {
        //     //obj.linksIds[linkName] = link.id;
        // }
      }
    }
    // console.log('cloned:', this);
    // console.log('to:', obj);
    return obj;
  }

  public static cloneArray(arr: ObjectModel3[], cloneIds: boolean = false) {
    let new_arr: ObjectModel3[] = [];
    for (let i = 0; i < arr.length; ++i) new_arr.push(arr[i].clone(cloneIds));
    return new_arr;
  }
}
