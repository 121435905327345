import { Component, ViewChild } from '@angular/core';
import { defaults, defaultsDeep, merge } from 'lodash';

import { AccessViewComponent } from '../../../../components/views/access-view.component';
import { CredentialsService } from '../../../../services/credentials/credentials.service';
import { HomepageGroup } from '../../../types';
import { Toolbar } from '../../../../components/toolbar/classes/Toolbar.class';
import { ViewsComponent } from '../../../../components/views/views.component';
import { config } from '../../../../classes/config';
import { waitForInteropNavigate } from '../../../interop/helpers';
import { windowWithInterop } from '../../../interop/types';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage-view.component.html',
  styleUrls: ['./homepage-view.component.scss'],
})
export class HomepageViewComponent extends AccessViewComponent {
  public toolbar: Toolbar = {
    class: 'toolbar-big toolbar-transparent toolbar-nocontent',
    viewTitle: 'Table des matières',
    data: this,
    elements: [{ type: 'image', src: 'assets/img/logo_white_shadow.png' }, { type: 'spacing' }],
  };
  public _routeId = '';
  public logoAlt = config.companyName;

  @ViewChild('mydiv') mydiv;

  static registredGroups: Record<string, HomepageGroup> = {};

  public static registerGroups(groups: Record<string, HomepageGroup>) {
    for (const groupKey in groups) {
      if (groups.hasOwnProperty(groupKey)) {
        const group = groups[groupKey];
        if (!HomepageViewComponent.registredGroups[groupKey]) {
          HomepageViewComponent.registredGroups[groupKey] = group;
        } else if (Array.isArray(group.children)) {
          if (!HomepageViewComponent.registredGroups[groupKey].children) {
            HomepageViewComponent.registredGroups[groupKey].children = [];
          }
          group.children.forEach((child) => {
            HomepageViewComponent.registredGroups[groupKey].children.push(child);
          });
        }
      }
    }
  }

  openView(child) {
    if (child.href) {
      waitForInteropNavigate().then(() => {
        return windowWithInterop.reactInterop.navigate(child.href);
      });
    } else {
      let component = child.component;
      let args = child.getArgs && typeof child.getArgs === 'function' ? child.getArgs() : [];
      ViewsComponent.openView(component, ...args);
    }
  }

  private _filteredHomepageGroups: any = null;

  public get filteredHomepageGroups() {
    if (!this._filteredHomepageGroups) {
      this._filteredHomepageGroups = Object.values(HomepageViewComponent.registredGroups)
        .map((group) => ({
          ...group,
          children: group.children.filter((child) => {
            return child.visible !== false && (!child.permission || CredentialsService.isUserAllowed(child.permission));
          }),
        }))
        .filter(
          (group) =>
            group.children.length > 0 &&
            group.visible !== false &&
            (!group.permission || CredentialsService.isUserAllowed(group.permission))
        );
    }

    return this._filteredHomepageGroups;
  }
}
