import { OnInit } from '@angular/core';
import { environment } from '../../environments/environment';

export class ModuleBase implements OnInit {
  ngOnInit() {}

  public get isProd() {
    return environment.production === true;
  }
}
