import { CurrenciesService } from '../../../services/currencies/currencies.service';
import { DateTimeUtil } from '../../utils/classes/DateTimeUtil.class';
import { Deadline } from '../../other-data/classes/Deadline.class';
import { InfoBlock } from '../../../components/info-block/classes/InfoBlock.class';
import { InfoBlockComponent } from '../../../components/info-block/info-block.component';
import { InfoBlockField } from '../../../components/info-block/classes/InfoBlockField.class';
import { NumberUtil } from '../../utils/classes/NumberUtil.class';
import { Order } from '../../orders/classes/Order.class';
import { Page } from '../../printing/classes/Page.class';
import { PrintableElement } from '../../printing/classes/PrintableElement.class';
import { PrintingModel } from '../../printing/classes/PrintingModel.class';
import { PrintingUtil } from '../../utils/classes/PrintingUtil.class';
// import { Price } from "../../sales/classes/Price.class";
import { Reimbursement } from './Reimbursement.class';
import { Table } from '../../printing/classes/Table.class';

export class ReimbursementPrintingModel extends PrintingModel {
  public optionsBlock: InfoBlock = {
    fields: [
      {
        title: 'Langue',
        field: 'lang',
        type: 'select',
        selectOptions: [
          { text: '(Selon client)', value: '' },
          { text: 'Français', value: 'fr' },
          { text: 'Anglais', value: 'en' },
          { text: 'Néerlandais', value: 'nl' },
        ],
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, event: any) => {
          this.regenerate();
        },
      },
    ],
  };

  public reimbursement: Reimbursement = null;
  public lang: string = '';
  // public delivery_address: string = null;
  // public delivery_address: string = null;
  // public reimbursementing_address: string = null;
  // public send_address: string = null;
  //public hide_customer: boolean = false;

  public static generate(reimbursement: Reimbursement): PrintingModel {
    let model: ReimbursementPrintingModel = new ReimbursementPrintingModel();
    model.reimbursement = reimbursement;
    return model.regenerate();
  }

  public regenerate(): PrintingModel {
    let reimbursement: Reimbursement = this.reimbursement;

    let pageWidth = 210;
    let pageHeight = 295;

    let pageLeftMargin = 10;
    let pageRightMargin = 10;
    let pageTopMargin = 10;
    let pageBottomMargin = 10;

    let order: Order = reimbursement.order;

    // if (!this.delivery_address && order.delivery_address) this.delivery_address = order.delivery_address;
    // if (!this.reimbursementing_address && order.reimbursementing_address) this.reimbursementing_address = order.reimbursementing_address;
    // if (!this.send_address && order.send_address) this.send_address = order.send_address;

    this.pages = [];

    let lang: string = this.lang;
    if (!lang || lang == '') {
      if (this.reimbursement.order.sale.customer.lang) lang = this.reimbursement.order.sale.customer.lang.code;
      else lang = 'fr';
    }

    this.saveFilename = 'Note de crédit n°' + this.reimbursement.number.replace(/\//g, '-') + '.pdf';
    this.mailFilename =
      {
        fr: 'Note de crédit n° ',
        nl: 'Creditnota nr ',
        en: 'Credit note no ',
      }[lang] +
      this.reimbursement.shortNumber.replace(/\//g, '-') +
      '.pdf';

    // for(let i=0; i<this.order.suppliers.length; ++i)
    // {

    let page: Page = {
      width: pageWidth,
      height: pageHeight,

      leftMargin: pageLeftMargin,
      rightMargin: pageRightMargin,
      topMargin: pageTopMargin,
      bottomMargin: pageBottomMargin,

      style: {
        'font-family': 'Calibri',
        'font-size': '10pt',
        width: pageWidth - pageLeftMargin - pageRightMargin + 'mm',
        height: pageHeight - pageTopMargin - pageBottomMargin + 'mm',
        'margin-top': pageTopMargin + 'mm',
        'margin-left': pageLeftMargin + 'mm',
        'margin-right': pageRightMargin + 'mm',
        'margin-bottom': pageBottomMargin + 'mm',
      },

      headerSize: 33,
      headerElements: [
        {
          style: {
            position: 'absolute',
            right: '0mm',
            top: '1mm',
            width: '85mm',
            height: '17mm',
            border: '.5mm solid rgba(0, 176, 240, 1.0)',
            'border-radius': '2.5mm',
            'line-height': '4mm',
          },
          children: [
            {
              style: {
                'font-size': '20pt',
                'font-weight': 'bold',
                'text-align': 'center',
                width: '100%',
                'line-height': '5mm',
                'margin-top': '1.5mm',
                'margin-bottom': '1mm',
                'vertical-align': 'top',
              },
              html: {
                fr: 'NOTE DE CRÉDIT',
                en: 'CREDIT NOTE',
                nl: 'CREDITNOTA',
              }[lang],
            },
            {
              style: {
                display: 'flex',
                'flex-direction': 'row',
              },
              children: [
                {
                  style: {
                    width: '20%',
                    'text-align': 'right',
                    padding: '0 1mm',
                  },
                  html: {
                    fr: 'N° :',
                    en: 'No :',
                    nl: 'Nr :',
                  }[lang],
                },
                {
                  style: {
                    width: '80%',
                    padding: '0 1mm',
                    'font-weight': 'bold',
                    color: 'rgba(255,0,0,1)',
                  },
                  html: reimbursement.number,
                },
              ],
            },
            {
              style: {
                display: 'flex',
                'flex-direction': 'row',
              },
              children: [
                {
                  style: {
                    width: '20%',
                    'text-align': 'right',
                    padding: '0 1mm',
                  },
                  html: {
                    fr: 'Date :',
                    en: 'Date :',
                    nl: 'Datum :',
                  }[lang],
                },
                {
                  style: {
                    width: '30%',
                    padding: '0 1mm',
                  },
                  html: DateTimeUtil.format(new Date(reimbursement.date), 'd/m/Y'),
                },
                {
                  style: {
                    width: '25%',
                    'text-align': 'right',
                    padding: '0 1mm',
                  },
                  html: {
                    fr: 'N° client :',
                    en: 'Cust. No :',
                    nl: 'Klant Nr :',
                  }[lang],
                },
                {
                  style: {
                    width: '25%',
                    padding: '0 1mm',
                  },
                  html: reimbursement.customer.number,
                },
              ],
            },
          ],
        },
        {
          style: {
            position: 'absolute',
            left: '0mm',
            top: '0mm',
            width: '50mm',
            height: '30mm',
            'background-image': 'url(assets/img/logo_documents.png)',
            'background-position': 'center center',
            'background-size': 'contain',
            'background-repeat': 'no-repeat',
          },
          html: '',
        },
      ],

      bodyElements: [
        {
          style: {
            position: 'absolute',
            right: '0mm',
            top: '0mm',
            width: '85mm',
            height: '25m',
            'line-height': '4mm',
          },
          children: PrintingUtil.formatAddress(
            order.customer.name,
            order.invoicing_contact,
            order.invoicing_address_text
          ),
        },

        {
          style: {
            display: 'table',
            width: '100%',
            'border-collapse': 'collapse',
            'margin-top': '30mm',
            'font-size': '11pt',
          },
          children: [
            {
              style: {
                display: 'table-row',
              },
              children: [
                {
                  style: {
                    display: 'table-cell',
                    width: '33%',
                    'background-color': 'rgba(217,217,217,1)',
                    border: '.25mm solid rgba(0,0,0,1)',
                    'text-align': 'center',
                    'font-weight': 'bold',
                    color: 'rgba(0,156,216,1.0)',
                  },
                  html: {
                    fr: 'REPRÉSENTANT',
                    en: 'AGENT',
                    nl: 'VERTEGENWOORDIGER',
                  }[lang],
                },
                {
                  style: {
                    display: 'table-cell',
                    width: '33%',
                    'background-color': 'rgba(217,217,217,1)',
                    border: '.25mm solid rgba(0,0,0,1)',
                    'text-align': 'center',
                    'font-weight': 'bold',
                    color: 'rgba(0,156,216,1.0)',
                  },
                  html: {
                    fr: 'NOTRE COMMANDE N°',
                    en: 'OUR ORDER No.',
                    nl: 'ONS BESTEL Nr.',
                  }[lang],
                },
                {
                  style: {
                    display: 'table-cell',
                    width: '33%',
                    'background-color': 'rgba(217,217,217,1)',
                    border: '.25mm solid rgba(0,0,0,1)',
                    'text-align': 'center',
                    'font-weight': 'bold',
                    color: 'rgba(0,156,216,1.0)',
                  },
                  html: {
                    fr: 'VOTRE NUMÉRO DE T.V.A.',
                    en: 'YOUR VAT NUMBER',
                    nl: 'UW B.T.W. NUMMER',
                  }[lang],
                },
              ],
            },
            {
              style: {
                display: 'table-row',
              },
              children: [
                {
                  style: {
                    display: 'table-cell',
                    width: '33%',
                    'text-align': 'center',
                    border: '.25mm solid rgba(0,0,0,1)',
                  },
                  html: reimbursement.order.merchant.name,
                },
                {
                  style: {
                    display: 'table-cell',
                    width: '33%',
                    'text-align': 'center',
                    border: '.25mm solid rgba(0,0,0,1)',
                  },
                  html: reimbursement.order.number,
                },
                {
                  style: {
                    display: 'table-cell',
                    width: '33%',
                    'text-align': 'center',
                    border: '.25mm solid rgba(0,0,0,1)',
                  },
                  html: reimbursement.order.customer.vat_number ? reimbursement.order.customer.vat_number : '',
                },
              ],
            },
          ],
        },

        {
          style: {
            display: 'table',
            width: '100%',
            'border-collapse': 'collapse',
            'margin-top': '3mm',
          },
          children: [
            {
              style: {
                display: 'table-row',
                'font-size': '11pt',
              },
              children: [
                {
                  style: {
                    display: 'table-cell',
                    // 'width': '33%',
                    'background-color': 'rgba(217,217,217,1)',
                    border: '.25mm solid rgba(0,0,0,1)',
                    'text-align': 'center',
                    'font-weight': 'bold',
                    color: 'rgba(0,156,216,1.0)',
                    'white-space': 'pre',
                    'vertical-align': 'middle',
                  },
                  html: {
                    fr: "DÉSIGNATION DE L'ARTICLE",
                    en: 'PRODUCT NAME',
                    nl: 'BESCHRIJVING',
                  }[lang],
                },
                {
                  style: {
                    display: 'table-cell',
                    width: '25mm',
                    'background-color': 'rgba(217,217,217,1)',
                    border: '.25mm solid rgba(0,0,0,1)',
                    'text-align': 'center',
                    'font-weight': 'bold',
                    color: 'rgba(0,156,216,1.0)',
                    'white-space': 'pre',
                    'vertical-align': 'middle',
                  },
                  html: {
                    fr: 'QUANTITÉ',
                    en: 'QUANTITY',
                    nl: 'HOEVEELHEID',
                  }[lang],
                },
                {
                  style: {
                    display: 'table-cell',
                    width: '25mm',
                    'background-color': 'rgba(217,217,217,1)',
                    border: '.25mm solid rgba(0,0,0,1)',
                    'text-align': 'center',
                    'font-weight': 'bold',
                    color: 'rgba(0,156,216,1.0)',
                    'white-space': 'pre',
                    'vertical-align': 'middle',
                  },
                  html: {
                    fr: 'PRIX\nUNITAIRE',
                    en: 'PRICE\nPER UNIT',
                    nl: 'EENHEIDSPRIJS',
                  }[lang],
                },
                {
                  style: {
                    display: 'table-cell',
                    width: '25mm',
                    'background-color': 'rgba(217,217,217,1)',
                    border: '.25mm solid rgba(0,0,0,1)',
                    'text-align': 'center',
                    'font-weight': 'bold',
                    color: 'rgba(0,156,216,1.0)',
                    'white-space': 'pre',
                    'vertical-align': 'middle',
                  },
                  html: {
                    fr: 'MONTANT\nH.T.V.A.',
                    en: 'TOTAL\nAMOUNT',
                    nl: 'BEDRAG\nEXCL. B.T.W.',
                  }[lang],
                },
                {
                  style: {
                    display: 'table-cell',
                    width: '25mm',
                    'background-color': 'rgba(217,217,217,1)',
                    border: '.25mm solid rgba(0,0,0,1)',
                    'text-align': 'center',
                    'font-weight': 'bold',
                    color: 'rgba(0,156,216,1.0)',
                    'white-space': 'pre',
                    'vertical-align': 'middle',
                  },
                  html: {
                    fr: 'T.V.A. #%',
                    en: 'VAT #%',
                    nl: 'B.T.W. #%',
                  }[lang].replace(
                    '#',
                    NumberUtil.formatNumber(
                      reimbursement.vat,
                      Math.floor(reimbursement.vat) == reimbursement.vat ? 0 : 1
                    )
                  ),
                },
                {
                  style: {
                    display: 'table-cell',
                    width: '25mm',
                    'background-color': 'rgba(217,217,217,1)',
                    border: '.25mm solid rgba(0,0,0,1)',
                    position: 'relative',
                    'text-align': 'center',
                    'vertical-align': 'middle',
                    color: 'rgba(0,156,216,1.0)',
                    'font-weight': 'bold',
                  },
                  html: {
                    fr: 'À PAYER',
                    en: 'PAYABLE',
                    nl: 'TE BETALEN',
                  }[lang],
                },
              ],
            },
            {
              style: {
                display: 'table-row',
              },
              children: [
                {
                  style: {
                    display: 'table-cell',
                    // 'width': '33%',
                    'text-align': 'left',
                    border: '.25mm solid rgba(0,0,0,1)',
                    'vertical-align': 'top',
                    height: '50mm',
                    padding: '0 1mm',
                  },
                  html: reimbursement.designation || '',
                },
                {
                  style: {
                    display: 'table-cell',
                    width: '25mm',
                    'text-align': 'center',
                    border: '.25mm solid rgba(0,0,0,1)',
                    'vertical-align': 'top',
                    height: '50mm',
                    padding: '0 1mm',
                  },
                  html: reimbursement.quantityAndUnit,
                },
                {
                  style: {
                    display: 'table-cell',
                    width: '25mm',
                    'text-align': 'right',
                    border: '.25mm solid rgba(0,0,0,1)',
                    'vertical-align': 'top',
                    height: '50mm',
                    padding: '0 1mm',
                    color: 'rgba(255,0,0,1)',
                  },
                  html: NumberUtil.formatMoney(
                    reimbursement.converted_sell_price,
                    reimbursement.sell_currency ? reimbursement.sell_currency.symbol : '€',
                    5
                  ),
                },
                {
                  style: {
                    display: 'table-cell',
                    width: '25mm',
                    'text-align': 'right',
                    border: '.25mm solid rgba(0,0,0,1)',
                    'vertical-align': 'top',
                    height: '50mm',
                    padding: '0 1mm',
                    color: 'rgba(255,0,0,1)',
                  },
                  html: NumberUtil.formatMoney(
                    reimbursement.converted_totalAmount,
                    reimbursement.sell_currency ? reimbursement.sell_currency.symbol : '€',
                    2
                  ),
                },
                {
                  style: {
                    display: 'table-cell',
                    width: '25mm',
                    'text-align': 'right',
                    border: '.25mm solid rgba(0,0,0,1)',
                    'vertical-align': 'top',
                    height: '50mm',
                    padding: '0 1mm',
                    color: 'rgba(255,0,0,1)',
                  },
                  html: NumberUtil.formatMoney(
                    reimbursement.converted_vatAmount,
                    reimbursement.sell_currency ? reimbursement.sell_currency.symbol : '€',
                    2
                  ),
                },
                {
                  style: {
                    display: 'table-cell',
                    width: '25mm',
                    'text-align': 'right',
                    border: '.25mm solid rgba(0,0,0,1)',
                    'vertical-align': 'top',
                    height: '50mm',
                    padding: '0 1mm',
                    color: 'rgba(255,0,0,1)',
                  },
                  html: NumberUtil.formatMoney(
                    reimbursement.converted_totalWithTax,
                    reimbursement.sell_currency ? reimbursement.sell_currency.symbol : '€',
                    2
                  ),
                },
              ],
            },
            {
              style: {
                display: 'table-row',
                'font-size': '11pt',
              },
              children: [
                {
                  style: {
                    display: 'table-cell',
                    border: 'none',
                  },
                  html: '',
                },
                {
                  style: {
                    display: 'table-cell',
                    width: '25mm',
                    border: 'none',
                  },
                  html: '',
                },
                {
                  style: {
                    display: 'table-cell',
                    width: '25mm',
                    // 'text-align': 'center',
                    border: '.25mm solid rgba(0,0,0,1)',
                    'background-color': 'rgba(217,217,217,1)',
                    'text-align': 'right',
                    'font-weight': 'bold',
                    color: 'rgba(0,156,216,1.0)',
                    padding: '0 1mm',
                  },
                  html: {
                    fr: 'TOTAUX :',
                    en: 'TOTAL :',
                    nl: 'TOTAAL :',
                  }[lang],
                },
                {
                  style: {
                    display: 'table-cell',
                    width: '25mm',
                    'text-align': 'right',
                    border: '.25mm solid rgba(0,0,0,1)',
                    padding: '0 1mm',
                    color: 'rgba(255,0,0,1)',
                  },
                  html: NumberUtil.formatMoney(
                    reimbursement.converted_totalAmount,
                    reimbursement.sell_currency ? reimbursement.sell_currency.symbol : '€',
                    2
                  ),
                },
                {
                  style: {
                    display: 'table-cell',
                    width: '25mm',
                    'text-align': 'right',
                    border: '.25mm solid rgba(0,0,0,1)',
                    padding: '0 1mm',
                    color: 'rgba(255,0,0,1)',
                  },
                  html: NumberUtil.formatMoney(
                    reimbursement.converted_vatAmount,
                    reimbursement.sell_currency ? reimbursement.sell_currency.symbol : '€',
                    2
                  ),
                },
                {
                  style: {
                    display: 'table-cell',
                    width: '25mm',
                    'text-align': 'right',
                    border: '.25mm solid rgba(0,0,0,1)',
                    padding: '0 1mm',
                    color: 'rgba(255,0,0,1)',
                  },
                  html: NumberUtil.formatMoney(
                    reimbursement.converted_totalWithTax,
                    reimbursement.sell_currency ? reimbursement.sell_currency.symbol : '€',
                    2
                  ),
                },
              ],
            },
          ],
        },

        {
          style: {
            clear: 'both',
          },
        },

        {
          style: {
            display: 'table',
            width: '100%',
            'border-collapse': 'collapse',
            'margin-top': '3mm',
          },
          children: [
            {
              style: {
                display: 'table-row',
              },
              children: [
                {
                  style: {
                    display: 'table-cell',
                    border: '.25mm solid rgba(0,0,0,1)',
                    'text-align': 'center',
                    'vertical-align': 'middle',
                    color: 'rgba(0,156,216,1.0)',
                    'font-weight': 'bold',
                    'font-size': '11pt',
                    'background-color': 'rgba(217,217,217,1)',
                  },
                  html: {
                    fr: 'CERTIFICATION',
                    en: 'CERTIFICATION',
                    nl: 'CERTIFICATIE',
                  }[lang],
                },
              ],
            },
            {
              style: {
                display: 'table-row',
              },
              children: [
                {
                  style: {
                    display: 'table-cell',
                    border: '.25mm solid rgba(0,0,0,1)',
                    width: '100%',
                    padding: '.5mm 1mm',
                    'white-space': 'pre-wrap',
                    'line-height': '10mm',
                  },
                  html:
                    {
                      fr: 'Certifié sincère et véritable à la somme de moins ',
                      en: 'Certified sincere and genuine for the amount of minus ',
                      nl: 'Getrouw en juist verklaard voor een bedrag van min ',
                    }[lang] +
                    NumberUtil.moneyToWords(
                      Math.abs(reimbursement.totalWithTax),
                      lang,
                      reimbursement.sell_currency || CurrenciesService.defaultCurrency
                    ) +
                    '.',
                },
              ],
            },
          ],
        },

        {
          style: {
            display: 'block',
            width: '100%',
            border: '.25mm solid rgba(0,0,0,1)',
            'text-align': 'center',
            'vertical-align': 'middle',
            color: 'rgba(0,156,216,1.0)',
            'font-weight': 'bold',
            'font-size': '11pt',
            'background-color': 'rgba(217,217,217,1)',
            'margin-top': '3mm',
          },
          html: {
            fr: 'REMARQUE(S) IMPORTANTE(S)',
            en: 'IMPORTANT NOTE(S)',
            nl: 'BELANGERIJKE OPMERKING(EN)',
          }[lang],
        },
        {
          style: {
            display: 'block',
            flex: '1',
            'border-right': '.25mm solid rgba(0,0,0,1)',
            'border-left': '.25mm solid rgba(0,0,0,1)',
            'border-bottom': '.25mm solid rgba(0,0,0,1)',
            width: '100%',
            padding: '.5mm 1mm',
            'line-height': '5mm',
            'white-space': 'pre-wrap',
            'font-weight': 'bold',
            'vertical-align': 'top',
          },
          children: PrintingUtil.linesToDivs(
            {
              fr: "CLAUSE DE RÉSERVE DE PROPRIÉTÉ :\nNous conservons l'entière propriété des marchandises vendues jusqu'à complet paiement de son prix.\nN° de la facture originale : #\n",
              en: 'RETENTION OF TITLE CLAUSE :\nWe retain full ownership of the goods sold until full payment.\nOriginal invoice no. : #\n',
              nl: 'CLAUSULE VAN EIGENDOMSVOORBEHOUD :\nDe goederen blijven onze eigendom tot het volledig betalen van het verschuldigde factuurbedrag.\nFactuur nr. : #\n',
            }[lang].replace('#', reimbursement.invoice.number)
          ).concat(PrintingUtil.linesToDivs(reimbursement.remarks || '', { color: 'rgba(255,0,0,1)' })),
        },
      ],

      footerSize: 63,
      footerElements: [
        {
          style: {
            display: 'table',
            width: '100%',
            'border-collapse': 'collapse',
            'margin-top': '3mm',
          },
          children: [
            {
              style: {
                display: 'table-row',
              },
              children: [
                {
                  style: {
                    display: 'table-cell',
                    border: '.25mm solid rgba(0,0,0,1)',
                    'background-color': 'rgba(217, 217, 217, 1.0)',
                    width: '40%',
                    padding: '.5mm 1mm',
                    'text-align': 'center',
                    'line-height': '5mm',
                    'font-weight': 'bold',
                    color: 'rgba(0,156,216,1.0)',
                  },
                  html: {
                    fr: 'ADRESSE DE LIVRAISON',
                    en: 'DELIVERY ADDRESS',
                    nl: 'LEVERINGS ADRES',
                  }[lang],
                },
                {
                  style: {
                    display: 'table-cell',
                    border: '.25mm solid rgba(0,0,0,1)',
                    'background-color': 'rgba(217, 217, 217, 1.0)',
                    width: '40%',
                    padding: '.5mm 1mm',
                    'text-align': 'center',
                    'line-height': '5mm',
                    'font-weight': 'bold',
                    color: 'rgba(0,156,216,1.0)',
                  },
                  html: {
                    fr: 'RÉFÉRENCE DE LA COMMANDE CLIENT',
                    en: 'CUSTOMER ORDER REFERENCE',
                    nl: 'UW KENMERK',
                  }[lang],
                },
                {
                  style: {
                    display: 'table-cell',
                    border: '.25mm solid rgba(0,0,0,1)',
                    'background-color': 'rgba(217, 217, 217, 1.0)',
                    width: '20%',
                    padding: '.5mm 1mm',
                    'text-align': 'center',
                    'line-height': '5mm',
                    'font-weight': 'bold',
                    color: 'rgba(0,156,216,1.0)',
                  },
                  html: {
                    fr: 'ÉCHÉANCE',
                    en: 'PAYMENT TERMS',
                    nl: 'BETALINGSTERMIJN',
                  }[lang],
                },
              ],
            },
            {
              style: {
                display: 'table-row',
              },
              children: [
                {
                  style: {
                    display: 'table-cell',
                    border: '.25mm solid rgba(0,0,0,1)',
                    padding: '.5mm 1mm',
                    'text-align': 'center',
                    'line-height': '4mm',
                    'white-space': 'pre',
                    height: '30mm',
                    // 'position': 'absolute',
                    // 'left': '0',
                    // 'top': '8.5mm',
                    // 'width': '76.25mm',
                    'vertical-align': 'top',
                  },
                  children: PrintingUtil.formatAddress(
                    order.customer.name,
                    order.delivery_contact,
                    order.delivery_address_text,
                    null,
                    order.openingHours
                  ),
                },
                {
                  style: {
                    display: 'table-cell',
                    border: 'none',
                    width: '40%',
                    // 'padding': '.5mm 1mm',
                    padding: '0',
                    'text-align': 'center',
                    'line-height': '5mm',
                    'white-space': 'pre',
                  },
                  children: [
                    {
                      style: {
                        display: 'block',
                        height: '6mm',
                        'line-height': '6mm',
                        width: '100%',
                        'text-align': 'center',
                      },
                      html: reimbursement.order.customer_reference,
                    },
                    {
                      style: {
                        display: 'block',
                        height: '6mm',
                        'line-height': '6mm',
                        width: '100%',
                        'background-color': 'rgba(217, 217, 217, 1.0)',
                        'text-align': 'center',
                        'font-weight': 'bold',
                        color: 'rgba(0,156,216,1.0)',
                        'border-top': '.25mm solid rgba(0,0,0,1)',
                        'border-bottom': '.25mm solid rgba(0,0,0,1)',
                      },
                      html: {
                        fr: 'NUMÉRO DU BON DE LIVRAISON',
                        en: 'DELIVERY NUMBER',
                        nl: 'NUMMER VAN DE LEVERINGSBON',
                      }[lang],
                    },
                    {
                      style: {
                        display: 'block',
                        height: '6mm',
                        'line-height': '6mm',
                        width: '100%',
                        'text-align': 'center',
                      },
                      html: order.delivery_number,
                    },
                    {
                      style: {
                        display: 'block',
                        height: '6mm',
                        'line-height': '6mm',
                        width: '100%',
                        'background-color': 'rgba(217, 217, 217, 1.0)',
                        'text-align': 'center',
                        'font-weight': 'bold',
                        color: 'rgba(0,156,216,1.0)',
                        'border-top': '.25mm solid rgba(0,0,0,1)',
                        'border-bottom': '.25mm solid rgba(0,0,0,1)',
                      },
                      html: {
                        fr: 'DÉLAI DE LIVRAISON',
                        nl: 'DELIVERY TIME',
                        en: 'LEVERINGSTERMIJN',
                      }[lang],
                    },
                    {
                      style: {
                        display: 'block',
                        height: '6mm',
                        'line-height': '6mm',
                        width: '100%',
                        'text-align': 'center',
                        'border-bottom': '.25mm solid rgba(0,0,0,1)',
                      },
                      html: '',
                    },
                  ],
                },
                {
                  style: {
                    display: 'table-cell',
                    border: '.25mm solid rgba(0,0,0,1)',
                    width: '20%',
                    padding: '.5mm 1mm',
                    'text-align': 'center',
                    'line-height': '5mm',
                    'white-space': 'pre',
                    height: '30mm',
                    // 'position': 'absolute',
                    // 'right': '0',
                    // 'top': '8.5mm',
                    'vertical-align': 'top',
                  },
                  children: [
                    {
                      style: {
                        'font-size': '11pt',
                        'font-weight': 'bold',
                        'text-align': 'center',
                      },
                      html: reimbursement.deadline,
                    },
                    {
                      style: {
                        'text-align': 'center',
                        'white-space': 'pre',
                        'margin-top': '7mm',
                      },
                      html: {
                        fr: 'Paiement ultime le :',
                        en: 'The ultimate payment :',
                        nl: 'Te betalen uiterlijk voor :',
                      }[lang],
                    },
                    {
                      style: {
                        'font-size': '16pt',
                        'font-weight': 'bold',
                        'text-align': 'center',
                        'margin-top': '3mm',
                      },
                      html: this.reimbursement.order.deadline
                        ? DateTimeUtil.format(
                            Deadline.getFinalDate(this.reimbursement.deadline, new Date(reimbursement.date)),
                            'd/m/Y'
                          )
                        : '',
                    },
                  ],
                },
              ],
            },
          ],
        },

        PrintingUtil.getFooter(this.reimbursement.accounting, lang),
      ],
    };

    this.pages.push(page);

    //}
    return this;
  }
}
