import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';

import { User } from 'src/app/classes/credentials/User.class';
import { AccountingsService } from 'src/app/services/accountings/accountings.service';
import { windowWithInterop } from '../../modules/interop/types';
import { CredentialsService } from '../../services/credentials/credentials.service';
import { DialogButton } from '../dialogs/classes/DialogButton.class';
import { DialogsComponent } from '../dialogs/dialogs.component';
import { ViewsComponent } from '../views/views.component';
import { Toolbar } from './classes/Toolbar.class';
import { ToolbarMenuItem } from './classes/ToolbarMenuItem.class';

type MobileStatus = 'mobile' | 'desktop' | undefined;

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css'],
})
export class ToolbarComponent implements AfterViewInit {
  @Input() toolbar: Toolbar = null;

  interopId = Math.random() * 1000000;
  @ViewChild('interopToolbar') interopToolbar: ElementRef;
  @ViewChild('interopMobileToolbar') interopMobileToolbar: ElementRef;

  mobileStatus: MobileStatus = window.innerWidth < 768 ? 'mobile' : 'desktop';
  get isDesktop() {
    return this.mobileStatus == 'desktop';
  }
  get isMobile() {
    return this.mobileStatus == 'mobile';
  }

  @HostListener('window:resize', [])
  private onResize() {
    const prevStatus = this.mobileStatus;
    if (window.innerWidth < 768 && this.mobileStatus != 'mobile') {
      this.mobileStatus = 'mobile';
    } else if (window.innerWidth >= 768 && this.mobileStatus != 'desktop') {
      this.mobileStatus = 'desktop';
    }
    if (prevStatus != this.mobileStatus) {
      requestAnimationFrame(() => {
        this.injectInteropToolbar();
      });
    }
  }

  async injectInteropToolbar() {
    if (this.toolbar && this.toolbar.interopComponentName) {
      await this.waitForReactInterop();
      windowWithInterop.reactInterop.destroyComponent(
        `interop-toolbar-${this.interopId}`
      );
      windowWithInterop.reactInterop.injectComponent(
        `interop-toolbar-${this.interopId}`,
        this.interopToolbar.nativeElement,
        this.toolbar.interopComponentName,
        {
          onBackButtonClicked: () => this.prevPage(),
          ...(this.toolbar.interopComponentProps || {}),
        }
      );
      windowWithInterop.reactInterop.destroyComponent(
        `interop-mobile-toolbar-${this.interopId}`
      );
      if (this.isMobile) {
        windowWithInterop.reactInterop.injectComponent(
          `interop-mobile-toolbar-${this.interopId}`,
          this.interopMobileToolbar.nativeElement,
          this.toolbar.interopComponentName,
          {
            onBackButtonClicked: () => this.prevPage(),
            onlyStatic: true,
            ...(this.toolbar.interopComponentProps || {}),
          }
        );
      }
    }
  }

  async ngAfterViewInit() {
    await this.injectInteropToolbar();
  }

  ngOnDestroy() {
    if (this.toolbar && this.toolbar.interopComponentName) {
      windowWithInterop.reactInterop.destroyComponent(
        `interop-toolbar-${this.interopId}`
      );
    }
  }

  waitForReactInterop() {
    return new Promise<boolean>((resolve) => {
      var interval = setInterval(() => {
        if (
          windowWithInterop.reactInterop &&
          typeof windowWithInterop.reactInterop.injectComponent === 'function'
        ) {
          clearInterval(interval);
          resolve(true);
        }
      }, 500);
    });
  }

  prevPage() {
    if (this.toolbar.onPrevPage) {
      if (!this.toolbar.onPrevPage()) return false;
    }
    ViewsComponent.closeView();
  }

  get elementKeys() {
    if (!this.toolbar || !this.toolbar.elements) return null;
    return Object.keys(this.toolbar.elements);
  }

  // get visibleElements()
  // {
  //     return this.toolbar.elements.filter((value, index, arr) => {
  //         return !(value.visible != true);
  //     });
  // }

  // get buttonDisabled(element)
  // {
  //     if (!element.disabled) return false;
  //     else if (typeof(element.disabled) === 'function') return element.disabled(this);
  //     else return element.disabled;
  // }

  public menuItemClick(event: any, item: ToolbarMenuItem) {
    event.stopPropagation();
    this.toolbar.showMenu = false;
    if (item.viewComponent) {
      ViewsComponent.openView(item.viewComponent);
    } else if (item.click) {
      item.click(event);
    }
  }

  get menuItems() {
    return Toolbar._menuItems;
  }

  /* ACCOUNTINGS */

  get currentAccounting() {
    return AccountingsService.currentAccounting;
  }

  get accountingsItems() {
    return AccountingsService.otherAccountings;
  }

  public accountingsItemClick($event, item) {
    AccountingsService.setCurrentAccounting(item);
    this.toolbar.showAccountMenu = false;
  }

  /* ACCOUNT */

  get loggedUserName() {
    let user: User = CredentialsService.loggedUser;
    if (!user) return '(Non connecté)';
    else return user.name;
  }

  public reload() {
    window.location.reload();
  }

  public logout() {
    DialogsComponent.display({
      title: 'Se déconnecter ?',
      icon: 'question',
      message:
        'Êtes-vous sûr(e) de vouloir vous déconnecter ?<br/>Toute modification non enregistrée sera perdue !',
      buttons: DialogButton.yesNoButtons,
    }).then((result) => {
      if (result == DialogButton.RESULT_YES) {
        window.location.href = '/logout.php';
      }
    });
  }
}
