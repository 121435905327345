import { BsModalRef, BsModalService } from 'ngx-bootstrap';

import { Country } from '../../other-data/classes/Country.class';
import { ObjectDefinition } from '../../../classes/objects/ObjectDefinition.class';
import { ObjectModel3 } from 'src/app/classes/objects/ObjectModel3.class';

export class Address extends ObjectModel3 {
  public title: string = null;
  public name1: string = null;
  public name2: string = null;
  public address1: string = null;
  public address2: string = null;
  public postcode: string = null;
  public city: string = null;
  public state: string = null;

  public country: Country = null;

  public phone: string = null;
  public fax: string = null;
  public mobile: string = null;
  public email: string = null;
  public vat: string = null;
  public opening_hours: string = null;

  public accounting_email: string = null;
  public invoicing_email: string = null;

  public invoicing_by_mail: boolean = true;
  public invoicing_by_paper: boolean = true;

  public remarks: string = null;

  public DeletedAt: string = null;

  private static _defaultCountry: Country = null;

  bsModalRef: BsModalRef;
  constructor(private modalService: BsModalService = null) {
    super(Address);
    // if (!Address._defaultCountry)
    // {
    //     Country.load(null, null, null, false, "`name` = 'Belgique'").then(
    //         (result) => {
    //             if (result.length > 0) Address._defaultCountry = result[0];
    //             if (this.country == null) setTimeout(() => { this.country = Address._defaultCountry; }, 0);
    //         }
    //     );
    // }
    // else this.country = Address._defaultCountry;
  }

  /* ObjectModel FUNCTIONS */

  public static instances: { [id: number]: Address } = {};
  public static loaded_ids: number[] = [];

  public static definition: ObjectDefinition = {
    database: {
      table: 'addresses',
      id: 'id',
    },
    values: {
      title: { type: 'string' },
      name1: { type: 'string' },
      name2: { type: 'string' },
      address1: { type: 'string' },
      address2: { type: 'string' },
      postcode: { type: 'string' },
      city: { type: 'string' },
      state: { type: 'string' },
      phone: { type: 'string' },
      fax: { type: 'string' },
      mobile: { type: 'string' },
      email: { type: 'string' },
      vat: { type: 'string' },
      opening_hours: { type: 'string' },
      accounting_email: { type: 'string' },
      invoicing_email: { type: 'string' },
      invoicing_by_mail: { type: 'number' },
      invoicing_by_paper: { type: 'number' },
      remarks: { type: 'string' },
      DeletedAt: { type: 'string' },
    },
    children: {
      country: { type: 'Country', clone: false, save: false, delete: false },
    },
    links: {},
  };

  public generateText(
    showName: boolean = true,
    showAddress: boolean = true,
    showPostCodeAndCity: boolean = true,
    showCountry: boolean = true,
    separator = '\n'
  ) {
    let arr = [];
    if (showName === true) {
      if (this.name1) arr.push(this.name1);
      if (this.name2) arr.push(this.name2);
    }
    if (showAddress === true) {
      if (this.address1) arr.push(this.address1);
      if (this.address2) arr.push(this.address2);
    }
    if (showPostCodeAndCity === true) {
      let line_arr = [];
      if (this.postcode) line_arr.push(this.postcode);
      if (this.city) line_arr.push(this.city);
      if (line_arr.length > 0) arr.push(line_arr.join(' '));
    }
    if (showCountry === true) {
      if (this.country) arr.push(this.country.name);
    }
    return arr.join(separator);
  }

  public get fullAddress() {
    return this.generateText(true, true, true, false);
  }

  public get fullAddressWithCountry() {
    return this.generateText(true, true, true, true);
  }

  public getFullAddress(
    showCountry: boolean = true,
    separator?: string
  ): string {
    return this.generateText(true, true, true, showCountry, separator);
  }

  public getPostalAddress(separator?: string) {
    return this.generateText(
      false,
      true,
      true,
      !(this.country && this.country.name === 'Belgique'),
      separator
    );
  }

  public get oneLineText() {
    return (
      (this.title ? this.title + ' : ' : '') +
      this.generateText(
        true,
        true,
        true,
        !(this.country && this.country.name === 'Belgique'),
        ' - '
      )
    );
  }

  public get contactName() {
    let arr: string[] = [];
    if (this.name2) arr.push(this.name2);
    if (this.name1) arr.push(this.name1);
    return arr.join(' - ');
  }
  public get addressContactLine() {
    if (!this.name1 && !this.name2) return null;
    else if (!this.name2) return "A l'attention de " + this.name1;
    else if (!this.name1) return this.name2;
    else return this.contactName;
  }

  public getMailsArray(mails = null) {
    if (!mails) mails = this.email;
    return !mails ? [] : mails.split(/\;\s*/g);
  }
  public getInvoicingMailsArray() {
    return this.getMailsArray(this.invoicing_email);
  }
  public getAccountingMailsArray() {
    return this.getMailsArray(this.accounting_email);
  }
}
