import { Accounting } from '../../other-data/classes/Accounting.class';
import { ApiService } from '../../../services/api/api.service';
import { CredentialsService } from '../../../services/credentials/credentials.service';
import { CurrenciesService } from 'src/app/services/currencies/currencies.service';
import { DateTimeUtil } from '../../utils/classes/DateTimeUtil.class';
import { Deadline } from '../../other-data/classes/Deadline.class';
import { Merchant } from '../../other-data/classes/Merchant.class';
import { NumberUtil } from '../../utils/classes/NumberUtil.class';
import { ObjectDefinition } from 'src/app/classes/objects/ObjectDefinition.class';
import { ObjectModel3 } from 'src/app/classes/objects/ObjectModel3.class';
import { Order } from '../../orders/classes/Order.class';
import { PaymentStatus } from '../../payments/classes/PaymentStatus.class';
import { Reimbursement } from './Reimbursement.class';
import { ServerApi } from 'src/app/classes/api/ServerApi.class';
import { StockMove } from '../../stocks/classes/StockMove.class';
import { User } from 'src/app/classes/credentials/User.class';

export class Invoice extends ObjectModel3 {
  public accounting: Accounting = null;

  public proforma: number = 0;

  public date: string = null;
  public number: string = null;
  public get is_logistic() {
    return this.order && this.order.deliveries > 1;
  }

  public merchant: Merchant = null;
  public order: Order = null;
  public move: StockMove = null;
  public reimbursements: Reimbursement[] = [];

  public deadline: string = null;
  public delivery_time: string = null;
  public discount: number = null;
  public discount_days: number = null;

  public quantity: number = null;
  public unit: string = null;
  public sell_price: number = null;
  public vat: number = 21;

  public delivery_number: string = null;
  public delivery: string = null;
  public delivery_date: string = null;

  public remarks: string = null;
  public get offer_remarks() {
    return this.order && this.order.sale ? this.order.sale.offer_remarks : null;
  }
  public get demand_remarks() {
    return this.order && this.order.sale ? this.order.sale.demand_remarks : null;
  }

  public user: User = null;

  public payment_date: string = null;
  public payment_amount: number = null;
  public payment_status: PaymentStatus = null;
  public payment_remarks: string = null;
  public payment_archived: number = 0;

  public get customer() {
    return this.order ? this.order.customer : null;
  }
  public get supplier() {
    return this.order ? this.order.supplier : null;
  }
  public get article() {
    return this.order ? this.order.article : null;
  }

  public get delivery_address_text() {
    return this.move ? this.move.delivery_address_text : this.order ? this.order.delivery_address_text : null;
  }
  public get delivery_contact() {
    return this.move ? this.move.delivery_contact : this.order ? this.order.delivery_contact : null;
  }
  public get customer_reference() {
    return this.move ? this.move.customer_reference : this.order ? this.order.customer_reference : null;
  }

  public get daysSince() {
    return DateTimeUtil.daysBetween(this.date, new Date());
  } // TODO
  public get daysDue() {
    return DateTimeUtil.daysBetween(new Date(), this.expiration);
  }
  public get expiration() {
    return DateTimeUtil.toDateString(Deadline.getFinalDate(this.deadline, new Date(this.date)));
  }

  //public get payment_result() { return this.payment && this.payment.amount ? (this.payment.amount - this.totalWithTax) : null; };

  public get reimbursements_totalAmount() {
    let total: number = 0;
    if (Array.isArray(this.reimbursements)) {
      for (let i = 0; i < this.reimbursements.length; ++i) total += this.reimbursements[i].totalAmount;
    }
    return total;
  }
  public get reimbursements_vatAmount() {
    let total: number = 0;
    if (Array.isArray(this.reimbursements)) {
      for (let i = 0; i < this.reimbursements.length; ++i) total += this.reimbursements[i].vatAmount;
    }
    return total;
  }
  public get reimbursements_totalWithTax() {
    let total: number = 0;
    if (Array.isArray(this.reimbursements)) {
      for (let i = 0; i < this.reimbursements.length; ++i) total += this.reimbursements[i].totalWithTax;
    }
    return total;
  }
  public get reimbursements_quantity() {
    let total: number = 0;
    if (Array.isArray(this.reimbursements)) {
      for (let i = 0; i < this.reimbursements.length; ++i) total += this.reimbursements[i].quantity;
    }
    return total;
  }

  public get converted_reimbursements_totalAmount() {
    let total: number = 0;
    if (Array.isArray(this.reimbursements)) {
      for (let i = 0; i < this.reimbursements.length; ++i) total += this.reimbursements[i].converted_totalAmount;
    }
    return total;
  }
  public get converted_reimbursements_vatAmount() {
    let total: number = 0;
    if (Array.isArray(this.reimbursements)) {
      for (let i = 0; i < this.reimbursements.length; ++i) total += this.reimbursements[i].converted_vatAmount;
    }
    return total;
  }
  public get converted_reimbursements_totalWithTax() {
    let total: number = 0;
    if (Array.isArray(this.reimbursements)) {
      for (let i = 0; i < this.reimbursements.length; ++i) total += this.reimbursements[i].converted_totalWithTax;
    }
    return total;
  }

  public get has_supplier_payment() {
    return Invoice._supplierPayments.indexOf(this.id) >= 0 ? 'OK' : '';
  }

  private static _supplierPayments: string[] = [];

  public static loadSupplierPayments() {
    ApiService.callModule('invoices', 'supplierPayments', {}).then(
      (result) => {
        Invoice._supplierPayments = result.details;
      },
      (err) => {
        console.error(err);
        Invoice._supplierPayments = null;
      }
    );
  }

  public get isCancelled(): boolean {
    return this.payment_status && this.payment_status.isCancelled;
  }

  constructor() {
    super(Invoice);
    this.user = CredentialsService.loggedUser;
  }

  public getNumberPrefix() {
    return (
      (this.proforma ? '14/' : '12/') +
      ('00' + (new Date().getFullYear() % 100)).slice(-2) +
      (this.order.deliveries > 1 ? '1' : '0') +
      (this.accounting ? this.accounting.number : '0')
    );
  }
  public getNextNumber() {
    return ServerApi.callModule('invoices', 'nextNumber', {
      number_prefix: this.getNumberPrefix(),
    });
  }
  public generateNumber(number: string) {
    return (
      this.getNumberPrefix() +
      ' ' +
      ('0000' + number).slice(-4) +
      '/' +
      (this.order.customer ? this.order.customer.number : 'BEL0000') +
      '/' +
      (this.order.sale.article && this.order.sale.article.nomenclature && this.order.sale.article.nomenclature.name
        ? this.order.sale.article.nomenclature.name.replace(/[^A-Za-z0-9]/g, '').substr(0, 3)
        : '000')
    );
  }
  public get shortNumber() {
    return (this.number || '').replace(/(\/[A-Za-z]{2,}[0-9]{4,}\/[0-9A-Za-z]{3}(\-[0-9]+)?)$/, '');
  }

  public get quantityAndUnit() {
    let arr: string[] = [];
    if (this.quantity != null) arr.push(NumberUtil.formatNumber(this.quantity, null, '.'));
    if (this.unit != null) arr.push(this.unit);
    return arr.join(' ');
  }
  public get totalAmount() {
    return (this.quantity || 0) * (this.sell_price || 0);
  }
  public get totalAmountWithDiscount() {
    if (this.discount > 0 && this.discount_days > 0) return (this.totalAmount * (100 - this.discount)) / 100;
    else return this.totalAmount;
  }
  public get vatAmount() {
    if (this.quantity != null && this.sell_price != null)
      return this.quantity * this.sell_price * ((this.vat == null ? 21 : this.vat) / 100);
    else return 0;
  }
  public get vatAmountWithDiscount() {
    if (this.discount > 0 && this.discount_days > 0)
      return this.totalAmountWithDiscount * ((this.vat == null ? 21 : this.vat) / 100);
    else return this.vatAmount;
  }
  public get totalWithTax() {
    return this.totalAmount + this.vatAmount;
  }
  public get totalWithTaxWithTaxDiscount() {
    return this.totalAmount + this.vatAmountWithDiscount;
  }
  public get totalWithTaxWithFullDiscount() {
    return this.totalAmountWithDiscount + this.vatAmountWithDiscount;
  }

  public get payment_result(): number {
    return this.payment_status && this.payment_status.ended == 1
      ? this.payment_amount - this.totalWithTaxWithTaxDiscount
      : 0;
  }
  public get converted_payment_amount() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.payment_amount);
  }
  public set converted_payment_amount(value: number) {
    this.payment_amount = CurrenciesService.currencyToEuro(this.sell_xrate || 1, value);
  }
  public get converted_payment_result() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.payment_result);
  }

  public get converted_totalAmount() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.totalAmount);
  }
  public get converted_vatAmount() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.vatAmount);
  }
  public get converted_totalWithTax() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.totalWithTax);
  }

  public get converted_totalAmountWithDiscount() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.totalAmountWithDiscount);
  }
  public get converted_vatAmountWithDiscount() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.vatAmountWithDiscount);
  }
  public get converted_totalWithTaxWithTaxDiscount() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.totalWithTaxWithTaxDiscount);
  }
  public get converted_totalWithTaxWithFullDiscount() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.totalWithTaxWithFullDiscount);
  }

  public get buy_price() {
    return this.move ? this.move.buy_price : this.order ? this.order.buy_price : null;
  }
  public get unitBuyPrice() {
    return this.move ? this.move.unitBuyPrice : this.order ? this.order.unitBuyPrice : null;
  }
  public get totalBuyPrice() {
    return this.move ? this.move.totalBuyAmount : this.unitBuyPrice ? this.quantity * this.unitBuyPrice : null;
  }
  public get unitCostPrice() {
    return this.move ? this.move.unitCostPrice : this.order ? this.order.unitCostPrice : null;
  }
  public get totalCostPrice() {
    return this.move ? this.move.totalCostPrice : this.unitBuyPrice ? this.quantity * this.unitCostPrice : null;
  }
  public get converted_sell_price() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.sell_price);
  }
  public get totalSellPrice() {
    return this.move ? this.move.totalAmount : this.totalAmount;
  }
  public get internal_fees() {
    return this.move ? this.move.internal_fees : this.order ? this.order.internal_fees : null;
  }
  public get external_fees() {
    return this.move ? this.move.external_fees : this.order ? this.order.external_fees : null;
  }

  public get converted_buy_price() {
    return CurrenciesService.euroToCurrency(this.buy_xrate || 1, this.buy_price);
  }
  public get converted_unitBuyPrice() {
    return CurrenciesService.euroToCurrency(this.buy_xrate || 1, this.unitBuyPrice);
  }
  public get converted_totalBuyPrice() {
    return CurrenciesService.euroToCurrency(this.buy_xrate || 1, this.totalBuyPrice);
  }
  public get converted_unitCostPrice() {
    return CurrenciesService.euroToCurrency(this.buy_xrate || 1, this.unitCostPrice);
  }
  public get converted_totalCostPrice() {
    return CurrenciesService.euroToCurrency(this.buy_xrate || 1, this.totalCostPrice);
  }
  public get converted_totalSellPrice() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.totalSellPrice);
  }
  public get converted_internal_fees() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.internal_fees);
  }
  public get converted_external_fees() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.external_fees);
  }

  public get rawMargin() {
    return this.totalSellPrice - this.totalBuyPrice;
  }
  public get netMargin() {
    return this.totalSellPrice - this.totalCostPrice;
  }
  public get rawMarginPercentage() {
    return this.totalBuyPrice == 0 ? 0 : (this.rawMargin / this.totalBuyPrice) * 100;
  }
  public get netMarginPercentage() {
    return this.totalBuyPrice == 0 ? 0 : (this.netMargin / this.totalBuyPrice) * 100;
  }

  public get orderNumber() {
    return this.move ? this.move.fullNumber : this.order ? this.order.number : null;
  }

  public get buy_currency() {
    return this.order ? this.order.buy_currency : null;
  }
  public get buy_xrate() {
    return this.order ? this.order.buy_xrate : null;
  }
  public get sell_currency() {
    return this.order ? this.order.sell_currency : null;
  }
  public get sell_xrate() {
    return this.order ? this.order.sell_xrate : null;
  }

  public get unit_buyPrice() {
    return this.move ? this.move.unit_buyPrice : this.order ? this.order.unit_buyPrice : 0;
  }
  public get unit_costPrice() {
    return this.move ? this.move.unit_costPrice : this.order ? this.order.unit_costPrice : 0;
  }
  public get unit_supplierFees() {
    return this.move ? this.move.unit_supplierFees : this.order ? this.order.unit_supplierFees : 0;
  }
  public get unit_externalFees() {
    return this.move ? this.move.unit_externalFees : this.order ? this.order.unit_externalFees : 0;
  }
  public get unit_internalFees() {
    return this.move ? this.move.unit_internalFees : this.order ? this.order.unit_internalFees : 0;
  }
  public get unit_sellPrice() {
    return this.move ? this.move.unit_sellPrice : this.sell_price;
  }
  public get unit_rawMargin() {
    return this.move ? this.move.unit_rawMargin : this.total_rawMargin / this.quantity;
  }
  public get unit_netMargin() {
    return this.move ? this.move.unit_netMargin : this.total_netMargin / this.quantity;
  }

  public get total_buyPrice() {
    return this.move ? this.move.total_buyPrice : this.unit_buyPrice * this.quantity;
  }
  public get total_costPrice() {
    return this.move ? this.move.total_costPrice : this.unit_costPrice * this.quantity;
  }
  public get total_supplierFees() {
    return this.move ? this.move.total_supplierFees : this.unit_supplierFees * this.quantity;
  }
  public get total_externalFees() {
    return this.move ? this.move.total_externalFees : this.unit_externalFees * this.quantity;
  }
  public get total_internalFees() {
    return this.move ? this.move.total_internalFees : this.unit_internalFees * this.quantity;
  }
  public get total_sellPrice() {
    return this.move ? this.move.total_sellPrice : this.unit_sellPrice * this.quantity;
  }
  public get total_rawMargin() {
    return this.move ? this.move.total_rawMargin : this.total_sellPrice - this.total_buyPrice;
  }
  public get total_netMargin() {
    return this.move
      ? this.move.total_netMargin
      : this.total_rawMargin - this.total_externalFees - this.total_internalFees;
  }

  public get total_rawMarginPerc() {
    return this.total_sellPrice == 0 ? 0 : (1 - this.total_buyPrice / this.total_sellPrice) * 100;
  }
  public get total_netMarginPerc() {
    return this.total_sellPrice == 0 ? 0 : (1 - this.total_costPrice / this.total_sellPrice) * 100;
  }

  public get merchantMargin() {
    return this.proforma
      ? 0
      : (this.payment_status != null ? this.payment_status.ended * this.payment_status.paid : 0) *
          (this.total_netMargin * ((this.merchant && this.merchant.margin ? this.merchant.margin : 0) / 100));
  }

  public get converted_unit_buyPrice() {
    return CurrenciesService.euroToCurrency(this.buy_xrate || 1, this.unit_buyPrice);
  }
  public get converted_unit_costPrice() {
    return CurrenciesService.euroToCurrency(this.buy_xrate || 1, this.unit_costPrice);
  }
  public get converted_unit_supplierFees() {
    return CurrenciesService.euroToCurrency(this.buy_xrate || 1, this.unit_supplierFees);
  }
  public get converted_unit_externalFees() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.unit_externalFees);
  }
  public get converted_unit_internalFees() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.unit_internalFees);
  }
  public get converted_unit_sellPrice() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.unit_sellPrice);
  }

  public get converted_total_buyPrice() {
    return CurrenciesService.euroToCurrency(this.buy_xrate || 1, this.total_buyPrice);
  }
  public get converted_total_costPrice() {
    return CurrenciesService.euroToCurrency(this.buy_xrate || 1, this.total_costPrice);
  }
  public get converted_total_supplierFees() {
    return CurrenciesService.euroToCurrency(this.buy_xrate || 1, this.total_supplierFees);
  }
  public get converted_total_externalFees() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.total_externalFees);
  }
  public get converted_total_internalFees() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.total_internalFees);
  }
  public get converted_total_sellPrice() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.total_sellPrice);
  }
  public get converted_total_rawMargin() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.total_rawMargin);
  }
  public get converted_total_netMargin() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.total_netMargin);
  }

  /* ObjectModel FUNCTIONS */

  public static definition: ObjectDefinition = {
    trashDelete: true,
    database: {
      table: 'invoices',
      id: 'id',
    },
    values: {
      date: { type: 'string' },
      proforma: { type: 'number' },
      number: { type: 'string' },

      quantity: { type: 'number' },
      unit: { type: 'string' },
      sell_price: { type: 'number' },
      vat: { type: 'number' },

      delivery_number: { type: 'string' },
      deadline: { type: 'string' },
      delivery_time: { type: 'string' },
      discount: { type: 'number' },
      discount_days: { type: 'number' },

      delivery: { type: 'string' },
      delivery_date: { type: 'string' },

      remarks: { type: 'string' },

      payment_date: { type: 'string' },
      payment_amount: { type: 'number' },
      payment_remarks: { type: 'string' },
      payment_archived: { type: 'number' },
    },
    children: {
      accounting: {
        type: 'Accounting',
        clone: false,
        save: false,
        delete: false,
      },
      merchant: { type: 'Merchant', clone: false, save: false, delete: false },
      order: { type: 'Order', clone: false, save: false, delete: false },
      move: { type: 'StockMove', clone: false, save: true, delete: false },
      payment_status: {
        type: 'PaymentStatus',
        clone: false,
        save: false,
        delete: false,
      },
      user: { type: 'User', clone: false, save: false, delete: false },
    },
    links: {
      reimbursements: {
        type: 'Reimbursement',
        clone: false,
        save: false,
        delete: false,
        table: 'invoices_reimbursements',
        id_self: 'id_invoice',
        id_link: 'id_reimbursement',
      },
    },
  };
}
