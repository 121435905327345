import { Accounting } from '../../other-data/classes/Accounting.class';
import { Address } from '../../addresses/classes/Address.class';
import { Article } from '../../articles/classes/Article.class';
import { ArticleBase } from '../../articles/classes/ArticleBase.class';
import { CredentialsService } from '../../../services/credentials/credentials.service';
import { CurrenciesService } from 'src/app/services/currencies/currencies.service';
import { Currency } from '../../other-data/classes/Currency.class';
import { Customer } from '../../customers/classes/Customer.class';
import { DateTimeUtil } from '../../utils/classes/DateTimeUtil.class';
import { Deadline } from '../../other-data/classes/Deadline.class';
import { Invoice } from '../../invoicing/classes/Invoice.class';
import { Merchant } from '../../other-data/classes/Merchant.class';
import { NumberUtil } from '../../utils/classes/NumberUtil.class';
import { ObjectDefinition } from '../../../classes/objects/ObjectDefinition.class';
import { ObjectModel3 } from 'src/app/classes/objects/ObjectModel3.class';
import { Payment } from '../../payments/classes/Payment.class';
import { PaymentStatus } from '../../payments/classes/PaymentStatus.class';
import { Price } from '../../sales/classes/Price.class';
import { Sale } from '../../sales/classes/Sale.class';
import { ServerApi } from 'src/app/classes/api/ServerApi.class';
import { Stock } from '../../stocks/classes/Stock.class';
import { Supplier } from '../../suppliers/classes/Supplier.class';
import { User } from 'src/app/classes/credentials/User.class';

export class Order extends ObjectModel3 {
  public accounting: Accounting = null;
  public buy_currency: Currency = null;
  public buy_xrate: number = 1;
  public sell_currency: Currency = null;
  public sell_xrate: number = 1;

  public sale: Sale = null;
  public price: Price = null;

  public date: string = null;

  public merchant: Merchant = null;
  public customer: Customer = null;
  public article: ArticleBase = null;
  public article_reference: string = null;

  public user: User = null;

  public archived: number = 0;

  public use_stock: Stock = null;

  // public invoice: Invoice = null;
  public invoices: Invoice[] = [];
  public get remaining_quantity(): number {
    let total: number = this.quantity;
    if (this.invoices) {
      for (let i = 0; i < this.invoices.length; ++i) {
        total -= this.invoices[i].quantity || 0;
        total += this.invoices[i].reimbursements_quantity || 0;
      }
    }
    return total;
  }
  public get remaining_amount(): number {
    let total: number = this.totalAmount;
    if (this.invoices) {
      for (let i = 0; i < this.invoices.length; ++i) {
        total -= this.invoices[i].totalAmount || 0;
        total -= this.invoices[i].reimbursements_totalAmount || 0;
      }
    }
    return total;
  }
  public get invoices_list(): string {
    if (this.deliveries > 1) return 'STOCK';
    if (!this.invoices || this.invoices.length == 0) return null;
    if (this.invoices.length == 1) return this.invoices[0].number;
    else return '(' + this.invoices.length + ' factures)';
  }
  public get isCancelled(): boolean {
    return (
      this.invoices && this.invoices.length > 0 && this.invoices.reduce((res, inv) => res && inv.isCancelled, true)
    );
  }
  public get isStock() {
    return this.deliveries > 1;
  }

  public deadline: string = null;
  public delivery_time: string = null;

  public ordinator_contact: string = null;
  public ordinator_address: Address = null;
  public ordinator_address_text: string = null;
  public ordinator_emails: string = null;
  public invoicing_contact: string = null;
  public invoicing_address: Address = null;
  public invoicing_address_text: string = null;
  public invoicing_emails: string = null;
  public reminders_emails: string = null;
  public delivery_contact: string = null;
  public delivery_address: Address = null;
  public delivery_address_text: string = null;
  public opening_hours: string = null;
  public self_delivery: number = 0;

  public invoicings: number = null;
  public deliveries: number = null;
  public storage: number = null;
  public productions: number = null;

  public supplier: Supplier = null;
  public quantity: number = null;
  public unit: string = null;
  public buy_price: number = null;
  public internal_fees: number = null;
  public supplier_fees: number = null;
  public external_fees: number = null;
  public agent_fees: number = null;
  public sell_price: number = null;
  public get converted_buy_price() {
    return CurrenciesService.euroToCurrency(this.buy_xrate || 1, this.buy_price);
  }
  public set converted_buy_price(value: number) {
    this.buy_price = CurrenciesService.currencyToEuro(this.buy_xrate || 1, value);
  }
  public get converted_supplier_fees() {
    return CurrenciesService.euroToCurrency(this.buy_xrate || 1, this.supplier_fees);
  }
  public set converted_supplier_fees(value: number) {
    this.supplier_fees = CurrenciesService.currencyToEuro(this.buy_xrate || 1, value);
  }
  public get converted_sell_price() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.sell_price);
  }
  public set converted_sell_price(value: number) {
    this.sell_price = CurrenciesService.currencyToEuro(this.sell_xrate || 1, value);
  }
  public get converted_internal_fees() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.internal_fees);
  }
  public set converted_internal_fees(value: number) {
    this.internal_fees = CurrenciesService.currencyToEuro(this.sell_xrate || 1, value);
  }
  public get converted_external_fees() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.external_fees);
  }
  public set converted_external_fees(value: number) {
    this.external_fees = CurrenciesService.currencyToEuro(this.sell_xrate || 1, value);
  }
  public get converted_agent_fees() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.agent_fees);
  }
  public set converted_agent_fees(value: number) {
    this.agent_fees = CurrenciesService.currencyToEuro(this.sell_xrate || 1, value);
  }

  public supplier_reference: string = null;
  public supplier_date: string = null;
  public customer_reference: string = null;
  public number: string = null;
  public get fullNumber() {
    return this.number;
  }
  public delivery_number: string = null;

  public discount: number = 0;
  public discount_days: number = null;
  public show_name_on_stickers: number = 1;

  public remarks_ack: string = null;
  public remarks_order: string = null;
  public remarks_note: string = null;
  public internal_remarks: string = null;
  public get offer_remarks() {
    return this.sale ? this.sale.offer_remarks : null;
  }
  public get demand_remarks() {
    return this.sale ? this.sale.demand_remarks : null;
  }

  public followup_archived: number = 0;
  public followup_remarks: string = null;
  public followup_delivery_date: string = null;
  public followup_supplier_reference: string = null;

  public followup_creation: number = 0;
  public followup_waiting_customer: number = 0;
  public followup_accepted: number = 0;
  public followup_reminder: number = 0;

  public payment: Payment = null;
  public get payment_status() {
    return this.payment ? this.payment.status : null;
  }
  public get payment_date() {
    return this.payment ? this.payment.date : null;
  }
  public get payment_amount() {
    return this.payment ? this.payment.amount : null;
  }
  public set payment_status(value: PaymentStatus) {
    if (!this.payment) this.createPayment();
    this.payment.status = value;
  }
  public set payment_date(value: string) {
    if (!this.payment) this.createPayment();
    this.payment.date = value;
  }
  public set payment_amount(value: number) {
    if (!this.payment) this.createPayment();
    this.payment.amount = value;
  }
  private createPayment() {
    this.payment = new Payment();
    this.payment.date = DateTimeUtil.toDateString(new Date());
    this.payment.amount = this.totalAmount;
  }

  constructor() {
    super(Order);
    this.user = CredentialsService.loggedUser;
  }

  public get numberAndName() {
    return this.number + ' - ' + (this.article ? this.article.designation : '');
  }
  public get shortNumber() {
    return this.number.replace(/(\/[A-Za-z]{2,}[0-9]{4,}\/[0-9A-Za-z]{3}(\-[0-9]+)?)$/, '');
  }
  public get quantityAndUnit() {
    let arr: string[] = [];
    if (this.quantity != null) arr.push(NumberUtil.formatNumber(this.quantity, null, '.'));
    if (this.unit != null) arr.push(this.unit);
    return arr.join(' ');
  }
  public get totalAmount() {
    if (this.quantity != null && this.sell_price != null) return this.quantity * this.sell_price;
    else return 0;
  }
  public get converted_vatAmount() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.vatAmount);
  }
  public get vatAmount() {
    if (this.quantity != null && this.sell_price != null)
      return this.quantity * this.sell_price * ((isNaN(this.customer.vat) ? 21 : this.customer.vat) / 100);
    else return 0;
  }
  public get converted_totalWithTax() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.totalWithTax);
  }
  public get totalWithTax() {
    return this.totalAmount + this.vatAmount;
  }
  public get converted_totalAmount() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.totalAmount);
  }
  public get openingHours() {
    return this.opening_hours || (this.sale ? this.sale.opening_hours : null);
  }

  public get unit_buyPrice() {
    return this.total_buyPrice / this.quantity;
  }
  public get unit_costPrice() {
    return this.total_costPrice / this.quantity;
  }
  public get unit_supplierFees() {
    return this.total_supplierFees / this.quantity;
  }
  public get unit_externalFees() {
    return this.total_externalFees / this.quantity;
  }
  public get unit_agentFees() {
    return this.total_agentFees / this.quantity;
  }
  public get unit_internalFees() {
    return this.total_internalFees / this.quantity;
  }
  public get unit_sellPrice() {
    return this.sell_price;
  }
  public get unit_rawMargin() {
    return this.total_rawMargin / this.quantity;
  }
  public get unit_netMargin() {
    return this.total_netMargin / this.quantity;
  }

  public get total_buyPrice() {
    return this.buy_price * this.quantity + this.total_supplierFees;
  }
  public get total_costPrice() {
    return this.total_buyPrice + this.total_externalFees + this.total_internalFees + this.total_agentFees;
  }
  public get total_supplierFees() {
    return this.supplier_fees;
  }
  public get total_externalFees() {
    return this.external_fees;
  }
  public get total_agentFees() {
    return this.agent_fees;
  }
  public get total_internalFees() {
    return this.internal_fees;
  }
  public get total_sellPrice() {
    return this.unit_sellPrice * this.quantity;
  }
  public get total_rawMargin() {
    return this.total_sellPrice - this.total_buyPrice;
  }
  public get total_netMargin() {
    return this.total_rawMargin - this.total_externalFees - this.total_internalFees - this.total_agentFees;
  }

  public get total_rawMarginPerc() {
    return this.total_buyPrice ? (100 * this.total_rawMargin) / this.total_buyPrice : 0;
  }
  public get total_netMarginPerc() {
    return this.total_costPrice ? (100 * this.total_netMargin) / this.total_costPrice : 0;
  }
  public get total_netMarkupPerc() {
    return this.total_sellPrice ? 100 * (1 - this.total_costPrice / this.total_sellPrice) : 0;
  }

  public get converted_unit_buyPrice() {
    return CurrenciesService.euroToCurrency(this.buy_xrate || 1, this.unit_buyPrice);
  }
  public get converted_unit_costPrice() {
    return CurrenciesService.euroToCurrency(this.buy_xrate || 1, this.unit_costPrice);
  }
  public get converted_unit_supplierFees() {
    return CurrenciesService.euroToCurrency(this.buy_xrate || 1, this.unit_supplierFees);
  }
  public get converted_unit_externalFees() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.unit_externalFees);
  }
  public get converted_unit_agentFees() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.unit_agentFees);
  }
  public get converted_unit_internalFees() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.unit_internalFees);
  }
  public get converted_unit_sellPrice() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.unit_sellPrice);
  }

  public get converted_total_buyPrice() {
    return CurrenciesService.euroToCurrency(this.buy_xrate || 1, this.total_buyPrice);
  }
  public get converted_total_costPrice() {
    return CurrenciesService.euroToCurrency(this.buy_xrate || 1, this.total_costPrice);
  }
  public get converted_total_supplierFees() {
    return CurrenciesService.euroToCurrency(this.buy_xrate || 1, this.total_supplierFees);
  }
  public get converted_total_externalFees() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.total_externalFees);
  }
  public get converted_total_agentFees() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.total_agentFees);
  }
  public get converted_total_internalFees() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.total_internalFees);
  }
  public get converted_total_sellPrice() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.total_sellPrice);
  }

  public get totalBuyPrice() {
    return (
      this.quantity * this.buy_price +
      // (this.external_fees ? this.external_fees : 0) +
      // (this.internal_fees ? this.internal_fees : 0) +
      (this.supplier_fees ? this.supplier_fees : 0)
    );
  }
  public get unitBuyPrice(): number {
    if (this.quantity <= 0) return 0;
    return this.totalBuyPrice / this.quantity;
  }
  public get totalCostPrice(): number {
    return (
      this.totalBuyPrice +
      (this.internal_fees ? this.internal_fees * 1 : 0) +
      (this.external_fees ? this.external_fees * 1 : 0) +
      (this.agent_fees ? this.agent_fees * 1 : 0)
    );
  }
  public get unitCostPrice(): number {
    if (this.quantity <= 0) return 0;
    return this.totalCostPrice / this.quantity;
  }
  public get totalSellPrice() {
    return this.totalAmount;
  }

  public get converted_unitBuyPrice() {
    return CurrenciesService.euroToCurrency(this.buy_xrate || 1, this.unitBuyPrice);
  }
  public get converted_totalCostPrice() {
    return CurrenciesService.euroToCurrency(this.buy_xrate || 1, this.totalCostPrice);
  }
  public get converted_unitCostPrice() {
    return CurrenciesService.euroToCurrency(this.buy_xrate || 1, this.unitCostPrice);
  }
  public get converted_totalSellPrice() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.totalSellPrice);
  }

  public get customerName() {
    return this.customer && this.customer.name;
  }
  public get articleDesignation() {
    return this.article && this.article.designation;
  }
  public get supplierName() {
    return this.supplier && this.supplier.name;
  }
  public get saleNumber() {
    return this.sale && this.sale.number;
  }
  public get merchantName() {
    return this.merchant && this.merchant.name;
  }

  public get rawMargin() {
    return this.totalSellPrice - this.totalBuyPrice;
  }
  public get netMargin() {
    return this.totalSellPrice - this.totalCostPrice;
  }

  public get rawMarginPercentage() {
    return this.totalBuyPrice == 0 ? 0 : (this.rawMargin / this.totalBuyPrice) * 100;
  }
  public get netMarginPercentage() {
    return this.totalCostPrice == 0 ? 0 : (this.netMargin / this.totalCostPrice) * 100;
  }

  public get daysSince() {
    return DateTimeUtil.daysBetween(this.date, new Date());
  }
  public get daysDue() {
    return DateTimeUtil.daysBetween(new Date(), this.expiration);
  }
  public get expiration() {
    return DateTimeUtil.toDateString(Deadline.getFinalDate(this.deadline, new Date(this.date)));
  }

  public getNumberPrefix(type: string = '11') {
    return (
      type +
      '/' +
      ('00' + (new Date().getFullYear() % 100)).slice(-2) +
      (this.deliveries > 1 ? '1' : '0') +
      (this.accounting ? this.accounting.number : '0')
    );
  }
  public getNextNumber() {
    return ServerApi.callModule('orders', 'nextNumber', { number_prefix: this.getNumberPrefix() });
  }
  public generateNumber(number: string, type: string = '11') {
    return (
      this.getNumberPrefix(type) +
      ' ' +
      ('0000' + number).slice(-4) +
      '/' +
      (this.sale.customer ? this.sale.customer.number : 'BEL0000') +
      '/' +
      (this.sale.article && this.sale.article.nomenclature && this.sale.article.nomenclature.name
        ? this.sale.article.nomenclature.name.replace(/[^A-Za-z0-9]/g, '').substr(0, 3)
        : '000')
    );
  }

  /* ObjectModel FUNCTIONS */

  public static definition: ObjectDefinition = {
    trashDelete: true,
    database: {
      table: 'orders',
      id: 'id',
    },
    values: {
      buy_xrate: { type: 'number' },
      sell_xrate: { type: 'number' },
      date: { type: 'string' },
      deadline: { type: 'string' },
      delivery_time: { type: 'string' },
      article_reference: { type: 'string' },

      ordinator_contact: { type: 'string' },
      ordinator_address_text: { type: 'string' },
      ordinator_emails: { type: 'string' },
      invoicing_contact: { type: 'string' },
      invoicing_address_text: { type: 'string' },
      invoicing_emails: { type: 'string' },
      reminders_emails: { type: 'string' },
      delivery_contact: { type: 'string' },
      delivery_address_text: { type: 'string' },
      opening_hours: { type: 'string' },
      self_delivery: { type: 'number' },

      invoicings: { type: 'number' },
      deliveries: { type: 'number' },
      storage: { type: 'number' },
      productions: { type: 'number' },

      quantity: { type: 'number' },
      unit: { type: 'string' },
      buy_price: { type: 'number' },
      internal_fees: { type: 'number' },
      supplier_fees: { type: 'number' },
      external_fees: { type: 'number' },
      agent_fees: { type: 'number' },
      sell_price: { type: 'number' },

      supplier_reference: { type: 'string' },
      supplier_date: { type: 'string' },
      customer_reference: { type: 'string' },
      number: { type: 'string' },
      delivery_number: { type: 'string' },

      discount: { type: 'number' },
      discount_days: { type: 'number' },
      show_name_on_stickers: { type: 'number' },

      followup_archived: { type: 'number', ignore_save: true },
      followup_remarks: { type: 'string' },
      followup_delivery_date: { type: 'string' },
      followup_supplier_reference: { type: 'string' },

      followup_creation: { type: 'number' },
      followup_waiting_customer: { type: 'number' },
      followup_accepted: { type: 'number' },
      followup_reminder: { type: 'number' },

      remarks_ack: { type: 'string' },
      remarks_order: { type: 'string' },
      remarks_note: { type: 'string' },
      internal_remarks: { type: 'string' },

      archived: { type: 'number', ignore_save: true },
    },
    children: {
      accounting: { type: 'Accounting', clone: false, save: false, delete: false },
      buy_currency: { type: 'Currency', clone: false, save: false, delete: false },
      sell_currency: { type: 'Currency', clone: false, save: false, delete: false },
      sale: { type: 'Sale', clone: false, save: false, delete: false },
      supplier: { type: 'Supplier', clone: false, save: false, delete: false },
      merchant: { type: 'Merchant', clone: false, save: false, delete: false },
      customer: { type: 'Customer', clone: false, save: false, delete: false },
      article: { type: 'Article', clone: false, save: false, delete: false, includeDeleted: true },
      // 'invoice': { type: 'Invoice', clone: false, save: false, delete: false },
      payment: { type: 'Payment', clone: false, save: false, delete: false },
      user: { type: 'User', clone: false, save: false, delete: false },
      use_stock: { type: 'Stock', clone: false, save: false, delete: false },
    },
    links: {
      invoices: {
        type: 'Invoice',
        table: 'orders_invoices',
        id_self: 'id_order',
        id_link: 'id_invoice',
        clone: false,
        save: false,
        delete: false,
      },
    },
  };
}
